import { useId, useMemo } from 'react'
import { ConfirmationDialog } from '~/components/ui/dialog/ConfirmationDialog/ConfirmationDialog'
import { NormalMessageDialog } from '~/components/ui/dialog/NormalMessageDialog/NormalMessageDialog'
import { useBreakpoint } from '~/hooks/useBreakpoint'
import { colorTextSecondary } from '~/styles/textColors'
import { ProgressCircle } from './components/ProgressCircle/ProgressCircle'
import { useOfflineToggleInteractions } from './hooks/useOfflineToggleInteractions'
import type { DownloadResultType } from './hooks/useOfflineToggleInteractions'
import type { useOfflineModeToggle } from '../../hooks/useOfflineModeToggle'

type Props = Pick<
  ReturnType<typeof useOfflineModeToggle>,
  | 'isDownloadConfirmationDialogOpen'
  | 'isOfflineModeEndedDialogOpen'
  | 'closeDownloadConfirmationDialog'
  | 'closeOfflineModeEndedDialog'
>

type ResultDialogProps = {
  title: string
  contentText?: string
}

const getResultDialogProps = (
  resultType: DownloadResultType,
): ResultDialogProps | undefined => {
  switch (resultType) {
    case 'success':
      return {
        title: 'ダウンロードが完了しました。',
      }
    case 'error':
      return {
        title: 'ダウンロードに失敗しました。',
        contentText:
          'インターネットの接続を確認し、再度ダウンロードしてください。',
      }
    case 'hasMoreEmployees':
      return {
        title: 'ダウンロードが完了しました。',
        contentText:
          '従業員の登録数が上限を超えています。レポート記録を正常に行えない可能性があります。',
      }
    case 'noFeedback':
      return undefined
  }
}

export function OfflineToggleInteractions({
  isDownloadConfirmationDialogOpen,
  isOfflineModeEndedDialogOpen,
  closeDownloadConfirmationDialog,
  closeOfflineModeEndedDialog,
}: Props) {
  const {
    startDownloading,
    endOfflineReporting,
    displayProgressCircle,
    downloadProgress,
    downloadResultType,
    hideDownloadResultInfo,
  } = useOfflineToggleInteractions()

  const resultDialogProps = useMemo(() => {
    if (!downloadResultType) return undefined
    return getResultDialogProps(downloadResultType)
  }, [downloadResultType])

  const idSuffix = useId()
  // TODO: 一旦ボタンのレイアウトが崩れる可能性がある場合＝smartphoneサイズの場合のみボタンサイズを小さくするために指定しているが、ダイアログによって分ける必要はないかもしれない
  // labels: ph.3
  const buttonSize = useBreakpoint() === 'smartphone' ? 'medium' : 'large'

  return (
    <>
      <ConfirmationDialog
        id={`download-confirmation-dialog-${idSuffix}`}
        open={isDownloadConfirmationDialogOpen}
        title="オフライン記録用に最新のひな形/スケジュールをダウンロードし、オフラインモードを開始しますか？"
        okText="ダウンロードして開始"
        onClose={closeDownloadConfirmationDialog}
        okCallback={async () => {
          await startDownloading()
        }}
        cancelButtonProps={{
          size: buttonSize,
          sx: { color: colorTextSecondary },
        }}
        okButtonProps={{ variant: 'contained', size: buttonSize }}
      />
      <ConfirmationDialog
        id={`offline-mode-ended-dialog-${idSuffix}`}
        open={isOfflineModeEndedDialogOpen}
        title="オフラインモードを終了しますか？"
        okText="はい"
        onClose={closeOfflineModeEndedDialog}
        okCallback={async () => {
          await endOfflineReporting()
        }}
        cancelButtonProps={{
          size: buttonSize,
          sx: { color: colorTextSecondary },
        }}
        okButtonProps={{ variant: 'contained', size: buttonSize }}
      >
        オフラインで記録していたレポートは忘れずにアップロードしてください。
      </ConfirmationDialog>
      {resultDialogProps && (
        <NormalMessageDialog
          title={resultDialogProps.title}
          contentText={resultDialogProps.contentText}
          isOpen
          onOk={hideDownloadResultInfo}
        />
      )}
      {displayProgressCircle && <ProgressCircle progress={downloadProgress} />}
    </>
  )
}
