import type { Extra } from '~/utils/error'
import { ErrorWithExtra } from '~/utils/error'

/**
 * model、action、selector などの処理中に、明示的に発生させたいが、
 * try ~ catch を明示的に書かない（書けない）可能性があるエラーで使用するカスタムエラークラス。
 * 基本的に、レポート記録中の action や selector、利用するコンテキストが限定できない model でのエラーに使用することを想定。
 *
 * （selector でのエラーは、ErrorBoundary で実質 catch されているが、このエラーを利用しても良いものとする）
 *
 */
export class UnhandledInReportingError extends ErrorWithExtra {
  constructor(message: string, extra?: Extra) {
    super('UnhandledInReportingError', message, extra)
  }
}
