import { useMediaQuery, useTheme } from '@mui/material'

/**
 * 基準はタブレット(大きいサイズ)で、一定のサイズ以下になったらスタイルを変更する
 */

export type Breakpoint = 'smartphone' | 'tablet' | 'pc'

export const useBreakpoint = (): Breakpoint => {
  const theme = useTheme()
  const isSmartphone = useMediaQuery(theme.breakpoints.only('smartphone'))
  const isTablet = useMediaQuery(theme.breakpoints.only('tablet'))

  if (isSmartphone) {
    return 'smartphone'
  } else if (isTablet) {
    return 'tablet'
  }

  return 'pc'
}
