import { Image } from '../../../../image/image'
import { UnhandledInReportingError } from '../../../error'

import {
  type QuestionNodeBase,
  type QuestionNodeInterface,
} from '../questionNode'
import type { InformationDocumentAnswer } from './informationDocumentAnswer'
import type { InformationDocumentQuestion } from './informationDocumentQuestion'
import type { LocalImage } from '../../../../image/image'
import type { UploadedFile } from '../../../uploadedFile/uploadedFile'
import type { Node } from '../../node'
import type { UploadImageQuestionNodeInterface } from '../uploadImageQuestionNode/uploadImageQuestionNode'

export type InformationDocumentQuestionNode =
  QuestionNodeBase<'informationDocument'> & {
    question: InformationDocumentQuestion
    answer: InformationDocumentAnswer | undefined
  }

const isInformationDocumentQuestionNode = (
  node: Node | undefined,
): node is InformationDocumentQuestionNode => {
  return (
    node?.type === 'question' && node.questionType === 'informationDocument'
  )
}
type RequireInformationDocumentQuestionNode = (
  node: Node | undefined,
) => asserts node is InformationDocumentQuestionNode
const requireInformationDocumentQuestionNode: RequireInformationDocumentQuestionNode =
  node => {
    if (!isInformationDocumentQuestionNode(node)) {
      throw new UnhandledInReportingError(`node is not image question node.`, {
        node,
      })
    }
  }

const addUploadedImage = (
  node: InformationDocumentQuestionNode,
  key: string,
  file: UploadedFile,
  recordedAt: Date,
): InformationDocumentQuestionNode => {
  const image: Image = {
    type: 'uploaded',
    recordedAt,
    key: key,
    file,
  }
  if (node.answer === undefined) {
    return {
      ...node,
      answer: {
        type: 'informationDocument',
        recordedAt: image.recordedAt,
        image,
      },
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      recordedAt: image.recordedAt,
      image,
    },
  }
}

const addLocalImage = (
  node: InformationDocumentQuestionNode,
  key: string,
  file: File,
  recordedAt: Date,
): InformationDocumentQuestionNode => {
  const image: Image = {
    type: 'local',
    key,
    recordedAt,
    file,
  }
  if (node.answer === undefined) {
    return {
      ...node,
      answer: {
        type: 'informationDocument',
        recordedAt: image.recordedAt,
        image,
      },
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      recordedAt: image.recordedAt,
      image,
    },
  }
}

const updateImageWithLocalFile = (
  node: InformationDocumentQuestionNode,
  key: string,
  file: File,
  recordedAt: Date,
): InformationDocumentQuestionNode => {
  if (node.answer === undefined) {
    throw new UnhandledInReportingError('answer is undefined', {
      node,
    })
  }

  let newImage = node.answer.image
  if (newImage.key === key) {
    newImage = {
      type: 'local',
      key: newImage.key,
      recordedAt,
      file,
    }
  }

  return {
    ...node,
    answer: {
      ...node.answer,
      recordedAt: recordedAt,
      image: newImage,
    },
  }
}

const updateImageWithUploadedFile = (
  node: InformationDocumentQuestionNode,
  key: string,
  file: UploadedFile,
): InformationDocumentQuestionNode => {
  if (node.answer === undefined) {
    throw new UnhandledInReportingError('answer is undefined', {
      node,
    })
  }
  let newImage = node.answer.image
  if (newImage.key === key) {
    newImage = {
      type: 'uploaded',
      key: newImage.key,
      recordedAt: newImage.recordedAt,
      file,
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      image: newImage,
    },
  }
}

const getLocalImagesToUpload = (
  node: InformationDocumentQuestionNode,
): LocalImage[] => {
  if (node.answer === undefined || !Image.isLocalImage(node.answer.image)) {
    return []
  }
  return [node.answer.image]
}

const _InformationDocumentQuestionNode = {
  isInvalidAnswer: () => false,
  addUploadedImage,
  addLocalImage,
  updateImageWithLocalFile,
  updateImageWithUploadedFile,
  isInformationDocumentQuestionNode,
  getLocalImagesToUpload,
} satisfies QuestionNodeInterface<InformationDocumentQuestionNode> &
  UploadImageQuestionNodeInterface<InformationDocumentQuestionNode>

export const InformationDocumentQuestionNode: typeof _InformationDocumentQuestionNode & {
  requireInformationDocumentQuestionNode: typeof requireInformationDocumentQuestionNode
} = {
  ..._InformationDocumentQuestionNode,
  requireInformationDocumentQuestionNode,
}
