import { ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import { z } from 'zod'

// 400系/500系エラーのときにharami_api_serverが返してくるレスポンスの型
const ApiErrorScheme = z.object({
  status: z.number(),
  code: z.nativeEnum(ErrorCodeEnum),
  errors: z.array(z.string()),
})
export type ApiError = z.infer<typeof ApiErrorScheme>

export type RequestError =
  | ({
      type: 'apiError'
    } & ApiError)
  | {
      type: 'unexpectedError'
    }

/**
 * クライアントからのリクエストに失敗した際にthrowされるエラーをRequestError型へ変換する
 *
 * @param error - harami_api_clientへのリクエストに失敗した際にthrowされるエラー
 */
export const getRequestError = async (
  error: unknown,
): Promise<RequestError> => {
  if (!(error instanceof Response)) {
    return { type: 'unexpectedError' }
  }
  const json = await error
    .clone()
    .json()
    .catch(e => e)
  const result = ApiErrorScheme.safeParse(json)
  if (!result.success) {
    return { type: 'unexpectedError' }
  }
  return {
    type: 'apiError',
    ...result.data,
  }
}

export const isAppVersionInconsistentError = (error: RequestError) => {
  return (
    error.type === 'apiError' &&
    error.status === 400 &&
    error.code === ErrorCodeEnum.HaramiReportVersionInconsistentError
  )
}

export const isAuthenticationExpiredError = (error: RequestError) => {
  return (
    error.type === 'apiError' &&
    error.status == 401 &&
    error.code === ErrorCodeEnum.AuthenticationExpired
  )
}

export const isConflictError = (error: RequestError) => {
  return (
    error.type === 'apiError' &&
    error.status === 409 &&
    error.code === ErrorCodeEnum.ConflictError
  )
}
