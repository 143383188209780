import { UnhandledInReportingError } from '~/domain/report/model/report/error'
import { SectionNode } from '../../sectionNode'
import type { Node } from '../../../node'
import type { SectionNodeBase } from '../../sectionNode'
import type { RepeatableMasterEmployeeSectionNode } from '../employeeCheckSectionNode/employeeCheckSectionNode'

/**
 * 繰り返しセクションのマスター
 */
export type RepeatableMasterSectionNode =
  SectionNodeBase<'repeatableSectionMaster'>

export type MasterSectionNode =
  | RepeatableMasterSectionNode
  | RepeatableMasterEmployeeSectionNode

const isMasterSectionNode = (
  node: Node | undefined,
): node is MasterSectionNode => {
  if (!SectionNode.isSectionNode(node)) {
    return false
  }

  return (
    node.section.sectionType === 'repeatableSectionMaster' ||
    node.section.sectionType === 'repeatableEmployeeSectionMaster'
  )
}
type RequireMasterSectionNode = (
  node: Node | undefined,
) => asserts node is MasterSectionNode
const requireMasterSectionNode: RequireMasterSectionNode = node => {
  if (node === undefined || !isMasterSectionNode(node)) {
    throw new UnhandledInReportingError('node is not master section node.', {
      node,
    })
  }
}

const _MasterSectionNode = {
  isMasterSectionNode,
}

export const MasterSectionNode: typeof _MasterSectionNode & {
  requireMasterSectionNode: RequireMasterSectionNode
} = {
  ..._MasterSectionNode,
  requireMasterSectionNode,
}
