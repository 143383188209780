import type { UploadedImage } from '~/domain/report/model/image/image'
import type { ImageAnswer } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageAnswer'
import type { ImageQuestion } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageQuestion'
import type { ImageQuestionNode } from '~/domain/report/model/report/node/questionNode/imageQuestionNode/imageQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { convertToUploadedImage } from './imageConverter'
import type { ConversionContext } from './types'

export const convertResultImageQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { questionNodeBase, questionBase, recordedAt },
  } = context

  const responseAnswer = question.responseAnswer

  const imageQuestion: ImageQuestion = {
    ...questionBase,
    type: 'resultImage',
  }

  const images: UploadedImage[] =
    responseAnswer?.resultImages?.map(image => convertToUploadedImage(image)) ??
    []

  let imageAnswer: ImageAnswer | undefined = undefined
  if (images.length > 0) {
    imageAnswer = {
      type: 'resultImage',
      images,
      recordedAt,
    }
  }

  const questionNode: ImageQuestionNode = {
    ...questionNodeBase,
    questionType: 'resultImage',
    question: imageQuestion,
    answer: imageAnswer,
  }

  return questionNode
}
