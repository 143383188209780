import * as Sentry from '@sentry/browser'
import {
  type MultipleChoiceSet,
  type ReportNodeSchema,
  type ReportPageSchema,
  type TemplatePage,
  type ReportPage,
  type Report as ResponseReport,
} from '@ulysses-inc/harami_api_client'
import { schema, normalize } from 'normalizr'

import { type NodeDict, type NodeId } from '../../model/report/node/node'
import { ApiToModelError } from './error'
import { createPagesWithNewId, type ModelNodeIdGenerator } from './nodeIdMapper'
import {
  createNodeSchemaConverterWithMultipleChoiceSets,
  createNodeSchemaConverterWithMultipleChoiceUuids,
} from './nodeSchemaConverterFactory'

import { NodesRepairer } from './nodesRepairer'
import { validateEmployeeCheckSections } from './postValidator'

const nodeEntity = new schema.Entity('nodes')
nodeEntity.define({ nodes: [nodeEntity] })

const pageEntity = new schema.Entity('pages', {
  nodes: [nodeEntity],
})

type NormalizedEntities = {
  pages: Record<NodeId, ReportPageSchema>
  nodes: Record<NodeId, ReportNodeSchema>
}

type ArgMultipleChoiceSets =
  | {
      type: 'instance'
      multipleChoiceSets: MultipleChoiceSet[]
    }
  | {
      type: 'reference'
      uuids: string[]
    }

/**
 * OpenAPI スキーマにおける、templatePages or reportPage から Model に格納するための、page・node のデータに変換する
 *
 * @param pages
 * @param multipleChoiceSets
 * @returns
 */
const convertToModelReportPages = (
  origPages: TemplatePage[] | ReportPage[],
  multipleChoiceSets: ArgMultipleChoiceSets,
): {
  pageNodeIds: number[]
  nodes: NodeDict
  nodeIdGenerator: ModelNodeIdGenerator
} => {
  const { pages, nodeIdGenerator } = createPagesWithNewId(origPages)
  const normalizedReportNodes = normalizeToReportNodeSchemaDict(pages)

  const nodeSchemaConverter = (() => {
    switch (multipleChoiceSets.type) {
      case 'instance':
        return createNodeSchemaConverterWithMultipleChoiceSets(
          pages,
          normalizedReportNodes,
          multipleChoiceSets.multipleChoiceSets,
        )
      case 'reference':
        return createNodeSchemaConverterWithMultipleChoiceUuids(
          pages,
          normalizedReportNodes,
          multipleChoiceSets.uuids,
        )
    }
  })()
  const { nodeDict, pageNodeIds } = nodeSchemaConverter.convert(
    pages,
    normalizedReportNodes,
  )

  const nodesRepairer = new NodesRepairer(nodeDict)
  nodesRepairer.repairInstanceSectionNodePosition(pageNodeIds, nodeDict)

  validateEmployeeCheckSections(nodeDict)

  return {
    pageNodeIds,
    nodes: nodeDict,
    nodeIdGenerator,
  }
}

const processError = (e: unknown): Error => {
  if (e instanceof Error) {
    Sentry.captureException(e)
    return e
  } else {
    const error = new ApiToModelError(
      'An unexpected error has occurred in converting response to model.',
      { unknownError: e },
    )
    Sentry.captureException(error)
    return error
  }
}

/**
 * API Schema の TemplatePage（配下の TemplateNode も含む) から Model の型に変換する
 *
 * @param page
 * @param multipleChoiceSetUuids
 * @param swallowsError エラーが発生したい場合にエラーの後処理を行わずそのまま返却する場合 true を指定する。デフォルトは false
 * @returns
 */
export const convertToModelTemplatePagesWithMultipleChoiceSetUuids = (
  page: TemplatePage[],
  multipleChoiceSetUuids: string[],
  swallowsError: boolean = false,
) => {
  try {
    const { pageNodeIds, nodes } = convertToModelReportPages(page, {
      type: 'reference',
      uuids: multipleChoiceSetUuids,
    })
    return {
      pageNodeIds,
      nodes,
    }
  } catch (e) {
    if (swallowsError) {
      return new Error(String(e))
    }
    return processError(e)
  }
}

/**
 * API Schema の TemplatePage（配下の TemplateNode も含む) から Model の型に変換する
 *
 * @param page
 * @param multipleChoiceSets
 * @returns
 */
export const convertToModelTemplatePages = (
  page: TemplatePage[],
  multipleChoiceSets: MultipleChoiceSet[] | undefined,
) => {
  try {
    const { pageNodeIds, nodes } = convertToModelReportPages(page, {
      type: 'instance',
      multipleChoiceSets: multipleChoiceSets ?? [],
    })
    return {
      pageNodeIds,
      nodes,
    }
  } catch (e) {
    return processError(e)
  }
}

/**
 *  API Schema の Report を Model の型に変換する
 *
 * NOTE:
 *  実装時点ではカイゼン機能は未実装のため、カイゼン関係のデータは何も返していないが、
 *  カイゼン実装時には、API Schema の Improve を Model の型に変換したものが返却されるであろう。
 *  そのため、convertToModelReportPages という限定的な名前にはしていない
 * @param report
 * @returns
 */
export const convertToModelReport = (report: ResponseReport) => {
  try {
    const { pageNodeIds, nodes } = convertToModelReportPages(
      // report.pagesは定義上はrequiredだがundefinedになる可能性がある
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      report.pages ?? [],
      {
        type: 'instance',
        multipleChoiceSets: report.multipleChoiceSets ?? [],
      },
    )

    return {
      pageNodeIds,
      nodes,
    }
  } catch (e) {
    return processError(e)
  }
}

const normalizeToReportNodeSchemaDict = (
  pages: TemplatePage[] | ReportPage[],
) => {
  // NOTE:
  // 変換処理が冗長になり大変なので、まずはharami_mobileのnormalize処理をそのまま利用している。
  // https://github.com/Ulysses-inc/harami_mobile/blob/develop/src/state/ducks/reports/schemas.ts

  const normalized = normalize<unknown, NormalizedEntities, Array<number>>(
    pages,
    [pageEntity],
  )
  return normalized.entities.nodes
}
