export type PromiseFunc = () => Promise<unknown>

type AwaitedTuple<T extends PromiseFunc[]> = {
  [P in keyof T]: Awaited<ReturnType<T[P]>>
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
export const sleepSeconds = (seconds: number) => sleep(seconds * 1000)

/**
 * 非同期関数をチャンクごとに分けて実行する
 * @param promiseFuncs 非同期関数の配列
 * @param chunkSize チャンクサイズ
 * @param sleepTime チャンクごとの実行間隔時間（ms）
 * @returns
 */
export const processInChunks = async <T extends PromiseFunc[]>(
  promiseFuncs: [...T],
  chunkSize: number,
  sleepTime = 0,
): Promise<AwaitedTuple<T>> => {
  const chunks: PromiseFunc[][] = []
  for (let i = 0; i < promiseFuncs.length; i += chunkSize) {
    chunks.push(promiseFuncs.slice(i, i + chunkSize))
  }

  const results: unknown[] = []
  for (const chunk of chunks) {
    const chunkResults = await Promise.all(chunk.map(func => func()))
    results.push(...chunkResults)
    await sleep(sleepTime)
  }

  return results as AwaitedTuple<T>
}

/**
 * 非同期 task をリトライする
 *
 * @param task
 * @param options.maxRetryCount デフォルト: 3
 * @param options.backOff デフォルト: 1.5 ** triedCount * 1000
 * @param options.errorsMapper デフォルト: 発生したエラーの配列を文字列にしたメッセージを格納した Error に変換する
 * @returns
 */
export const retry = async <T>(
  task: () => Promise<T>,
  options: {
    maxRetryCount?: number
    backOff?: (triedCount: number) => number
    errorsMapper?: (errors: unknown[]) => Error
  } = {},
): Promise<T | Error> => {
  const maxRetryCount = options.maxRetryCount ?? 3
  const backOff =
    options.backOff ?? ((triedCount: number) => 1.5 ** triedCount * 1000)
  const errorsMapper =
    options.errorsMapper ?? ((errors: unknown[]) => new Error(String(errors)))

  let triedCount = 0
  const errors: unknown[] = []
  while (triedCount < maxRetryCount) {
    try {
      triedCount && (await sleep(backOff(triedCount)))
      return await task()
    } catch (e) {
      errors.push(e)
    }
    triedCount++
  }
  return errorsMapper(errors)
}
