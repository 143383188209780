import { isNullish } from './isNullish'

/**
 * 指定された key がまだ map に存在しない場合、f によって生成された値を key に紐付けて map にセットした上で返す。
 * 指定された key がすでに map に存在する場合、key に対応する値を返す。
 *
 * @param map
 * @param key
 * @param f この関数は、null or undefined を返してはならない
 *
 */
export const computeIfAbsent = <K, V>(
  map: Map<K, V>,
  key: K,
  f: (key: K) => V,
): V => {
  const currentValue = map.get(key)
  if (currentValue) {
    return currentValue
  }
  const newValue = f(key)
  if (isNullish(newValue)) {
    throw new Error(`The given 'f' must return none-nullish value`)
  }
  map.set(key, newValue)
  return newValue
}
