import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { bannerHeight } from '../components/OfflineModeBanner/OfflineModeBanner'

export const defaultHeaderHeight = 64

export const useHeaderHeight = () => {
  const isOffline = useOfflineModeStore(state => state.offlineMode)
  const headerHeight = isOffline
    ? bannerHeight + defaultHeaderHeight
    : defaultHeaderHeight

  return {
    headerHeight,
  } as const
}
