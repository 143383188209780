import { Configuration } from '@ulysses-inc/harami_api_client'
import { getBasePath } from '~/config'
import { getHeaders } from './headers'
import type { BaseAPI } from '@ulysses-inc/harami_api_client'

export const useUnAuthApi = () => {
  const getApi = <T extends BaseAPI>(api: new (config: Configuration) => T) => {
    const config = new Configuration({
      basePath: getBasePath(),
      headers: getHeaders(),
    })
    return new api(config)
  }

  return { getApi }
}
