import type { ReportNodeSchemaLatest } from '~/adapter/indexedDB/types'
import type { InformationDocumentAnswer } from '~/domain/report/model/report/node/questionNode/informationDocumentQuestionNode/informationDocumentAnswer'
import type { InformationDocumentQuestionNode } from '~/domain/report/model/report/node/questionNode/informationDocumentQuestionNode/informationDocumentQuestionNode'
import { convertReadonlyImage, convertReportImage } from '../image/convertImage'
import { convertMemo } from './convertMemo'

type InformationDocumentQuestionNodeSchema = Extract<
  ReportNodeSchemaLatest['data']['nodes'][0],
  { type: 'question'; questionType: 'informationDocument' }
>
type InformationAnswerSchema = InformationDocumentQuestionNodeSchema['answer']

export const convertInformationDocumentQuestionNode = async (
  node: InformationDocumentQuestionNode,
): Promise<InformationDocumentQuestionNodeSchema> => {
  const answer = await convertAnswer(node.answer)
  const information =
    node.question.information &&
    (await convertReadonlyImage(node.question.information))

  return {
    ...node,
    question: {
      ...node.question,
      information,
    },
    answer,
    memo: await convertMemo(node.memo),
  }
}

const convertAnswer = async (
  answer: InformationDocumentAnswer | undefined,
): Promise<InformationAnswerSchema | undefined> => {
  if (answer === undefined) {
    return undefined
  }

  return {
    ...answer,
    image: await convertReportImage(answer.image),
  }
}
