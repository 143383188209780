import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import type { Analytics } from 'firebase/analytics'

let firebaseAnalytics: Analytics | undefined = undefined

/**
 * このファイルをインポートすることでfirebase の初期化が行われる。
 */

const apiKey = import.meta.env.VITE_FIREBASE_API_KEY
const authDomain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
const databaseURL = import.meta.env.VITE_FIREBASE_DATABASE_URL
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID
const storageBucket = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
const messagingSenderId = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID
const appId = import.meta.env.VITE_FIREBASE_APP_ID
const measurementId = import.meta.env.VITE_FIREBASE_MEASUREMENT_ID

if (
  apiKey &&
  authDomain &&
  databaseURL &&
  projectId &&
  storageBucket &&
  messagingSenderId &&
  appId &&
  measurementId
) {
  const firebaseConfig = {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId,
  }

  const app = initializeApp(firebaseConfig)
  firebaseAnalytics = getAnalytics(app)
}

export default firebaseAnalytics
