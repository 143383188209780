import type { HTTPMethod } from '@ulysses-inc/harami_api_client'

// unknown でも大丈夫そうだが、現状で必要なもののみに絞っておく
type AcceptableParamValueType = string | number | boolean | undefined
type ParamsType = Record<string, AcceptableParamValueType>
type QueryKeyArrayType = [string, HTTPMethod, ParamsType, unknown, ParamsType]

export const getQueryKey = (
  path: string,
  method: HTTPMethod,
  queryParams: ParamsType = {},
  requestBody: object = {},
  additionalVariables: ParamsType = {},
): QueryKeyArrayType => {
  // TODO: このチケット https://kaminashi.atlassian.net/browse/INE-1469 で整理
  // labels: ph.3

  // key の配列の要素は、object でも問題なさそうである
  // https://tanstack.com/query/latest/docs/framework/react/guides/query-keys
  return [path, method, queryParams, requestBody, additionalVariables]
}
