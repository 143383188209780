import { computeIfAbsent } from '~/utils/map'
import { type NodeId, type NodeDict, Node } from '../../model/report/node/node'
import { InstanceSectionNode } from '../../model/report/node/sectionNode/repeatableSectionNode/instanceSectionNode/instanceSectionNode'

/**
 * node のディクショナリーのデータ構造を model で扱うのに適した状態にする機能を提供するクラス
 */
export class NodesRepairer {
  private readonly instanceNodesByMasterNodeId: Map<
    NodeId,
    InstanceSectionNode[]
  >

  constructor(nodeDict: NodeDict) {
    this.instanceNodesByMasterNodeId = new Map()
    Object.values(nodeDict).forEach(node => {
      if (InstanceSectionNode.isInstanceSectionNode(node)) {
        // 条件分岐内の繰り返しインスタンスの位置修正のためにMapに格納しておく
        computeIfAbsent(
          this.instanceNodesByMasterNodeId,
          node.section.masterNodeId,
          () => [],
        ).push(node)
      }
    })
  }

  /**
   * 親ノードが異なるマスターとインスタンスがあれば、インスタンスをマスターセクションの兄弟位置に移動する。
   * 以下Notion記載のデータ構造をあるべき姿に修正している。
   *
   * @param pageNodeIds
   * @param nodeDict
   * @see https://www.notion.so/7c49aa2bbe744298aedf655c9a990fc5?pvs=4#83483b47f4624b3e9718276f18d5f004
   */
  repairInstanceSectionNodePosition(pageNodeIds: number[], nodeDict: NodeDict) {
    // 条件分岐内の繰り返しセクションデータ修正
    const nodeIdPaths = Node.generateNodeIdPaths(pageNodeIds, nodeDict)

    for (const masterNodeId of this.instanceNodesByMasterNodeId.keys()) {
      const masterParentNode = Node.getParentNode(
        masterNodeId,
        nodeIdPaths,
        nodeDict,
      )

      const instanceNodeIdsToModify: NodeId[] = []
      this.instanceNodesByMasterNodeId
        .get(masterNodeId)
        ?.forEach(instanceNode => {
          const instanceParentNode = Node.getParentNode(
            instanceNode.id,
            nodeIdPaths,
            nodeDict,
          )

          if (masterParentNode.id !== instanceParentNode.id) {
            instanceNodeIdsToModify.push(instanceNode.id)
            instanceParentNode.nodes = instanceParentNode.nodes.filter(
              nodeId => nodeId !== instanceNode.id,
            )
          }
        })

      const shouldInsertIndex = masterParentNode.nodes.findIndex(
        nodeId => masterNodeId === nodeId,
      )
      masterParentNode.nodes.splice(
        shouldInsertIndex + 1,
        0,
        ...instanceNodeIdsToModify,
      )
    }
  }
}
