import { Alert, Snackbar as MuiSnackbar, Portal } from '@mui/material'
import { useSnackbarStore } from './store'

export function Snackbar() {
  const message = useSnackbarStore(state => state.message)
  const hideMessage = useSnackbarStore(state => state.hideMessage)

  return (
    <Portal>
      <MuiSnackbar
        anchorOrigin={message?.options?.anchorOrigin}
        open={message != undefined}
        key={message?.key}
        onClose={(_, reason) => {
          // スナックバーの外をクリックしても閉じないようにする
          if (reason === 'clickaway') return
          hideMessage()
        }}
        autoHideDuration={message?.options?.autoHideDuration}
        sx={{ mb: '84px' }} // フッターと被らないようするため
      >
        <Alert
          onClose={hideMessage}
          severity={message?.options?.severity}
          variant={message?.options?.variant}
          icon={message?.options?.icon}
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {message?.content}
        </Alert>
      </MuiSnackbar>
    </Portal>
  )
}
