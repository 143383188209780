import type { PlaceNode } from '@ulysses-inc/harami_api_client'

export type Employee = {
  name: string
  code: string
  /* 画面表示では使用していないが、DBに登録するために定義 */
  kana: string
  /* 選択された従業員マスタが紐づけられた現場。画面表示では使用していないが、DBに登録するために定義 */
  placeNodes: PlaceNode[]
}

const extractEmployeesByCodes = (employees: Employee[], codes: string[]) => {
  return employees.filter(employee => codes.includes(employee.code))
}

export const Employee = {
  extractEmployeesByCodes,
}
