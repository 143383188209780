type Sign = '+' | '-'
export type NumChar = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'

export type NumberDraftAnswer = {
  /**
   * 数値の符号
   */
  sign: Sign
  /**
   * 数値入力された文字列。小数点を含む場合もある。
   * 入力途中なので数値として不完全な場合もある。
   * 例) "0."
   */
  value: string
}

/**
 * 表示用の文字列に変換した値を返す。
 *
 * @param draftAnswer
 * @returns
 */
const toDisplayStringDraftAnswer = (
  draftAnswer: NumberDraftAnswer | undefined,
): string => {
  if (!draftAnswer) {
    return ''
  }

  if (draftAnswer.sign === '+') {
    return draftAnswer.value
  }

  return `${draftAnswer.sign}${draftAnswer.value}`
}

/**
 * 数値型に変換するした値を返す。
 *
 * 数値として不完全な場合はNaNを返す。
 * 例えば、valueが空文字の場合など。
 *
 * @param draftAnswer
 * @returns
 */
const toNumber = (draftAnswer: NumberDraftAnswer): number => {
  return Number(draftAnswer.sign + draftAnswer.value)
}

const initialNumberDraftAnswer: NumberDraftAnswer = {
  sign: '+',
  value: '',
}

const createInitialDraftAnswer = (
  opts?: Partial<NumberDraftAnswer>,
): NumberDraftAnswer => {
  return {
    ...initialNumberDraftAnswer,
    ...opts,
  }
}

const createNumberDraftAnswer = (value: number): NumberDraftAnswer => {
  if (value < 0) {
    return {
      sign: '-',
      value: String(-value),
    }
  }

  return {
    sign: '+',
    value: String(value),
  }
}

/**
 * 既に小数点桁数制限いっぱいに入力されていた場合はtrueを返す。
 * 制限がない場合はfalseを返す。
 *
 * @param numberString
 * @param decimalPlacesLimit
 * @returns
 */
const isDecimalPlacesGreaterEqualLimit = (
  numberString: string,
  decimalPlacesLimit?: number,
): boolean => {
  if (decimalPlacesLimit === undefined) {
    return false
  }

  const fractionalPart = numberString.split('.')[1]
  if (!fractionalPart) {
    return false
  }

  return fractionalPart.length >= decimalPlacesLimit
}

/**
 * 数値文字列"0"~"9"を追加する。
 *
 * @param draftAnswer
 * @param value - 数値文字列"0"~"9"
 * @param decimalPlacesLimit - 小数点以下の桁数制限を指定。既に制限いっぱいに入力されていた場合は数値文字追加しない。未指定なら無制限に入力できる。
 * @returns
 */
const addNumberChar = (
  draftAnswer: NumberDraftAnswer,
  value: NumChar,
  decimalPlacesLimit?: number,
): NumberDraftAnswer => {
  // 既に小数点桁数制限いっぱいに入力されていた場合は追加しない
  if (isDecimalPlacesGreaterEqualLimit(draftAnswer.value, decimalPlacesLimit)) {
    return { ...draftAnswer }
  }

  return {
    ...draftAnswer,
    value: draftAnswer.value + value,
  }
}

/**
 * value文字列から一文字削除する。
 * value文字列が空の場合は何もしない。
 *
 * @param draftAnswer
 * @returns
 */
const deleteNumberChar = (
  draftAnswer: NumberDraftAnswer,
): NumberDraftAnswer => {
  if (draftAnswer.value.length === 0) {
    return {
      ...draftAnswer,
    }
  }

  return {
    ...draftAnswer,
    value: draftAnswer.value.slice(0, -1),
  }
}

/**
 * valueに小数点文字を追加する。
 * 既に小数点がある場合は追加しない。
 *
 * @param draftAnswer
 * @returns
 */
const addDecimalPoint = (draftAnswer: NumberDraftAnswer): NumberDraftAnswer => {
  // 入力がない場合、小数点を追加しない
  if (draftAnswer.value.length === 0) {
    return {
      ...draftAnswer,
    }
  }

  // 既に小数点がある場合、小数点を追加しない
  if (draftAnswer.value.includes('.')) {
    return {
      ...draftAnswer,
    }
  }

  return {
    ...draftAnswer,
    value: draftAnswer.value + '.',
  }
}

/**
 * 符号を切り替える
 *
 * @param draftAnswer
 * @returns
 */
const changeSign = (draftAnswer: NumberDraftAnswer): NumberDraftAnswer => {
  return {
    ...draftAnswer,
    sign: draftAnswer.sign === '+' ? '-' : '+',
  }
}

export const NumberDraftAnswer = {
  toDisplayStringDraftAnswer,
  toNumber,
  createInitialDraftAnswer,
  createNumberDraftAnswer,
  addNumberChar,
  deleteNumberChar,
  addDecimalPoint,
  changeSign,
}
