import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'

import { routes } from './paths'

type Props = {
  children: ReactNode
}

export function OnlyOnlineRoute({ children }: Props) {
  const isOffline = useOfflineModeStore(state => state.offlineMode)
  if (isOffline) {
    return <Navigate to={routes.home.buildPath({})} />
  }
  return children
}
