import { useQuery } from '@tanstack/react-query'
import { LoginUserApi } from '@ulysses-inc/harami_api_client'
import { useAuthApi } from '~/adapter/api/useAuthApi'
import { getQueryKey } from '~/lib/tanstackQuery/queryKey'

export const useQueryLoginUserContext = ({ enabled } = { enabled: true }) => {
  const { getApi } = useAuthApi()

  return useQuery({
    queryKey: getQueryKey('/loginUser/context', 'GET'),
    queryFn: async () => {
      return await getApi(LoginUserApi).getLoginUserContext()
    },
    enabled,
    // NOTE: 全体オプションではtrueになっている。このクエリを使う箇所共通化されているような箇所のため、ErrorBoundaryでエラーをキャッチして処理するよりも、例外的に個別にハンドリングする方がスッキリするため
    throwOnError: false,
    // NOTE: staleTime と gcTime を 0 に設定することで、常に最新のデータを取得する
    gcTime: 0,
    staleTime: 0,
  })
}
