import { UnhandledInReportingError } from '../../../error'
import { MultipleChoiceSetAnswer } from './multipleChoiceSetAnswer'
import type {
  Choice,
  MultipleChoiceSetQuestion,
} from './multipleChoiceSetQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type MultipleChoiceSetQuestionNode = QuestionNodeBase<'responseSet'> & {
  question: MultipleChoiceSetQuestion
  answer: MultipleChoiceSetAnswer | undefined
}

const isMultipleChoiceSetQuestionNode = (
  node: Node | undefined,
): node is MultipleChoiceSetQuestionNode => {
  return node?.type === 'question' && node.questionType === 'responseSet'
}

type RequireMultipleChoiceSetQuestionNode = (
  node: Node | undefined,
) => asserts node is MultipleChoiceSetQuestionNode
const requireMultipleChoiceSetQuestionNode: RequireMultipleChoiceSetQuestionNode =
  node => {
    if (!isMultipleChoiceSetQuestionNode(node)) {
      throw new UnhandledInReportingError(
        `node is not multiple choice set question node.`,
        {
          node,
        },
      )
    }
  }

const changeChoiceUuids = (
  node: MultipleChoiceSetQuestionNode,
  choiceUuids: string[],
): MultipleChoiceSetQuestionNode => {
  const answer =
    choiceUuids.length === 0
      ? undefined
      : MultipleChoiceSetAnswer.createMultipleChoiceSetAnswer(choiceUuids)
  return {
    ...node,
    answer,
  }
}

const getAnsweredChoices = (
  node: MultipleChoiceSetQuestionNode,
): Choice[] | undefined => {
  const answer = node.answer
  if (answer === undefined) {
    return undefined
  }

  const { choices } = node.question
  return choices.filter(choice => answer.choiceUuids.includes(choice.uuid))
}

const _MultipleChoiceSetQuestionNode = {
  isInvalidAnswer: () => false,
  isMultipleChoiceSetQuestionNode,
  changeChoiceUuids,
  getAnsweredChoices,
} satisfies QuestionNodeInterface<MultipleChoiceSetQuestionNode>

export const MultipleChoiceSetQuestionNode: typeof _MultipleChoiceSetQuestionNode & {
  requireMultipleChoiceSetQuestionNode: RequireMultipleChoiceSetQuestionNode
} = {
  ..._MultipleChoiceSetQuestionNode,
  requireMultipleChoiceSetQuestionNode,
}
