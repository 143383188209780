import { UnhandledInReportingError } from '../../../error'
import { MultipleChoiceAnswer } from './multipleChoiceAnswer'
import type {
  ChoiceItem,
  MultipleChoiceQuestion,
} from './multipleChoiceQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type MultipleChoiceQuestionNode = QuestionNodeBase<'multipleChoice'> & {
  question: MultipleChoiceQuestion
  answer: MultipleChoiceAnswer | undefined
}

const isMultipleChoiceQuestionNode = (
  node: Node | undefined,
): node is MultipleChoiceQuestionNode => {
  return node?.type === 'question' && node.questionType === 'multipleChoice'
}

type RequireMultipleChoiceQuestionNode = (
  node: Node | undefined,
) => asserts node is MultipleChoiceQuestionNode
const requireMultipleChoiceQuestionNode: RequireMultipleChoiceQuestionNode =
  node => {
    if (!isMultipleChoiceQuestionNode(node)) {
      throw new UnhandledInReportingError(
        `node is not multiple choice question node.`,
        {
          node,
        },
      )
    }
  }

const selectItem = (
  node: MultipleChoiceQuestionNode,
  selectedItemKey: string,
): MultipleChoiceQuestionNode => {
  return {
    ...node,
    answer: MultipleChoiceAnswer.createMultipleChoiceAnswer(selectedItemKey),
  }
}

const deselectItem = (
  node: MultipleChoiceQuestionNode,
): MultipleChoiceQuestionNode => {
  return {
    ...node,
    answer: undefined,
  }
}

/**
 * 回答した選択肢(ChoiceItem)を取得する.
 * まだ回答していない場合にはundefinedを返す.
 *
 * Note:
 * 毎回、回答した選択肢のitemを取得するためにchoiceItemsの数ループしているので、
 * 速度が気になるようであれば探索がN(1)になるようにデータ構造を見直す.
 *
 * @param node
 */
const getAnsweredChoiceItem = (
  node: MultipleChoiceQuestionNode,
): ChoiceItem | undefined => {
  const answeredItemKey = node.answer?.selectedItemKey
  if (answeredItemKey === undefined) {
    return undefined
  }

  return node.question.choiceItems.find(item => item.key === answeredItemKey)
}

/**
 * 回答した選択肢が逸脱に設定された選択肢かどうかを判定する.
 * まだ回答していない場合にはfalseを返す.
 *
 * @param node
 * @returns
 */
const isInvalidAnswer = (node: MultipleChoiceQuestionNode): boolean => {
  const answeredItem = getAnsweredChoiceItem(node)
  if (answeredItem === undefined) {
    return false
  }

  return answeredItem.isInvalid
}

const hasNonDefaultAnswer = (node: MultipleChoiceQuestionNode): boolean => {
  if (node.answer === undefined) {
    return false
  }

  // デフォルト回答の場合recordedAtがundefinedになっている
  if (node.answer.recordedAt === undefined) {
    return false
  }

  return true
}

const toUserFriendlyAnswerString = (
  node: MultipleChoiceQuestionNode,
): string => {
  const answeredItem = getAnsweredChoiceItem(node)
  if (answeredItem === undefined) {
    return ''
  }

  return answeredItem.label
}

const _MultipleChoiceQuestionNode = {
  isInvalidAnswer,
  selectItem,
  deselectItem,
  getAnsweredChoiceItem,
  hasNonDefaultAnswer,
  isMultipleChoiceQuestionNode,
  toUserFriendlyAnswerString,
} satisfies QuestionNodeInterface<MultipleChoiceQuestionNode>

export const MultipleChoiceQuestionNode: typeof _MultipleChoiceQuestionNode & {
  requireMultipleChoiceQuestionNode: RequireMultipleChoiceQuestionNode
} = {
  ..._MultipleChoiceQuestionNode,
  requireMultipleChoiceQuestionNode,
}
