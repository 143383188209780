export type Extra = Record<string, unknown>

/**
 * なんらかのコンテキスト情報 (extra) を付加した上で throw したい場合に使用するカスタムエラークラス
 *
 */
export class ErrorWithExtra extends Error {
  public extra?: Extra

  constructor(name: string, message: string, extra?: Extra) {
    super(message)
    this.name = name
    this.extra = extra
  }
}
