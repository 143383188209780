import type { Employee } from '../../../employee/employee'
import type { AnswerBase } from '../answer'

export type EmployeeAnswer = AnswerBase<'employee'> & {
  employees: Employee[]
}

const create = (employees: Employee[]): EmployeeAnswer | undefined => {
  if (employees.length === 0) {
    return undefined
  }
  return {
    type: 'employee',
    employees,
    recordedAt: new Date(),
  }
}

export const EmployeeAnswer = {
  create,
}
