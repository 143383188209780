import { getExtension } from '~/utils/getExtension'
import type { UploadedFile } from '../report/uploadedFile/uploadedFile'
// TODO: Imageとあるが概念としてはMediaの方が適切かもしれないので、今後の拡張時に検討する
// labels: ph.3
type ImageBase = {
  key: string
  recordedAt: Date
}

/*
 * レポート記録では、写真・画像・PDFなどのメディアには、少なくとも以下の３つの種類があり、それぞれ異なった扱いをしなくてはならない
 *
 * - A. レポート記録時に生成されたもので、本来アップロードが必要だが、まだアップロードされておらず、ローカルの端末上に保存されている
 * - B. すでにアップロードされていて、サーバー（CDN,cloudfront) 上のリソースを url で参照する
 * - C. すでにアップロードされていて、サーバー（CDN,cloudfront) 上のリソースではあるものの、ローカルにあるダウンロードデータを参照する
 *
 * 現時点では、これらのメディアの特性に応じて、メディアのロケーション・編集可・不可あたりを意識した名称で分類している
 *
 * A: LocalImage： ロケーションはローカル、編集可能
 * B: UploadedImage： ロケーションはリモートのサーバー。リモートにあれば当然アプリ上では編集不可
 * C: LocalReadOnlyImage： ロケーションはローカル、編集不可
 *
 */

export type LocalImage = ImageBase & {
  type: 'local'
  file: File
}

// TODO: LocalImage との対比で、RemoteImage などの方がわかりやすいかもしれない
// labels: ph.3
export type UploadedImage = ImageBase & {
  type: 'uploaded'
  file: UploadedFile
}

export type LocalReadonlyImage = ImageBase & {
  type: 'localRo'
  underlyingRemoteFile: UploadedFile
  content: File
}

// TODO: これは WritableImage などもう少し性質がわかりやすい名前に変更する
// labels: ph.3
export type Image = LocalImage | UploadedImage
export type ReadonlyImage = UploadedImage | LocalReadonlyImage

// TODO: 概念を整理する時に名称も見直す
// labels: ph.3
export type GenericImage = LocalImage | UploadedImage | LocalReadonlyImage

const isLocalImage = (image: GenericImage): image is LocalImage => {
  return image.type === 'local'
}
const isUploadedImage = (image: GenericImage): image is UploadedImage => {
  return image.type === 'uploaded'
}

const isLocalReadonlyImage = (
  image: GenericImage,
): image is LocalReadonlyImage => {
  return image.type === 'localRo'
}

const getRemoteResourceInfo = (
  image:
    | Pick<UploadedImage, 'type' | 'file'>
    | Pick<LocalReadonlyImage, 'type' | 'underlyingRemoteFile'>,
): UploadedFile => {
  switch (image.type) {
    case 'uploaded':
      return image.file
    case 'localRo':
      return image.underlyingRemoteFile
  }
}

const getSource = (image: GenericImage): { url: string } | { file: File } => {
  switch (image.type) {
    case 'uploaded':
      return { url: image.file.url }
    case 'local':
      return { file: image.file }
    case 'localRo':
      return { file: image.content }
  }
}

const getRawSource = (image: GenericImage): string | File => {
  const source = getSource(image)
  if ('file' in source) {
    return source.file
  }
  return source.url
}

const isPdf = (url: string) => {
  return getExtension(url) === 'pdf'
}

const getDocumentType = (image: GenericImage) => {
  if (isUploadedImage(image) && isPdf(image.file.url)) {
    return 'pdf'
  }
  if (isLocalReadonlyImage(image) && isPdf(image.underlyingRemoteFile.url)) {
    return 'pdf'
  }
  return 'image'
}

const toFile = (blob: Blob): File => {
  return new File([blob], 'resultImage.jpg', { type: 'image/jpeg' })
}

export const Image = {
  isLocalImage,
  isUploadedImage,
  getDocumentType,
  getRemoteResourceInfo,
  getSource,
  getRawSource,
  toFile,
}
