import { useCallback } from 'react'
import type { DBType } from '~/adapter/indexedDB/db'
import {
  VersionInconsistencyError,
  getDB as nakedGetDB,
} from '~/adapter/indexedDB/db'
import { useOfflineReportVersionConsistency } from '~/stores/offlineVer/offlineVer'

/**
 * 端末のストレージ(通常、indexedDB)を利用する時に使用するカスタムフック。
 * getDB が Error とともに resolve された場合に、そのエラーの Sentry 通知は呼び出し元で必要に応じて行う
 *
 * @returns
 */
export const useGetDB = () => {
  const inconsistentVersionDetected = useOfflineReportVersionConsistency(
    state => state.inconsistentVersionDetected,
  )
  const inconsistentVersionRestored = useOfflineReportVersionConsistency(
    state => state.inconsistentVersionRestored,
  )

  const getDB = useCallback(async (): Promise<DBType | Error> => {
    try {
      const db = await nakedGetDB()
      inconsistentVersionRestored()
      return db
    } catch (e) {
      if (e instanceof VersionInconsistencyError) {
        inconsistentVersionDetected()
        return e
      } else {
        return new Error(`Failed to get DB: ${e}`)
      }
    }
  }, [inconsistentVersionDetected, inconsistentVersionRestored])

  const getDBWithThrow = useCallback(async (): Promise<DBType> => {
    const db = await getDB()
    if (db instanceof Error) {
      throw db
    }
    return db
  }, [getDB])

  return { getDB, getDBWithThrow }
}
