import React from 'react'
import ReactDOM from 'react-dom/client'
import { initializeSentry } from './lib/sentry/initialize'
import { Providers } from './providers/Providers'
import './lib/firebaseAnalytics/analytics'

initializeSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Providers />
  </React.StrictMode>,
)
