import { UnhandledInReportingError } from '../../../error'
import type { InformationUrlAnswer } from './informationUrlAnswer'
import type { InformationUrlQuestion } from './informationUrlQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type InformationUrlQuestionNode = QuestionNodeBase<'informationUrl'> & {
  question: InformationUrlQuestion
  answer: InformationUrlAnswer | undefined
}

const isInformationUrlQuestionNode = (
  node: Node | undefined,
): node is InformationUrlQuestionNode => {
  return node?.type === 'question' && node.questionType === 'informationUrl'
}

type RequireInformationUrlQuestionNode = (
  node: Node | undefined,
) => asserts node is InformationUrlQuestionNode

const requireInformationUrlQuestionNode: RequireInformationUrlQuestionNode =
  node => {
    if (!isInformationUrlQuestionNode(node)) {
      throw new UnhandledInReportingError(
        `node is not information url question node.`,
        {
          node,
        },
      )
    }
  }

const recordInformationUrlOpenedAt = (
  node: InformationUrlQuestionNode,
  openedAt: Date,
): InformationUrlQuestionNode => ({
  ...node,
  answer: {
    type: 'informationUrl',
    recordedAt: openedAt,
  },
})

const _InformationUrlQuestionNode = {
  isInvalidAnswer: () => false,
  isInformationUrlQuestionNode,
  recordInformationUrlOpenedAt,
} satisfies QuestionNodeInterface<InformationUrlQuestionNode>

export const InformationUrlQuestionNode: typeof _InformationUrlQuestionNode & {
  requireInformationUrlQuestionNode: RequireInformationUrlQuestionNode
} = {
  ..._InformationUrlQuestionNode,
  requireInformationUrlQuestionNode: requireInformationUrlQuestionNode,
}
