import { useCallback } from 'react'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { useUserPlaceNodeStore } from '~/stores/place/place'
import { useSessionStore } from '~/stores/session/session'

/**
 * zustandでpersistしているデータをまとめてクリアする。
 * ログアウト時に加えて、ログイン中に重ねてログインされることを考慮しログイン時にも呼び出す想定。
 *
 * @returns
 */
export const useClearAllPersistedData = () => {
  const logoutOfflineMode = useOfflineModeStore(
    state => state.logoutOfflineMode,
  )
  const logoutSession = useSessionStore(state => state.logout)
  const logoutUserPlaceNode = useUserPlaceNodeStore(state => state.logout)

  const clearAllPersistedData = useCallback(() => {
    logoutSession()
    logoutUserPlaceNode()
    logoutOfflineMode()
  }, [logoutOfflineMode, logoutSession, logoutUserPlaceNode])

  return { clearAllPersistedData }
}
