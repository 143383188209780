import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import {
  setUserProperties,
  logEvent,
} from '~/adapter/firebaseAnalytics/userProperties'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { useSessionStore, selectIsAuthed } from '~/stores/session/session'
import type { GetLoginUserContextResponse } from '@ulysses-inc/harami_api_client'
/**
 * ログインユーザーの情報に基づいてSentry、Firebase analytics での監視のセッションをスタートさせる
 *
 * @param loginUserInfo
 * @param shouldSuppressEventLog
 */
const browserSessionObservationStartedWithLoginUser = (
  loginUserInfo: {
    userUuid: string | undefined
    userName: string | undefined
    companyId: number | undefined
    companyName: string | undefined
  },
  shouldSuppressEventLog: boolean,
) => {
  const { userUuid, userName, companyId, companyName } = loginUserInfo
  if (!userUuid) return

  try {
    // エラーを吐く処理なのかどうかわからないため、念の為、try ... catch しておく
    // やりすぎな気もするが、認証まわりの重要な処理に影響を与えたくないため、やっておく
    Sentry.setUser({ id: userUuid })
    Sentry.setTag('companyId', companyId)
  } catch (e) {
    Sentry.captureException(e)
  }

  setUserProperties({ userUuid, userName, companyId, companyName })

  if (shouldSuppressEventLog) return

  logEvent('harami_report_session_start', {})
}

/**
 * エラーや利用状況の監視で用いる Sentry、Firebase analytics のセッションをスタートさせる
 * オフラインモード時は、LocalStorage に保存されている情報を元に、セットアップを行うが、
 * Firebase にはイベントを送信しない
 *
 * @param loginUser
 */
export const useBrowserSessionObservation = (
  loginUser: GetLoginUserContextResponse | undefined,
): void => {
  const isAuthed = useSessionStore(selectIsAuthed)
  const user = useSessionStore(state => state.user)
  const shouldSyncNow = !useOfflineModeStore(state => state.offlineMode)

  useEffect(() => {
    if (!shouldSyncNow) {
      browserSessionObservationStartedWithLoginUser(
        // Sentry はオフライン状態に対応しているため、オフラインでセッションがスタートされた時も情報をセットしておく必要がある
        // Firebase はセットしてもしなくてもどちらでも構わないが、共通処理を利用する都合でセットしている
        {
          userUuid: user?.uuid,
          userName: user?.name,
          companyId: user?.company.id,
          companyName: user?.company.name,
        },
        // オンライン状態でセッションをスタートした時のみ、Firebase Analytics にイベント送信する
        // オフラインモードで、セッションをスタートした場合、解除後に改めてイベントが送信されるので、タイミング的に不足はないはず
        true,
      )
      return
    }
    if (!loginUser) return
    if (!isAuthed) return
    browserSessionObservationStartedWithLoginUser(loginUser, false)
  }, [
    isAuthed,
    loginUser,
    shouldSyncNow,
    user?.company.id,
    user?.company.name,
    user?.name,
    user?.uuid,
  ])
}
