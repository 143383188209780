import { useMatch } from 'react-router-dom'
import { routes } from '~/app/routes/paths'

const asBoolean = (v: unknown): boolean => !!v

export const useIsInReporting = () => {
  const pathMatchReportsNew = useMatch(routes.reportsNew)
  const pathMatchReportsEdit = useMatch(routes.reportsEdit)
  const pathMatchReportsNewFromSchedule = useMatch(
    routes.reportsNewFromSchedule,
  )
  const pathMatchReportsOfflineEdit = useMatch(routes.reportsOfflineEdit)

  return asBoolean(
    pathMatchReportsNew ||
      pathMatchReportsNewFromSchedule ||
      pathMatchReportsEdit ||
      pathMatchReportsOfflineEdit,
  )
}
