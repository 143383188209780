import { unselectedPlaceNodeUuid } from '~/adapter/indexedDB/constants'
import type { EmployeeSchemaLatest } from '~/adapter/indexedDB/types'
import type { Employee } from '~/domain/report/model/report/employee/employee'

/**
 * Store から IndexedDB に保存するスキーマへ変換する
 *
 * @param storeEmployees store に保存されている従業員一覧
 * @param hasMoreEmployees 選択現場に紐づく従業員数が最大数を超えている場合true
 * @param currentPlaceNodeUuid 現在選択されている現場ノードの UUID。現場未選択の場合は undefined
 * @param companyId ログインユーザーの companyId
 * @returns
 */
export const convertEmployees = (
  storeEmployees: Employee[],
  hasMoreEmployees: boolean,
  currentPlaceNodeUuid: string | undefined,
  companyId: number,
): EmployeeSchemaLatest => {
  return {
    // 現場未選択の場合は処理の都合上固定値をセットする
    placeNodeUuid: currentPlaceNodeUuid ?? unselectedPlaceNodeUuid,
    companyId,
    hasMoreEmployees,
    employees: storeEmployees.map(employee => ({
      name: employee.name,
      code: employee.code,
      kana: employee.kana,
      placeNodes: employee.placeNodes,
    })),
  }
}
