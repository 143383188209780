import { Outlet } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ErrorBoundary } from 'react-error-boundary'
import { z } from 'zod'
import { GlobalError } from '~/components/layout/GlobalError/GlobalError'
import { GlobalLayout } from '~/components/layout/GlobalLayout/GlobalLayout'
import { customErrorMap } from '~/lib/zod/customError'

z.setErrorMap(customErrorMap)

export function App() {
  return (
    <>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={GlobalError}>
        <GlobalLayout>
          <Outlet />
        </GlobalLayout>
      </ErrorBoundary>
    </>
  )
}
