import { v4 as uuidv4 } from 'uuid'

import type { InformationDocumentAnswer } from '~/domain/report/model/report/node/questionNode/informationDocumentQuestionNode/informationDocumentAnswer'
import type {
  InformationDocumentQuestion,
  InformationDocument,
} from '~/domain/report/model/report/node/questionNode/informationDocumentQuestionNode/informationDocumentQuestion'
import type { InformationDocumentQuestionNode } from '~/domain/report/model/report/node/questionNode/informationDocumentQuestionNode/informationDocumentQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ApiToModelError } from '../error'
import { convertToUploadedImage } from './imageConverter'
import type { ConversionContext } from './types'

const convertQuestion = (context: ConversionContext) => {
  const {
    question,
    base: { questionBase, node },
  } = context
  const apiInformationDocument = question.responseInformations?.[0]
  let informationDocument: InformationDocument | undefined = undefined
  if (apiInformationDocument === undefined) {
    if (question.isRequired) {
      throw new ApiToModelError(
        'The question is required but information document is undefined',
        {
          node,
        },
      )
    }
  } else {
    informationDocument = convertToUploadedImage(apiInformationDocument)
  }
  const informationDocumentQuestion: InformationDocumentQuestion = {
    ...questionBase,
    information: informationDocument,
    type: 'informationDocument',
  }
  return informationDocumentQuestion
}
const convertAnswer = (context: ConversionContext) => {
  const {
    question,
    base: { recordedAt, node },
  } = context
  let informationAnswer: InformationDocumentAnswer | undefined = undefined
  const responseAnswerInformation = question.responseAnswer?.informations?.[0]
  if (responseAnswerInformation !== undefined) {
    if (
      responseAnswerInformation.uuid === undefined ||
      responseAnswerInformation.recordedAt === undefined ||
      responseAnswerInformation.url === undefined
    ) {
      throw new ApiToModelError(
        'responseAnswer informations has no uuid, recordedAt, or url.',
        {
          node,
          responseAnswerInformation,
        },
      )
    }
    informationAnswer = {
      type: 'informationDocument',
      image: {
        key: uuidv4(),
        type: 'uploaded',
        recordedAt: responseAnswerInformation.recordedAt,
        file: {
          uuid: responseAnswerInformation.uuid,
          url: responseAnswerInformation.url,
        },
      },
      recordedAt,
    }
  }
  return informationAnswer
}
export const convertInformationDocumentQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    base: { questionNodeBase },
  } = context
  const questionNode: InformationDocumentQuestionNode = {
    ...questionNodeBase,
    questionType: 'informationDocument',
    question: convertQuestion(context),
    answer: convertAnswer(context),
  }

  return questionNode
}
