import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import type { SxProps, Theme } from '@mui/material'

const styles = {
  dialogAction: {
    padding: '8px 16px 16px',
  },
} satisfies Record<string, SxProps<Theme>>

type Props = {
  isOpen: boolean
  title: string
  contentText?: string
  buttonName?: string
  onOk: () => void
}

export function NormalMessageDialog({
  isOpen,
  title,
  contentText,
  buttonName = 'OK',
  onOk,
}: Props) {
  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      {contentText && (
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={styles.dialogAction}>
        <Button onClick={onOk} size="large">
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
