import * as Sentry from '@sentry/browser'
import { ErrorWithExtra } from '~/utils/error'

const beforeSend: Sentry.BrowserOptions['beforeSend'] = (event, hint) => {
  const error = hint.originalException
  if (error instanceof ErrorWithExtra) {
    event.extra = {
      ...event.extra,
      ...error.extra,
    }
  }
  return event
}

export const initializeSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `harami_report@${import.meta.env.VITE_APP_VERSION}`,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // NOTE: makeBrowserOfflineTransport を使用することで、
    // オフライン時は IndexedDB にイベントを保存し、オンライン時に送信するようになる
    // また、保持件数などを変更する場合は、 transportOptions を使用する
    // transportOptions については、以下のリンクを参照
    // https://docs.sentry.io/platforms/javascript/best-practices/offline-caching/
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    tracePropagationTargets: [],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    beforeSend,
  })
}
