import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { localStorageKeys } from '~/adapter/localstorage/keys'

export type OfflineModeState = {
  offlineMode: boolean
}

type OfflineModeAction = {
  /**
   * オフラインモードをオンにした時にコールされる
   *
   * @returns
   */
  offlineModeOn: () => void

  /**
   * オフラインモードをオフにした時にコールされる
   *
   * @returns
   */
  offlineModeOff: () => void

  /**
   * ログアウト時にコールされる
   *
   * @returns
   */
  logoutOfflineMode: () => void
}

export const offlineModeStoreName = localStorageKeys.offlineModeStore

/**
 * ユーザーの所属する現場・現場グループの情報および、現場設定での選択状態を保持する store。
 * ローカルストレージと同期される
 */
export const useOfflineModeStore = create<
  OfflineModeState & OfflineModeAction
>()(
  devtools(
    immer(
      persist(
        set => ({
          offlineMode: false,
          offlineModeOn: () => {
            set(state => {
              state.offlineMode = true
            })
          },
          offlineModeOff: () => {
            set(state => {
              state.offlineMode = false
            })
          },
          logoutOfflineMode: () => {
            set(state => {
              state.offlineMode = false
            })
          },
        }),
        {
          name: offlineModeStoreName,
          version: 0,
          onRehydrateStorage: () => {
            // beta キーを削除する。リリースして時間経過したらこの行を消すこと。
            localStorage.removeItem(offlineModeStoreName + '-beta')
          },
        },
      ),
    ),
  ),
)
