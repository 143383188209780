import { Header } from '../Header/Header'
import type { Props as LayoutProps } from './PageLayout'

export function PageHeader(props: LayoutProps['header']) {
  if ('component' in props) {
    return props.component
  }

  return (
    <Header
      sx={{
        borderBottom: '1px solid',
        borderColor: theme => theme.customPalette.border,
        position: 'sticky',
        top: 0,
        zIndex: theme => theme.zIndex.appBar,
      }}
      title={props.title}
      hasBackButton={props.hasBackButton}
      hasHamburgerMenu={props.hasHamburgerMenu}
      rightContent={props.rightContent}
      backButtonComponent={props.backButtonComponent}
    />
  )
}
