import type { AnswerBase } from '../answer'

export type MultipleChoiceAnswer = AnswerBase<'multipleChoice'> & {
  /**
   * 回答(選択)した選択肢のキー
   */
  selectedItemKey: string
}

const createMultipleChoiceAnswer = (
  selectedItemKey: string,
): MultipleChoiceAnswer => {
  return {
    type: 'multipleChoice',
    recordedAt: new Date(),
    selectedItemKey,
  }
}

const createDefaultMultipleChoiceAnswer = (
  selectedItemKey: string,
): MultipleChoiceAnswer => {
  return {
    type: 'multipleChoice',
    // recordedAt は、既存の挙動に合わせて undefined にしている
    // ユーザーが明示的に選択しないと recordedAt は記録されない
    recordedAt: undefined,
    selectedItemKey,
  }
}

export const MultipleChoiceAnswer = {
  createMultipleChoiceAnswer,
  createDefaultMultipleChoiceAnswer,
}
