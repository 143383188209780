import { z } from 'zod'

const ParagraphBlockSchema = z.object({
  id: z.string().optional(),
  type: z.literal('paragraph'),
  data: z.object({
    text: z.string(),
  }),
})

const HeaderBlockSchema = z.object({
  id: z.string().optional(),
  type: z.literal('header'),
  data: z.object({
    text: z.string(),
    level: z.number(),
  }),
})

const ImageBlockSchema = z.object({
  id: z.string().optional(),
  type: z.literal('image'),
  data: z.object({
    file: z.object({
      url: z.string(),
      uuid: z.string(),
    }),
    caption: z.string(),
    withBorder: z.boolean(),
    withBackground: z.boolean(),
    stretched: z.boolean(),
  }),
})

const BlockSchema = z.union([
  ParagraphBlockSchema,
  HeaderBlockSchema,
  ImageBlockSchema,
])

export const safeParseBlock = (block: unknown) => {
  return BlockSchema.safeParse(block)
}
