import { Node } from '../../model/report/node/node'
import type {
  ReportPage,
  TemplateNode,
  TemplatePage,
} from '@ulysses-inc/harami_api_client'

export interface ModelNodeIdGenerator {
  mapNodeId: (oldNodeId: number) => number
  generate: () => number
}

/**
 * フロントエンドでの処理用に、page.id、node.id を採番しなおした pages を生成する。
 * 引数の pages は変更されず、コピーされたオブジェクトのIDが採番し直されて、返される。
 *
 * NOTE:
 * IDを振り直すフィールドは以下の PR を参考に、フロントエンドの処理で必要となるものに限定している。
 *
 * - https://github.com/Ulysses-inc/harami_api_server/pull/2725
 * - https://www.notion.so/20231218-ID-cd35dec8673d45bc94cde5cecd72b080
 *
 * @param pages
 * @returns
 */
export const createPagesWithNewId = <T extends TemplatePage[] | ReportPage[]>(
  pages: T,
): {
  pages: T
  nodeIdGenerator: ModelNodeIdGenerator
} => {
  const copiedPages = structuredClone(pages)
  const nodeIdMapper: { [key: number]: number } = {}

  const generateNodeId = Node.createNodeIdGenerator({})

  const traverseNode = (node: { id: number; nodes?: TemplateNode[] }) => {
    const newNodeId = generateNodeId()

    nodeIdMapper[node.id] = newNodeId
    node.id = newNodeId

    for (const childNode of node.nodes ?? []) {
      traverseNode(childNode)
    }
  }
  for (const page of copiedPages) {
    const newPageId = generateNodeId()
    page.id = newPageId
    for (const node of page.nodes ?? []) {
      traverseNode(node)
    }
  }

  return {
    pages: copiedPages,
    nodeIdGenerator: {
      mapNodeId: (oldNodeId: number) => nodeIdMapper[oldNodeId],
      generate: generateNodeId,
    },
  }
}
