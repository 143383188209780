import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { localStorageKeys } from '~/adapter/localstorage/keys'
import type { PlaceNode } from '@ulysses-inc/harami_api_client'

type UserPlaceNodeState = {
  /**
   * ログインユーザーが所属している現場。ツリー構造で配下の現場グループ、現場も保持している
   */
  loginUserPlaceNodes: PlaceNode[]
  /**
   * 現場設定画面で最終的に選択した現場
   */
  selectedPlaceNode: PlaceNode | null

  // @deprecated
  // TODO: レポート一覧（仮）を差し替えたら不要になる想定
  // labels: ph.3
  selectedParentPlaceGroupUUIDs: string[]
}

type UserPlaceNodeAction = {
  /**
   * 新たなログインユーザーの現場が取得された時にコールされる
   *
   * @param placeNodes
   * @returns
   */
  newLoginUserPlaceNodeFetched: (placeNodes: PlaceNode[]) => void

  /**
   * 現場設定画面で現場が選択された時にコールされる
   *
   * @param placeGroupUUIDs
   * @param placeNode
   * @returns
   */
  placeSelected: (placeGroupUUIDs: string[], placeNode: PlaceNode) => void

  /**
   * ログアウト時にコールされる
   *
   * @returns
   */
  logout: () => void
}

export const userPlaceNodeStoreName = localStorageKeys.userPlaceNodeStore

/**
 * ユーザーの所属する現場・現場グループの情報および、現場設定での選択状態を保持する store。
 * ローカルストレージと同期される
 */
export const useUserPlaceNodeStore = create<
  UserPlaceNodeState & UserPlaceNodeAction
>()(
  devtools(
    immer(
      persist(
        set => ({
          loginUserPlaceNodes: [],
          selectedPlaceNode: null,
          selectedParentPlaceGroupUUIDs: [],
          newLoginUserPlaceNodeFetched: placeNodes =>
            set(state => {
              // NOTE: 所属現場情報が更新されたタイミングで自動的に選択中の現場のクリアはしない。
              // 既存の選択中の現場が新しい所属現場に含まれているか否かの判定をもって、ユーザーへの通知を行いながらクリアされるべきと思うが、現段階でそこまで実装できていないため。
              // state.selectedPlaceNode = null
              // state.selectedParentPlaceGroupUUIDs = []
              state.loginUserPlaceNodes = placeNodes
            }),
          placeSelected: (placeGroupUUIDs, placeNode) =>
            set(state => {
              state.selectedPlaceNode = placeNode
              state.selectedParentPlaceGroupUUIDs = placeGroupUUIDs
            }),
          logout: () => {
            set(state => {
              state.selectedPlaceNode = null
              state.selectedParentPlaceGroupUUIDs = []
              state.loginUserPlaceNodes = []
            })
          },
        }),
        {
          name: userPlaceNodeStoreName,
          version: 0,
          onRehydrateStorage: () => {
            // beta キーを削除する。リリースして時間経過したらこの行を消すこと。
            localStorage.removeItem(userPlaceNodeStoreName + '-beta')
          },
        },
      ),
    ),
  ),
)

/**
 * 指定された UUID の現場グループの直下の現場・現場グループを取得する
 *
 * @param state
 * @returns
 */
export const selectChildPlaceNodesOf =
  (state: UserPlaceNodeState) => (placeNodeUUID: string) => {
    if (!placeNodeUUID) {
      return state.loginUserPlaceNodes
    }
    const findPlaceNodes = (placeNodes: PlaceNode[]): PlaceNode[] => {
      for (const placeNode of placeNodes) {
        if (placeNode.uuid === placeNodeUUID) {
          return placeNode.nodes ?? []
        }
        const children = findPlaceNodes(placeNode.nodes ?? [])
        if (children.length > 0) {
          return children
        }
      }
      return []
    }
    return findPlaceNodes(state.loginUserPlaceNodes)
  }

/**
 * 現場設定で現場が選択されている場合、true を返す
 * @param state
 * @returns
 */
export const selectIsPlaceNodeSelected = (state: UserPlaceNodeState) =>
  !!state.selectedPlaceNode

/**
 * 現場設定で選択された現場の UUID を取得する。未選択の場合は空文字を返す
 *
 * @param state
 * @returns
 */
export const selectSelectedPlaceNodeUUID = (
  state: UserPlaceNodeState,
): string => state.selectedPlaceNode?.uuid ?? ''

/**
 * 現場設定で選択された現場の名前を取得する。未選択の場合は空文字を返す
 *
 * @param state
 * @returns
 */
export const selectSelectedPlaceName = (state: UserPlaceNodeState): string =>
  state.selectedPlaceNode?.place?.name ?? ''

/**
 * ログインユーザーが、少なくとも１つの現場・現場グループに所属している場合 true を返す
 *
 * @param state
 * @returns
 */
export const selectBelongsToPlace = (state: UserPlaceNodeState) =>
  state.loginUserPlaceNodes.length > 0
