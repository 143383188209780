import type { InformationUrlAnswer } from '~/domain/report/model/report/node/questionNode/informationUrlQuestionNode/informationUrlAnswer'
import type { InformationUrlQuestion } from '~/domain/report/model/report/node/questionNode/informationUrlQuestionNode/informationUrlQuestion'
import type { InformationUrlQuestionNode } from '~/domain/report/model/report/node/questionNode/informationUrlQuestionNode/informationUrlQuestionNode'
import { isNullish } from '~/utils/isNullish'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'

export const convertInformationUrlQuestionNode = (
  context: ConversionContext,
): InformationUrlQuestionNode => {
  const {
    question,
    base: { node, questionNodeBase, questionBase, recordedAt },
  } = context
  const url = question.responseURLs?.[0]?.url ?? ''
  try {
    new URL(url)
  } catch (e) {
    throw new ApiToModelError(String(e), { node })
  }

  const informationUrlQuestion: InformationUrlQuestion = {
    ...questionBase,
    type: 'informationUrl',
    url,
  }

  const informationUrlAnswer: InformationUrlAnswer | undefined = isNullish(
    recordedAt,
  )
    ? undefined
    : {
        type: 'informationUrl',
        recordedAt,
      }
  const informationUrlQuestionNode: InformationUrlQuestionNode = {
    ...questionNodeBase,
    questionType: 'informationUrl',
    question: informationUrlQuestion,
    answer: informationUrlAnswer,
  }
  return informationUrlQuestionNode
}
