import { format } from 'date-fns'
import { UnhandledInReportingError } from '../../../error'

import type { DateTimeAnswer } from './dateTimeAnswer'
import type { DateTimeQuestion } from './dateTimeQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type DateTimeQuestionNode = QuestionNodeBase<'dateTime'> & {
  question: DateTimeQuestion
  answer: DateTimeAnswer | undefined
}

const isDateTimeQuestionNode = (
  node: Node | undefined,
): node is DateTimeQuestionNode => {
  return node?.type === 'question' && node.questionType === 'dateTime'
}
type RequireDateTimeQuestionNode = (
  node: Node | undefined,
) => asserts node is DateTimeQuestionNode
const requireDateTimeQuestionNode: RequireDateTimeQuestionNode = node => {
  if (!isDateTimeQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not dateTime question node.`, {
      node,
    })
  }
}

const isInvalidAnswer = (node: DateTimeQuestionNode): boolean => {
  return node.answer?.isInvalid ?? false
}

const updateAnswer = (
  node: DateTimeQuestionNode,
  answer: Date,
  isInvalid: boolean,
): DateTimeQuestionNode => {
  return {
    ...node,
    answer: {
      type: 'dateTime',
      value: answer,
      isInvalid,
      recordedAt: new Date(),
    },
  }
}

const toUserFriendlyAnswerString = (node: DateTimeQuestionNode): string => {
  const answer = node.answer
  if (answer === undefined) {
    return ''
  }
  switch (node.question.format) {
    case 'dateTime':
      return format(answer.value, 'yyyy年MM月dd日 HH時mm分')
    case 'date':
      return format(answer.value, 'yyyy年MM月dd日')
    case 'time':
      // 現状の仕様では、時刻の場合にルールの設定はできない＝逸脱判定はないが、
      // この関数の意味合い的にあるのが正しいのでこの case も定義している
      return format(answer.value, 'HH時mm分')
  }
}

const _DateTimeQuestionNode = {
  isInvalidAnswer,
  isDateTimeQuestionNode,
  updateAnswer,
  toUserFriendlyAnswerString,
} satisfies QuestionNodeInterface<DateTimeQuestionNode>

export const DateTimeQuestionNode: typeof _DateTimeQuestionNode & {
  requireDateTimeQuestionNode: RequireDateTimeQuestionNode
} = {
  ..._DateTimeQuestionNode,
  requireDateTimeQuestionNode,
}
