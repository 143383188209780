import { UnhandledInReportingError } from '../../../error'
import { NoAnswer } from '../noAnswer'
import { NumberAnswer } from './numberAnswer'
import { NumberDraftAnswer } from './numberDraftAnswer'
import { NumberQuestion } from './numberQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type NumberQuestionNode = QuestionNodeBase<'number'> & {
  question: NumberQuestion
  answer: NumberAnswer | NoAnswer | undefined
  draftAnswer: NumberDraftAnswer | undefined
}

const isNumberQuestionNode = (
  node: Node | undefined,
): node is NumberQuestionNode => {
  return node?.type === 'question' && node.questionType === 'number'
}
type RequireNumberQuestionNode = (
  node: Node | undefined,
) => asserts node is NumberQuestionNode
const requireNumberQuestionNode: RequireNumberQuestionNode = node => {
  if (!isNumberQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not number question node.`, {
      node,
    })
  }
}

/**
 * 小数点桁数の制限を取得する。制限がない場合はundefinedを返す。
 *
 * @param node
 * @returns
 */
const getDecimalPlacesLimit = (
  node: NumberQuestionNode,
): number | undefined => {
  const decimalPoint = node.question.decimalPoint
  if (!decimalPoint) {
    return undefined
  }

  return decimalPoint.value
}

/**
 * 入力途中の値を確定させたNodeを返す。
 *
 * @param node
 * @returns
 */
const confirmDraftAnswer = (node: NumberQuestionNode): NumberQuestionNode => {
  if (node.draftAnswer === undefined) {
    return { ...node }
  }

  const confirmedValue = NumberDraftAnswer.toNumber(node.draftAnswer)
  // draftAnswerが数値として不完全な場合は回答を初期化して返す
  if (isNaN(confirmedValue)) {
    return { ...node, answer: undefined, draftAnswer: undefined }
  }

  // 逸脱判定処理
  const rule = node.question.rule
  const isWithinRule = rule
    ? NumberQuestion.isWithinNumberValueRule(rule, confirmedValue)
    : true

  const newAnswer = NumberAnswer.createAnswer(confirmedValue, !isWithinRule)

  return {
    ...node,
    answer: newAnswer,
    draftAnswer: undefined,
  }
}

const isInvalidAnswer = (node: NumberQuestionNode): boolean => {
  if (NumberAnswer.isNumberAnswer(node.answer)) {
    return node.answer.isInvalid
  }
  return false
}

const toUserFriendlyAnswerString = (node: NumberQuestionNode): string => {
  const answer = node.answer
  if (answer === undefined || NoAnswer.isNoAnswer(answer)) {
    return ''
  }
  const valueString = Number.isNaN(answer.value) ? '' : String(answer.value)
  return `${valueString}${node.question.scale}`
}

const _NumberQuestionNode = {
  isInvalidAnswer,
  getDecimalPlacesLimit,
  confirmDraftAnswer,
  isNumberQuestionNode,
  toUserFriendlyAnswerString,
} satisfies QuestionNodeInterface<NumberQuestionNode>

export const NumberQuestionNode: typeof _NumberQuestionNode & {
  requireNumberQuestionNode: RequireNumberQuestionNode
} = {
  ..._NumberQuestionNode,
  requireNumberQuestionNode,
}
