import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { getAppVersion } from '~/config'
import {
  useAppVersionConsistency,
  selectIsAppVersionConsistent,
} from '~/stores/appVersionConsistency/appVersionConsistency'
import { colorTextSecondary } from '~/styles/textColors'
import { useAppUpdate } from './hooks/useAppUpdate'

export function UpdateModal() {
  useAppUpdate()
  const isAppVersionConsistent = useAppVersionConsistency(
    selectIsAppVersionConsistent,
  )

  return (
    <Dialog open={!isAppVersionConsistent}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <CircularProgress size={24} />
        <Box>アップデート中です</Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <DialogContentText>
          最新バージョンにアップデートされるまでしばらくお待ちください。
        </DialogContentText>
        <DialogContentText>
          しばらく待ってもアップデートが完了しない場合、アプリの再起動をお試しください。
        </DialogContentText>
        <DialogContentText
          sx={{
            fontSize: '0.8rem',
            color: colorTextSecondary,
          }}
        >
          現在のバージョン: {getAppVersion()}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
