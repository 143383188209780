import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = {
  /**
   * オフラインデータのスキーマバージョンと、ソースコードのスキーマバージョンが一致している場合 true
   *
   */
  isVersionConsistent: boolean
}
type Actions = {
  /**
   * オフラインデータのスキーマバージョンの不整合が検知された場合にコールされる
   * @returns
   */
  inconsistentVersionDetected: () => void

  /**
   * オフラインデータのスキーマバージョンの不整合が解消された場合にコールされる
   * @returns
   */
  inconsistentVersionRestored: () => void
}

export const useOfflineReportVersionConsistency = create<State & Actions>()(
  devtools(
    immer(set => ({
      isVersionConsistent: true,
      inconsistentVersionDetected: () =>
        set(state => {
          state.isVersionConsistent = false
        }),
      inconsistentVersionRestored: () =>
        set(state => {
          state.isVersionConsistent = true
        }),
    })),
  ),
)

export const selectIsOfflineReportVersionConsistent = (state: State) =>
  state.isVersionConsistent
