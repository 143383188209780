import { createSearchParams, generatePath } from 'react-router-dom'
import { z } from 'zod'
import type { ZodSchema } from 'zod'

// ParamParseKeyの型を修正したかったためreact-router-domの型定義からPathParamと_PathParamをコピー。
type _PathParam<Path extends string> = Path extends `${infer L}/${infer R}`
  ? _PathParam<L> | _PathParam<R>
  : Path extends `:${infer Param}`
    ? Param extends `${infer Optional}?`
      ? Optional
      : Param
    : never
type PathParam<Path extends string> = Path extends '*' | '/*'
  ? '*'
  : Path extends `${infer Rest}/*`
    ? '*' | _PathParam<Rest>
    : _PathParam<Path>
type ParamParseKey<Segment extends string> = [PathParam<Segment>] extends [
  never,
]
  ? never
  : PathParam<Segment>

export type Params<PP, QP, SP> = (PP extends undefined
  ? { pathParams?: undefined }
  : { pathParams: PP }) &
  (QP extends undefined ? { queryParams?: undefined } : { queryParams: QP }) &
  (SP extends undefined ? { stateParams?: undefined } : { stateParams: SP })

type QueryParamEncodable = string | number | Date
export type QueryParamsEncodable = Record<
  string,
  QueryParamEncodable | QueryParamEncodable[]
>
export type PathParamsEncodable<T extends string> =
  ParamParseKey<T> extends never
    ? undefined
    : Record<ParamParseKey<T>, string | number>

type Config<
  T extends string,
  PP extends PathParamsEncodable<T>,
  PPS extends ZodSchema<PP> | undefined,
  QP extends QueryParamsEncodable | undefined,
  QPS extends ZodSchema<QP> | undefined,
  SP,
  SPS extends ZodSchema<SP> | undefined,
> = (QP extends undefined
  ? {
      querySchema?: undefined
    }
  : {
      querySchema: QPS
    }) &
  (PP extends undefined
    ? {
        pathParamsSchema?: undefined
      }
    : {
        pathParamsSchema: PPS
      }) &
  (SP extends undefined
    ? {
        stateParamsSchema?: undefined
      }
    : {
        stateParamsSchema: SPS
      })

const parse = <T extends ZodSchema>(schema: T, obj: unknown): z.infer<T> => {
  const result = schema.safeParse(obj)
  if (result.success) {
    return result.data
  } else {
    return undefined
  }
}

const encodePathParams = (
  pathParamsEncodable: Record<string, string | number>,
): Record<string, string> => {
  const obj: Record<string, string> = {}
  Object.entries(pathParamsEncodable).forEach(([key, value]) => {
    obj[key] = typeof value === 'number' ? value.toString() : value
  })
  return obj
}

const encodeQueryParam = (param: QueryParamEncodable): string => {
  if (typeof param === 'number') {
    return param.toString()
  } else if (param instanceof Date) {
    return param.toISOString()
  } else {
    return param
  }
}
const encodeQueryParams = (
  queryParamsEncodable: QueryParamsEncodable,
): URLSearchParams => {
  const obj: Record<string, string> = {}
  Object.entries(queryParamsEncodable).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      obj[key] = value.map(encodeQueryParam).join(',')
    } else {
      obj[key] = encodeQueryParam(value)
    }
  })
  return new URLSearchParams(obj)
}
const decodeQueryParams = <QP>(
  schema: ZodSchema<QP>,
  searchParams: URLSearchParams,
): QP => {
  const obj: Record<string, string | string[]> = {}
  for (const [key, value] of searchParams.entries()) {
    const array = value.split(',')
    if (array.length === 1) {
      obj[key] = value
    } else {
      obj[key] = array
    }
  }
  return parse(schema, obj)
}

export class Route<
  T extends string,
  PP extends PathParamsEncodable<T>,
  QP extends QueryParamsEncodable | undefined,
  SP,
> {
  path: T
  constructor(
    path: T,
    private config: Config<
      T,
      PP,
      ZodSchema<PP>,
      QP,
      ZodSchema<QP>,
      SP,
      ZodSchema<SP>
    >,
  ) {
    this.path = path
  }

  public encodeQueryParams(params: QP): URLSearchParams | undefined {
    if (params === undefined) {
      return undefined
    }
    return encodeQueryParams(params)
  }
  public decodeQueryParams(obj: URLSearchParams): QP | undefined {
    if (this.config.querySchema === undefined) {
      return undefined
    }
    return decodeQueryParams(this.config.querySchema, obj)
  }

  public encodePathParams(params: PP): Record<string, string> | undefined {
    if (params === undefined) {
      return undefined
    }
    return encodePathParams(params)
  }
  public decodePathParams(obj: unknown): PP | undefined {
    if (this.config.pathParamsSchema === undefined) {
      return undefined
    }
    return parse(this.config.pathParamsSchema, obj)
  }

  public encodeStateParams(params: SP): SP {
    if (params === undefined) {
      return params
    }
    return params
  }
  public decodeStateParams(obj: unknown): SP | undefined {
    if (this.config.stateParamsSchema === undefined) {
      return undefined
    }
    return parse(this.config.stateParamsSchema, obj)
  }

  public buildPath({ queryParams, pathParams }: Params<PP, QP, SP>): string {
    let path: string = this.path
    if (pathParams) {
      const encodedPathParams = encodePathParams(pathParams)
      path = generatePath<string>(this.path, encodedPathParams)
    }
    if (queryParams) {
      const encodedQueryParams = encodeQueryParams(queryParams)
      if (Array.from(encodedQueryParams.entries()).length > 0) {
        path += `?${createSearchParams(encodedQueryParams)}`
      }
    }
    return path
  }
}

export const queryParam = {
  number: z.coerce.number(),
  string: z.string(),
  date: z.coerce.date(),
}
export const pathParam = {
  number: z.coerce.number(),
  string: z.string(),
  date: z.coerce.date(),
}
export const stateParam = {
  number: z.number(),
  string: z.string(),
  date: z.date(),
  boolean: z.boolean(),
}
