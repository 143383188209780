import { Box, CircularProgress, Typography } from '@mui/material'

type Props = {
  progress: number
}

export function ProgressCircle({ progress }: Props) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <CircularProgress variant="determinate" value={progress} size={88} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h2"
          component="div"
          color="white"
        >{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  )
}
