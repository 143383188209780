import { isGreaterThanLength } from '~/utils/length'
import { UnhandledInReportingError } from '../../error'
import type { Answer, AnswerBase } from './answer'

const maxReasonLength = 1024

export type NoAnswer = AnswerBase<'noAnswer'> & {
  reason: string
}

const isNoAnswer = (answer: Answer | undefined): answer is NoAnswer => {
  return answer?.type === 'noAnswer'
}

type RequireNoAnswer = (
  answer: Answer | undefined,
) => asserts answer is NoAnswer
const requireNoAnswer: RequireNoAnswer = answer => {
  if (!isNoAnswer(answer)) {
    throw new UnhandledInReportingError(`The answer is not a no answer.`, {
      answer,
    })
  }
}

const createNoAnswer = (reason: string): NoAnswer => {
  return {
    type: 'noAnswer',
    reason,
    recordedAt: new Date(),
  }
}

const isGreaterThanMaxReasonLength = (reason: string): boolean => {
  return isGreaterThanLength(reason, maxReasonLength)
}

const _NoAnswer = {
  isNoAnswer,
  createNoAnswer,
  isGreaterThanMaxReasonLength,
}

export const NoAnswer: typeof _NoAnswer & {
  requireNoAnswer: RequireNoAnswer
} = {
  ..._NoAnswer,
  requireNoAnswer,
}
