import { v4 as uuidv4 } from 'uuid'
import { MultipleChoiceAnswer } from '~/domain/report/model/report/node/questionNode/multipleChoiceQuestionNode/multipleChoiceAnswer'
import type {
  ChoiceItem,
  MultipleChoiceQuestion,
} from '~/domain/report/model/report/node/questionNode/multipleChoiceQuestionNode/multipleChoiceQuestion'
import type { MultipleChoiceQuestionNode } from '~/domain/report/model/report/node/questionNode/multipleChoiceQuestionNode/multipleChoiceQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'
import type { Image } from '@ulysses-inc/harami_api_client'

export const convertMultipleChoiceQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { deviateProperty, questionNodeBase, questionBase },
  } = context

  const responseMultipleChoices =
    question.responseMultipleChoices?.[0].responses ?? []

  // APIデータのバリデーション
  responseMultipleChoices.forEach(choice => {
    // NOTE: response(テキスト)が空の選択肢はWeb画面で作成出来ないようになっている
    if (choice.response === '') {
      throw new ApiToModelError(`Multiple choice has no response.`, {
        node: context.base.node,
        choice,
      })
    }
  })

  const choiceItems: MultipleChoiceQuestion['choiceItems'] =
    responseMultipleChoices.map(choice => ({
      key: uuidv4(),
      label: choice.response,
      isInvalid: choice.isInvalid === 1,
      color: choice.color ?? '',
      isDefault: choice.isDefault === 1,
      isExcludedFromScoring: choice.isExcludedFromScoring ?? false,
      score: choice.score,
      originalMultipleChoiceId: choice.id ?? 0,
    }))

  const images =
    question.informationImages?.map(createMultipleChoiceImage) ?? []

  const questionNode: MultipleChoiceQuestionNode = {
    ...questionNodeBase,
    questionType: 'multipleChoice',
    question: {
      ...questionBase,
      ...deviateProperty,
      type: 'multipleChoice',
      choiceItems,
      images,
    },
    answer: getMultipleChoiceAnswer(context, choiceItems),
  }

  return questionNode
}
const getMultipleChoiceAnswer = (
  context: ConversionContext,
  choiceItems: ChoiceItem[],
): MultipleChoiceAnswer | undefined => {
  const {
    question,
    base: { recordedAt },
  } = context

  // 既に回答がある場合、それを返す
  const answeredResponseText = question.responseAnswer?.multipleChoice?.response
  if (answeredResponseText !== undefined) {
    // Notes:
    // 現状、回答済の選択肢が、選択肢一覧のどの選択肢かどうかを判定する方法がresponseの文字列を見るしかない
    // なぜなら、一度保存した場合idは一致しないため
    const answeredChoiceItem = choiceItems.find(
      item => item.label === answeredResponseText,
    )
    if (answeredChoiceItem === undefined) {
      throw new ApiToModelError(`Answered choice item is not found.`, {
        node: context.base.node,
        answeredResponseText,
      })
    }

    return {
      type: 'multipleChoice',
      selectedItemKey: answeredChoiceItem.key,
      recordedAt,
    }
  }

  // 回答済ではデフォルト選択肢を返さない
  if ('responseAnswer' in question) {
    return undefined
  }

  // 回答がない場合、デフォルトの選択肢があればそれを返す
  const defaultChoiceItem = choiceItems.find(item => item.isDefault)
  return defaultChoiceItem
    ? MultipleChoiceAnswer.createDefaultMultipleChoiceAnswer(
        defaultChoiceItem.key,
      )
    : undefined
}

const createMultipleChoiceImage = (
  informationImage: Image,
): MultipleChoiceQuestion['images'][0] => {
  if (!informationImage.uuid) {
    throw new ApiToModelError('Question information image has no uuid.', {
      informationImage,
    })
  }
  if (!informationImage.url) {
    throw new ApiToModelError('Question information image has no url.', {
      informationImage,
    })
  }

  return {
    type: 'uploaded',
    key: informationImage.uuid,
    recordedAt: informationImage.recordedAt ?? new Date(),
    file: {
      uuid: informationImage.uuid,
      url: informationImage.url,
    },
  }
}
