import { Image, type LocalImage } from '../../../../image/image'
import { UnhandledInReportingError } from '../../../error'
import type { SignatureAnswer } from './signatureAnswer'
import type { SignatureQuestion } from './signatureQuestion'
import type { UploadedFile } from '../../../uploadedFile/uploadedFile'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'
import type { UploadImageQuestionNodeInterface } from '../uploadImageQuestionNode/uploadImageQuestionNode'

export type SignatureQuestionNode = QuestionNodeBase<'signature'> & {
  question: SignatureQuestion
  answer: SignatureAnswer | undefined
}

const isSignatureQuestionNode = (
  node: Node | undefined,
): node is SignatureQuestionNode => {
  return node?.type === 'question' && node.questionType === 'signature'
}
type RequireSignatureQuestionNode = (
  node: Node | undefined,
) => asserts node is SignatureQuestionNode
const requireSignatureQuestionNode: RequireSignatureQuestionNode = node => {
  if (!isSignatureQuestionNode(node)) {
    throw new UnhandledInReportingError(
      `node is not signature question node.`,
      {
        node,
      },
    )
  }
}

const addLocalSignature = (
  node: SignatureQuestionNode,
  key: string,
  file: File,
  recordedAt: Date,
): SignatureQuestionNode => {
  const newAnswer: SignatureAnswer = {
    type: 'signature',
    image: {
      type: 'local',
      key,
      recordedAt,
      file,
    },
    recordedAt,
  }
  return {
    ...node,
    answer: newAnswer,
  }
}

const updateSignatureWithUploadedFile = (
  node: SignatureQuestionNode,
  key: string,
  file: UploadedFile,
): SignatureQuestionNode => {
  const existingRecordedAt = node.answer?.image.recordedAt ?? new Date()
  const newAnswer: SignatureAnswer = {
    type: 'signature',
    image: {
      type: 'uploaded',
      key,
      recordedAt: existingRecordedAt,
      file,
    },
    recordedAt: existingRecordedAt,
  }
  return {
    ...node,
    answer: newAnswer,
  }
}

const removeSignature = (
  node: SignatureQuestionNode,
): SignatureQuestionNode => {
  return {
    ...node,
    answer: undefined,
  }
}

const getLocalImagesToUpload = (node: SignatureQuestionNode): LocalImage[] => {
  if (node.answer === undefined) {
    return []
  }
  return Image.isLocalImage(node.answer.image) ? [node.answer.image] : []
}

const _SignatureQuestionNode = {
  isInvalidAnswer: () => false,
  addLocalSignature,
  updateSignatureWithUploadedFile,
  removeSignature,
  getLocalImagesToUpload,
} satisfies QuestionNodeInterface<SignatureQuestionNode> &
  UploadImageQuestionNodeInterface<SignatureQuestionNode>

export const SignatureQuestionNode: typeof _SignatureQuestionNode & {
  requireSignatureQuestionNode: typeof requireSignatureQuestionNode
} = {
  ..._SignatureQuestionNode,
  requireSignatureQuestionNode,
}
