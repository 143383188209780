import type { PropsWithChildren } from 'react'
import { Snackbar } from '../Snackbar/Snackbar'
import { UpdateModal } from '../UpdateModal/UpdateModal'

export function GlobalLayout({ children }: PropsWithChildren) {
  return (
    <>
      {children}
      <Snackbar />
      <UpdateModal />
    </>
  )
}
