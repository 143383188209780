import { ClientTypeEnum } from '@ulysses-inc/harami_api_client'
import { getAppVersion } from '~/config'

const appVersionHeaderName = 'Kmns-Harami-Report-Version'
const appVersion = getAppVersion()

export const getHeaders = () => {
  return {
    ct: `${ClientTypeEnum.MOBILE}`,
    locationHref: `${location.href}`,
    [appVersionHeaderName]: appVersion,
  }
}
