import type { NumberRule } from './numberRule'
import type { DeviateProperty, QuestionBase } from '../question'

export type NumberQuestion = QuestionBase<'number'> &
  DeviateProperty & {
    rule: NumberRule | undefined
    // 小数点以下の桁数を表す。value: number | undefined などでも表現できるが、
    // 0 と undefined が混在することを避けるため、オブジェクトで wrap するような形で定義している
    decimalPoint:
      | {
          value: number
        }
      | undefined
    scale: string
  }

const isWithinNumberValueRule = (rule: NumberRule, value: number): boolean => {
  switch (rule.type) {
    case 'between':
      return value >= rule.minimum && value <= rule.maximum
    case 'le':
      return value <= rule.maximum
    case 'ge':
      return value >= rule.minimum
  }
}

const convertNumberRuleCharacters = (
  scale: string,
  rule?: NumberRule,
): string => {
  switch (rule?.type) {
    case 'between':
      return `${rule.minimum}${scale}〜${rule.maximum}${scale}の間`
    case 'le':
      return `${rule.maximum}${scale}以下`
    case 'ge':
      return `${rule.minimum}${scale}以上`
    default:
      return 'ルールなし'
  }
}

export const NumberQuestion = {
  isWithinNumberValueRule,
  convertNumberRuleCharacters,
}
