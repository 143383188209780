import { ApiToModelError } from './error'
import { NodeSchemaConverter } from './nodeSchemaConverter'
import type { ArgPage } from './nodeSchemaConverter'
import type {
  ReportNodeSchema,
  MultipleChoiceSet,
} from '@ulysses-inc/harami_api_client'

export const createNodeSchemaConverterWithMultipleChoiceSets = (
  pages: ArgPage[],
  normalizedReportNodes: Partial<Record<number, ReportNodeSchema>>,
  multipleChoiceSets: MultipleChoiceSet[],
) => {
  const multipleChoiceSetDict =
    createInitialMultipleChoiceSetDict(multipleChoiceSets)
  return new NodeSchemaConverter(pages, normalizedReportNodes, {
    type: 'instance',
    data: multipleChoiceSetDict,
  })
}

export const createNodeSchemaConverterWithMultipleChoiceUuids = (
  pages: ArgPage[],
  normalizedReportNodes: Partial<Record<number, ReportNodeSchema>>,
  multipleChoiceSetUuids: string[],
) => {
  return new NodeSchemaConverter(pages, normalizedReportNodes, {
    type: 'reference',
    uuids: new Set(multipleChoiceSetUuids),
  })
}

const createInitialMultipleChoiceSetDict = (
  multipleChoiceSets: MultipleChoiceSet[],
) => {
  const multipleChoiceSetDict = new Map<string, MultipleChoiceSet>()
  for (const multipleChoiceSet of multipleChoiceSets) {
    if (!multipleChoiceSet.uuid || multipleChoiceSet.uuid === '') {
      throw new ApiToModelError(`Multiple choice set has no uuid.`, {
        multipleChoiceSet,
      })
    }
    // NOTE: 選択肢が1つもないセット選択肢がWeb画面で作成できてしまう
    for (const responseSet of multipleChoiceSet.responses ?? []) {
      if (!responseSet.uuid || responseSet.uuid === '') {
        throw new ApiToModelError(`Response set has no uuid.`, {
          multipleChoiceSet,
          responseSet,
        })
      }
    }
    multipleChoiceSetDict.set(multipleChoiceSet.uuid, multipleChoiceSet)
  }
  return multipleChoiceSetDict
}
