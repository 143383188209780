import { UnhandledInReportingError } from '../../../error'
import type { Answer, AnswerBase } from '../answer'

/**
 * 数値質問の回答の型
 */
export type NumberAnswer = AnswerBase<'number'> & {
  /**
   * 入力確定後の数値
   */
  value: number
  /**
   * 逸脱フラグ
   */
  isInvalid: boolean
}

const isNumberAnswer = (answer: Answer | undefined): answer is NumberAnswer => {
  return answer?.type === 'number'
}

type RequireNumberAnswer = (
  answer: Answer | undefined,
) => asserts answer is NumberAnswer
export const requireNumberAnswer: RequireNumberAnswer = answer => {
  if (!isNumberAnswer(answer)) {
    throw new UnhandledInReportingError(`The answer is not a number answer.`, {
      answer,
    })
  }
}

/**
 * 表示用の文字列に変換する
 *
 * @param answer
 */
const toDisplayStringAnswer = (
  answer: NumberAnswer | undefined,
  decimalPoint?: number,
): string => {
  const numberValue = answer?.value
  if (numberValue === undefined) {
    return ''
  }

  if (!decimalPoint) {
    return numberValue.toString()
  }

  return numberValue.toFixed(decimalPoint)
}

const createAnswer = (value: number, isInvalid: boolean): NumberAnswer => {
  return {
    recordedAt: new Date(),
    type: 'number',
    value,
    isInvalid,
  }
}

export const NumberAnswer = {
  isNumberAnswer,
  requireNumberAnswer,
  toDisplayStringAnswer,
  createAnswer,
}
