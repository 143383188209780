import { useCallback, useState } from 'react'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'

export const useOfflineModeToggle = () => {
  const [
    isDownloadConfirmationDialogOpen,
    setIsDownloadConfirmationDialogOpen,
  ] = useState(false)
  const [isOfflineModeEndedDialogOpen, setIsOfflineModeEndedDialogOpen] =
    useState(false)

  const isOfflineMode = useOfflineModeStore(state => state.offlineMode)

  const toggleOfflineMode = useCallback(() => {
    if (isOfflineMode) {
      setIsOfflineModeEndedDialogOpen(true)
    } else {
      setIsDownloadConfirmationDialogOpen(true)
    }
  }, [isOfflineMode])

  const closeDownloadConfirmationDialog = useCallback(() => {
    setIsDownloadConfirmationDialogOpen(false)
  }, [])

  const closeOfflineModeEndedDialog = useCallback(() => {
    setIsOfflineModeEndedDialogOpen(false)
  }, [])

  return {
    isDownloadConfirmationDialogOpen,
    isOfflineModeEndedDialogOpen,
    toggleOfflineMode,
    closeDownloadConfirmationDialog,
    closeOfflineModeEndedDialog,
  } as const
}
