import type { NavLinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ListItemButton,
  Typography,
  type SxProps,
  type Theme,
  type ListItemButtonProps,
} from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { colorPrimary, colorText } from '~/styles/textColors'

const styles = {
  item: {
    minHeight: '48px',
  },
  button: {
    alignItems: 'start',
  },
  icon: {
    padding: '3px 12px 0px 0px',
    color: colorPrimary,
  },
  text: {
    fontSize: '16px',
  },
} satisfies Record<string, SxProps<Theme>>

export type HamburgerMenuItemProps = {
  text: string
  icon: React.ReactNode
  selected?: boolean | false
  disabled?: boolean | false
}

function ButtonInner({
  icon,
  selected,
  text,
}: Pick<HamburgerMenuItemProps, 'text' | 'icon' | 'selected'>) {
  return (
    <>
      <ListItemIcon sx={styles.icon}>{icon}</ListItemIcon>
      <ListItemText>
        <Typography
          variant="body2"
          component="div"
          sx={(styles.text, { color: selected ? colorPrimary : colorText })}
        >
          {text}
        </Typography>
      </ListItemText>
    </>
  )
}

export function HamburgerMenuItemButton({
  text,
  icon,
  selected,
  onClick,
  disabled,
}: HamburgerMenuItemProps & Pick<ListItemButtonProps, 'sx' | 'onClick'>) {
  return (
    <ListItem disablePadding sx={styles.item}>
      <ListItemButton
        sx={styles.button}
        selected={selected}
        disabled={disabled}
        onClick={onClick}
      >
        <ButtonInner icon={icon} selected={selected} text={text} />
      </ListItemButton>
    </ListItem>
  )
}

export function HamburgerMenuItemButtonLink({
  text,
  icon,
  selected,
  to,
  disabled,
}: HamburgerMenuItemProps & Pick<NavLinkProps, 'to'>) {
  return (
    <ListItem disablePadding sx={styles.item}>
      <ListItemButton
        sx={styles.button}
        selected={selected}
        component={Link}
        to={to}
        disabled={disabled}
      >
        <ButtonInner icon={icon} selected={selected} text={text} />
      </ListItemButton>
    </ListItem>
  )
}
