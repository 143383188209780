import * as Sentry from '@sentry/browser'
import {
  logEvent as firebaseLogEvent,
  setUserProperties as setFireBaseUserProperties,
  setUserId,
} from 'firebase/analytics'
import { getAppVersion } from '~/config'
import firebaseAnalytics from '~/lib/firebaseAnalytics/analytics'

//
// NOTE:
// 当処理実装時点で、Web/Mobile 側ですでに実装されていた部分と挙動を合わせる
// BigQuery などでは field 名は、スネークケースで統一されている模様。
// （例：user_id、event_timestamp など）
// UserProperties の各フィールドも、スネークケースで統一するのが良かったと思われる。
type UserPropertiesArgs = {
  userUuid: string
  userName: string | undefined
  companyId: number | undefined
  companyName: string | undefined
}

/**
 * Firebase Analytics のための userId、properties を設定する
 *
 * @param userProperties
 * @returns
 */
export const setUserProperties = ({
  userUuid,
  userName,
  companyId,
  companyName,
}: UserPropertiesArgs) => {
  if (!firebaseAnalytics) return
  try {
    // Firebase Analytics の処理が失敗した場合であっても、
    // エンドユーザーの操作に影響を与えるのは適当ではないため、すべてのエラーをキャッチする
    setUserId(firebaseAnalytics, userUuid)
    setFireBaseUserProperties(
      firebaseAnalytics,

      // 当処理実装時点で、Web/Mobile 側ですでに実装されていた部分と挙動を合わせ、すべて文字列に変換する
      {
        app: 'harami_report',
        appVersion: getAppVersion(),
        userId: userUuid,
        userName: userName || '',
        companyId: (companyId ?? 0).toString(),
        companyName: companyName || '',
      },
    )
  } catch (e) {
    Sentry.captureException(e)
  }
}

/**
 * Firebase Analytics にイベントを送信する。
 * Firebase Analytics の logEvent を Wrap したものであり、同処理で内部的なエラーが発生した場合でも処理を続行する。
 *
 * @param eventName
 * @param eventParams
 */
export const logEvent = (
  eventName: string,
  eventParams: Record<string, unknown>,
) => {
  if (!firebaseAnalytics) return
  try {
    firebaseLogEvent(firebaseAnalytics, eventName, eventParams)
  } catch (e) {
    Sentry.captureException(e)
  }
}
