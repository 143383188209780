import type { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { PageHeader } from './PageHeader'
import {
  PageMainCenter,
  PageMainDefault,
  PageMainFull,
} from './PageMainContent'
import type { Props as DefaultHeaderProps } from '../Header/Header'

export type Props = {
  header: DefaultHeaderProps | { component: React.ReactNode }
  mainLayoutType?: 'default' | 'center' | 'full'
  // mainLayoutType が default の場合のみ表示できる
  bgWhiteContent?: React.ReactNode
}

const styles = {
  page: {
    minHeight: '100dvh',
    display: 'flex',
    flexFlow: 'column',
  },
}

export function PageLayout({
  children,
  header,
  mainLayoutType = 'default',
  bgWhiteContent,
}: PropsWithChildren<Props>) {
  return (
    <Box sx={styles.page}>
      <PageHeader {...header} />
      {mainLayoutType === 'default' && (
        <PageMainDefault bgWhiteContent={bgWhiteContent}>
          {children}
        </PageMainDefault>
      )}
      {mainLayoutType === 'center' && (
        <PageMainCenter>{children}</PageMainCenter>
      )}
      {mainLayoutType === 'full' && <PageMainFull>{children}</PageMainFull>}
    </Box>
  )
}
