import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import {
  useUserPlaceNodeStore,
  selectIsPlaceNodeSelected,
} from '~/stores/place/place'
import { routes } from './paths'

type Props = {
  children: ReactNode
}

export function HomePageRoute({ children }: Props) {
  const isPlaceNodeSelected = useUserPlaceNodeStore(selectIsPlaceNodeSelected)

  if (!isPlaceNodeSelected) {
    return <Navigate to={routes.places.buildPath({})} />
  }

  return children
}
