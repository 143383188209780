import { UnhandledInReportingError } from '../../error'
import { DateTimeQuestionNode } from './dateTimeQuestionNode/dateTimeQuestionNode'
import { EmployeeQuestionNode } from './employeeQuestionNode/employeeQuestionNode'
import { FormulaQuestionNode } from './formulaQuestionNode/formulaQuestionNode'
import { ImageQuestionNode } from './imageQuestionNode/imageQuestionNode'
import { InformationDocumentQuestionNode } from './informationDocumentQuestionNode/informationDocumentQuestionNode'
import { InformationUrlQuestionNode } from './informationUrlQuestionNode/informationUrlQuestionNode'
import { MultipleChoiceQuestionNode } from './multipleChoiceQuestionNode/multipleChoiceQuestionNode'
import { MultipleChoiceSetQuestionNode } from './multipleChoiceSetQuestionNode/multipleChoiceSetQuestionNode'
import { NoAnswer } from './noAnswer'
import { NumberQuestionNode } from './numberQuestionNode/numberQuestionNode'
import { SignatureQuestionNode } from './signatureQuestionNode/signatureQuestionNode'
import { TextQuestionNode } from './textQuestionNode/textQuestionNode'
import type { Memo } from './memo'
import type { QuestionType } from './question'
import type { NodeBase, Node } from '../node'

export type QuestionNodeBase<T extends QuestionType> = NodeBase<'question'> & {
  memo: Memo
  questionType: T
}

export type QuestionNode =
  | NumberQuestionNode
  | FormulaQuestionNode
  | ImageQuestionNode
  | MultipleChoiceQuestionNode
  | MultipleChoiceSetQuestionNode
  | SignatureQuestionNode
  | DateTimeQuestionNode
  | TextQuestionNode
  | InformationDocumentQuestionNode
  | InformationUrlQuestionNode
  | EmployeeQuestionNode

export type QuestionNodeByType<
  T extends QuestionType,
  K extends QuestionNode = QuestionNode,
> = K extends {
  questionType: T
}
  ? K
  : never

type RequiredQuestionNodeInterface<T extends QuestionNode> = {
  isInvalidAnswer: (node: T) => boolean
}
export type QuestionNodeInterface<T extends QuestionNode> =
  RequiredQuestionNodeInterface<T> &
    Omit<
      Record<string, (...args: never[]) => unknown>,
      keyof RequiredQuestionNodeInterface<T>
    >
type NodeFunctions<R> = {
  [K in QuestionType]: (node: QuestionNodeByType<K>) => R
}

const getFunctions = <K extends keyof RequiredQuestionNodeInterface<never>>(
  key: K,
) => {
  return {
    number: NumberQuestionNode[key],
    multipleChoice: MultipleChoiceQuestionNode[key],
    formula: FormulaQuestionNode[key],
    resultImage: ImageQuestionNode[key],
    responseSet: MultipleChoiceSetQuestionNode[key],
    signature: SignatureQuestionNode[key],
    dateTime: DateTimeQuestionNode[key],
    text: TextQuestionNode[key],
    informationDocument: InformationDocumentQuestionNode[key],
    informationUrl: InformationUrlQuestionNode[key],
    employee: EmployeeQuestionNode[key],
  }
}

// questionNode の型に応じた処理を switch を使わずにうまく書く方法がないため、linter を抑止している
// 処理を煩雑にしないようにするため、この関数の責務は type に応じて呼び出す関数などを switch することだけに限定すること
// eslint-disable-next-line complexity
const execute = <R>(node: QuestionNode, funcs: NodeFunctions<R>): R => {
  switch (node.questionType) {
    case 'number':
      return funcs.number(node)
    case 'multipleChoice':
      return funcs.multipleChoice(node)
    case 'formula':
      return funcs.formula(node)
    case 'resultImage':
      return funcs.resultImage(node)
    case 'responseSet':
      return funcs.responseSet(node)
    case 'signature':
      return funcs.signature(node)
    case 'dateTime':
      return funcs.dateTime(node)
    case 'text':
      return funcs.text(node)
    case 'informationDocument':
      return funcs.informationDocument(node)
    case 'informationUrl':
      return funcs.informationUrl(node)
    case 'employee':
      return funcs.employee(node)
  }
}

const isQuestionNode = (node: Node | undefined): node is QuestionNode => {
  return node?.type === 'question'
}
type RequireQuestionNode = (
  node: Node | undefined,
) => asserts node is QuestionNode
const requireQuestionNode: RequireQuestionNode = node => {
  if (!isQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not question node.`, {
      node,
    })
  }
}

/**
 * 必須質問が未回答の場合に true を返す
 * @param node
 * @returns
 */
const isQuestionUnfilledRequired = (node: QuestionNode): boolean => {
  if (NoAnswer.isNoAnswer(node.answer)) {
    return node.answer.reason === ''
  }
  const isRequired = node.question.isRequired
  const isUnfilled = node.answer === undefined
  return isUnfilled && isRequired
}

const isQuestionAnswered = (node: QuestionNode): boolean => {
  return node.answer !== undefined
}

const resetAnswer = (questionNode: QuestionNode) => {
  questionNode.answer = undefined
}

const isInvalidAnswer = (node: QuestionNode): boolean => {
  return execute(node, getFunctions('isInvalidAnswer'))
}

const hasNonDefaultAnswer = (node: QuestionNode): boolean => {
  if (node.questionType === 'multipleChoice') {
    return MultipleChoiceQuestionNode.hasNonDefaultAnswer(node)
  }

  return node.answer !== undefined
}

const _QuestionNode = {
  isQuestionNode,
  isQuestionUnfilledRequired,
  isQuestionAnswered,
  resetAnswer,
  isInvalidAnswer,
  hasNonDefaultAnswer,
}

export const QuestionNode: typeof _QuestionNode & {
  requireQuestionNode: RequireQuestionNode
} = {
  ..._QuestionNode,
  requireQuestionNode,
}
