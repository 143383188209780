import { v4 as uuidv4 } from 'uuid'
import { Image } from '../../../../image/image'
import { UnhandledInReportingError } from '../../../error'

import type { ImageAnswer } from './imageAnswer'
import type { ImageQuestion } from './imageQuestion'
import type { LocalImage, UploadedImage } from '../../../../image/image'
import type { UploadedFile } from '../../../uploadedFile/uploadedFile'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'
import type { UploadImageQuestionNodeInterface } from '../uploadImageQuestionNode/uploadImageQuestionNode'

export type ImageQuestionNode = QuestionNodeBase<'resultImage'> & {
  question: ImageQuestion
  answer: ImageAnswer | undefined
}

const isImageQuestionNode = (
  node: Node | undefined,
): node is ImageQuestionNode => {
  return node?.type === 'question' && node.questionType === 'resultImage'
}
type RequireImageQuestionNode = (
  node: Node | undefined,
) => asserts node is ImageQuestionNode
const requireImageQuestionNode: RequireImageQuestionNode = node => {
  if (!isImageQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not image question node.`, {
      node,
    })
  }
}

const addUploadedImage = (
  node: ImageQuestionNode,
  file: UploadedFile,
  recordedAt: Date,
): ImageQuestionNode => {
  const image: Image = {
    type: 'uploaded',
    recordedAt,
    key: uuidv4(),
    file,
  }
  if (node.answer === undefined) {
    return {
      ...node,
      answer: {
        type: 'resultImage',
        recordedAt: image.recordedAt,
        images: [image],
      },
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      recordedAt: image.recordedAt,
      images: [...node.answer.images, image],
    },
  }
}

const addLocalImage = (
  node: ImageQuestionNode,
  key: string,
  file: File,
  recordedAt: Date,
): ImageQuestionNode => {
  const image: Image = {
    type: 'local',
    key,
    recordedAt,
    file,
  }
  if (node.answer === undefined) {
    return {
      ...node,
      answer: {
        type: 'resultImage',
        recordedAt: image.recordedAt,
        images: [image],
      },
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      recordedAt: image.recordedAt,
      images: [...node.answer.images, image],
    },
  }
}

const updateImageWithLocalFile = (
  node: ImageQuestionNode,
  key: string,
  file: File,
  recordedAt: Date,
): ImageQuestionNode => {
  if (node.answer === undefined) {
    throw new UnhandledInReportingError('answer is undefined', {
      node,
    })
  }

  const newImages = node.answer.images.map(image => {
    if (image.key === key) {
      const newImage: LocalImage = {
        type: 'local',
        key: image.key,
        recordedAt,
        file,
      }
      return newImage
    }
    return image
  })

  return {
    ...node,
    answer: {
      ...node.answer,
      images: newImages,
    },
  }
}

const updateImageWithUploadedFile = (
  node: ImageQuestionNode,
  key: string,
  file: UploadedFile,
): ImageQuestionNode => {
  if (node.answer === undefined) {
    throw new UnhandledInReportingError('answer is undefined', {
      node,
    })
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      images: node.answer.images.map(image => {
        if (image.key === key) {
          const updatedImage: UploadedImage = {
            type: 'uploaded',
            key: image.key,
            recordedAt: image.recordedAt,
            file,
          }
          return updatedImage
        } else {
          return image
        }
      }),
    },
  }
}

const removeImage = (
  node: ImageQuestionNode,
  key: string,
): ImageQuestionNode => {
  if (node.answer === undefined) {
    return node
  }
  const images = node.answer.images.filter(image => image.key !== key)
  if (images.length === 0) {
    return {
      ...node,
      answer: undefined,
    }
  }
  return {
    ...node,
    answer: {
      ...node.answer,
      images,
    },
  }
}

const getLocalImagesToUpload = (node: ImageQuestionNode): LocalImage[] => {
  if (node.answer === undefined) {
    return []
  }
  return node.answer.images.filter(Image.isLocalImage)
}

const _ImageQuestionNode = {
  isInvalidAnswer: () => false,
  addUploadedImage,
  addLocalImage,
  updateImageWithLocalFile,
  updateImageWithUploadedFile,
  removeImage,
  isImageQuestionNode,
  getLocalImagesToUpload,
} satisfies QuestionNodeInterface<ImageQuestionNode> &
  UploadImageQuestionNodeInterface<ImageQuestionNode>

export const ImageQuestionNode: typeof _ImageQuestionNode & {
  requireImageQuestionNode: typeof requireImageQuestionNode
} = {
  ..._ImageQuestionNode,
  requireImageQuestionNode,
}
