import { UnhandledInReportingError } from '../../../error'
import type { Answer, AnswerBase } from '../answer'

/**
 * テキスト質問の回答の型
 */
export type TextAnswer = AnswerBase<'text'> & {
  /**
   * 入力確定後のテキスト
   */
  value: string
}

const isTextAnswer = (answer: Answer | undefined): answer is TextAnswer => {
  return answer?.type === 'text'
}

type RequireTextAnswer = (
  answer: Answer | undefined,
) => asserts answer is TextAnswer
export const requireTextAnswer: RequireTextAnswer = answer => {
  if (!isTextAnswer(answer)) {
    throw new UnhandledInReportingError(`The answer is not a text answer.`, {
      answer,
    })
  }
}

const createAnswer = (value: string): TextAnswer => {
  return {
    recordedAt: new Date(),
    type: 'text',
    value,
  }
}

export const TextAnswer = {
  isTextAnswer,
  requireTextAnswer,
  createAnswer,
}
