import { EmployeeQuestionNode } from '../../model/report/node/questionNode/employeeQuestionNode/employeeQuestionNode'
import { EmployeeCheckSectionNode } from '../../model/report/node/sectionNode/repeatableSectionNode/employeeCheckSectionNode/employeeCheckSectionNode'
import { ApiToModelError } from './error'
import type { NodeDict } from '../../model/report/node/node'

/**
 * 従業員チェックセクション直下には、従業員質問が存在することを確認する
 *
 * @param nodes
 */
export const validateEmployeeCheckSections = (nodes: NodeDict) => {
  Object.values(nodes).forEach(node => {
    if (EmployeeCheckSectionNode.isEmployeeCheckSectionNode(node)) {
      const firstChildNode = nodes[node.nodes[0] ?? 0]
      if (!EmployeeQuestionNode.isEmployeeQuestionNode(firstChildNode)) {
        throw new ApiToModelError(
          'The first child of employee check sections must be an employee question.',
          { node, firstChildNode },
        )
      }
    }
  })
}
