import { UnhandledInReportingError } from '../../../error'
import { TextAnswer } from './textAnswer'
import type { TextQuestion } from './textQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type TextQuestionNode = QuestionNodeBase<'text'> & {
  question: TextQuestion
  answer: TextAnswer | undefined
}

const isTextQuestionNode = (
  node: Node | undefined,
): node is TextQuestionNode => {
  return node?.type === 'question' && node.questionType === 'text'
}
type RequireTextQuestionNode = (
  node: Node | undefined,
) => asserts node is TextQuestionNode
const requireTextQuestionNode: RequireTextQuestionNode = node => {
  if (!isTextQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not text question node.`, {
      node,
    })
  }
}

/**
 * 値を入力
 *
 * @param node
 * @returns
 */
const confirmAnswer = (
  node: TextQuestionNode,
  value: string,
): TextQuestionNode => {
  // 空文字は required の validation のために undefined に変換する
  const newAnswer = value === '' ? undefined : TextAnswer.createAnswer(value)
  return {
    ...node,
    answer: newAnswer,
  }
}

const _TextQuestionNode = {
  isInvalidAnswer: () => false,
  confirmAnswer,
  isTextQuestionNode,
} satisfies QuestionNodeInterface<TextQuestionNode>

export const TextQuestionNode: typeof _TextQuestionNode & {
  requireTextQuestionNode: RequireTextQuestionNode
} = {
  ..._TextQuestionNode,
  requireTextQuestionNode,
}
