import { Box, Typography } from '@mui/material'
import OfflineIcon from '~/assets/icons/offline.svg'
import { gray } from '~/styles/kdsColors'

export const bannerHeight = 24
// 「オフラインモード」という概念が、harami 固有のものかどうか微妙であるが、
// オフラインかどうかの判断を表示するようなコンポーネントは、一般的なものと考え、この層に定義している
export function OfflineModeBanner() {
  return (
    <Box
      sx={{
        backgroundColor: gray[8],
        height: bannerHeight,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          height: bannerHeight,
          lineHeight: 1.5,
        }}
      >
        <Box
          component="img"
          src={OfflineIcon}
          sx={{
            height: 16,
          }}
        />
      </Box>
      <Typography
        component="div"
        sx={{
          color: 'white',
          fontWeight: 700,
          fontSize: 14,
        }}
      >
        オフラインモード
      </Typography>
    </Box>
  )
}
