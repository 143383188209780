import { UnhandledInReportingError } from '~/domain/report/model/report/error'
import { deepCopy } from '~/utils/deepCopy'
import { Node } from '../../../node'
import { SectionNode } from '../../sectionNode'
import { MasterSectionNode } from '../masterSectionNode/masterSectionNode'
import { RepeatableSectionNode } from '../repeatableSectionNode'
import type { Report } from '../../../../report'
import type { NodeId } from '../../../node'
import type { SectionNodeBase } from '../../sectionNode'
import type { RepeatableInstanceEmployeeSectionNode } from '../employeeCheckSectionNode/employeeCheckSectionNode'

/**
 * 繰り返しセクションのインスタンス
 */
export type RepeatableInstanceSectionNode = SectionNodeBase<
  'repeatableSectionInstance',
  {
    masterNodeId: NodeId
  }
>

export type InstanceSectionNode =
  | RepeatableInstanceSectionNode
  | RepeatableInstanceEmployeeSectionNode

const isInstanceSectionNode = (
  node: Node | undefined,
): node is InstanceSectionNode => {
  if (!SectionNode.isSectionNode(node)) {
    return false
  }

  return (
    node.section.sectionType === 'repeatableSectionInstance' ||
    node.section.sectionType === 'repeatableEmployeeSectionInstance'
  )
}
type RequireInstanceSectionNode = (
  node: Node | undefined,
) => asserts node is InstanceSectionNode
const requireInstanceSectionNode: RequireInstanceSectionNode = node => {
  if (node === undefined || !InstanceSectionNode.isInstanceSectionNode(node)) {
    throw new UnhandledInReportingError('node is not instance section node.', {
      node,
    })
  }
}

const addSuffixToName = (
  instanceNode: InstanceSectionNode,
  report: Report,
): InstanceSectionNode => {
  const parentNode = Node.getParentNode(
    instanceNode.section.masterNodeId,
    report.nodePathInfos.idPaths,
    report.nodes,
  )

  const masterNode = report.nodes[instanceNode.section.masterNodeId]
  MasterSectionNode.requireMasterSectionNode(masterNode)

  // Note: 👇いちいち探索し数を調べるのが冗長なのでなんとかしたい気持ち
  const existingInstanceCount = RepeatableSectionNode.countInstanceNodes(
    report.nodes,
    parentNode.nodes,
    masterNode,
  )

  const newInstanceNode = deepCopy(instanceNode)
  newInstanceNode.section.name = masterNode.section.name
    ? `${masterNode.section.name} (${existingInstanceCount + 1})`
    : `(${existingInstanceCount + 1})`

  return newInstanceNode
}

const _InstanceSectionNode = {
  isInstanceSectionNode,
  addSuffixToName,
}

export const InstanceSectionNode: typeof _InstanceSectionNode & {
  requireInstanceSectionNode: RequireInstanceSectionNode
} = {
  ..._InstanceSectionNode,
  requireInstanceSectionNode,
}
