import {
  ResponseEmployeeSubTypeEnum,
  type ReportNodeSchema,
  type ResponseEmployee,
} from '@ulysses-inc/harami_api_client'
import type { EmployeeAnswer } from '~/domain/report/model/report/node/questionNode/employeeQuestionNode/employeeAnswer'
import type { InputMethod } from '~/domain/report/model/report/node/questionNode/employeeQuestionNode/employeeQuestion'
import type { EmployeeQuestionNode } from '~/domain/report/model/report/node/questionNode/employeeQuestionNode/employeeQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'

export const convertEmployeeQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    base: { node, questionNodeBase, questionBase, recordedAt },
  } = context

  const responseEmployee = extractResponseEmployee(node)

  const employees = convertToEmployees(node)

  const questionNode: EmployeeQuestionNode = {
    ...questionNodeBase,
    questionType: 'employee',

    question: {
      ...questionBase,
      type: 'employee',
      inputMethod: convertToInputMethod(node),
      isMultiple: responseEmployee.isMultiple === 1,
    },
    answer: !employees
      ? undefined
      : { type: 'employee', employees, recordedAt },
  }

  return questionNode
}

const extractResponseEmployee = (node: ReportNodeSchema): ResponseEmployee => {
  const responseEmployee = node.question?.responseEmployees?.[0]
  if (!responseEmployee) {
    throw new ApiToModelError('ResponseEmployee is required', { node })
  }
  return responseEmployee
}

const convertToInputMethod = (node: ReportNodeSchema): InputMethod => {
  const responseEmployee = extractResponseEmployee(node)
  switch (responseEmployee.subType) {
    case ResponseEmployeeSubTypeEnum.NAME:
      return 'name'
    case ResponseEmployeeSubTypeEnum.CODE:
      return 'code'
    default:
      throw new ApiToModelError('Invalid ResponseEmployeeSubType', {
        responseEmployee,
      })
  }
}

const convertToEmployees = (
  node: ReportNodeSchema,
): EmployeeAnswer['employees'] | undefined => {
  const answerEmployees = node.question?.responseAnswer?.employees ?? []
  if (answerEmployees.length === 0) {
    return undefined
  }
  return answerEmployees.map(answerEmployee => {
    return {
      name: answerEmployee.name,
      code: answerEmployee.employeeCode,
      kana: answerEmployee.nameKana ?? '',
      placeNodes: answerEmployee.placeNodes ?? [],
    }
  })
}
