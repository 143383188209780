import { Employee } from '../../../employee/employee'
import { UnhandledInReportingError } from '../../../error'
import { EmployeeAnswer } from './employeeAnswer'
import type { EmployeeQuestion } from './employeeQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type EmployeeQuestionNode = QuestionNodeBase<'employee'> & {
  question: EmployeeQuestion
  answer: EmployeeAnswer | undefined
}

const isEmployeeQuestionNode = (
  node: Node | undefined,
): node is EmployeeQuestionNode => {
  return node?.type === 'question' && node.questionType === 'employee'
}

type RequireEmployeeQuestionNode = (
  node: Node | undefined,
) => asserts node is EmployeeQuestionNode
const requireEmployeeQuestionNode: RequireEmployeeQuestionNode = node => {
  if (!isEmployeeQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not employee question node.`, {
      node,
    })
  }
}

const resetAnswer = (
  node: EmployeeQuestionNode,
  baseEmployees: Employee[],
  codes: string[],
): EmployeeQuestionNode => {
  const newAnswer = EmployeeAnswer.create(
    Employee.extractEmployeesByCodes(baseEmployees, codes),
  )
  const newNode: EmployeeQuestionNode = {
    ...node,
    answer: newAnswer,
  }

  return newNode
}

const _EmployeeQuestionNode = {
  isInvalidAnswer: () => false,
  isEmployeeQuestionNode,
  resetAnswer,
} satisfies QuestionNodeInterface<EmployeeQuestionNode>

export const EmployeeQuestionNode: typeof _EmployeeQuestionNode & {
  requireEmployeeQuestionNode: RequireEmployeeQuestionNode
} = {
  ..._EmployeeQuestionNode,
  requireEmployeeQuestionNode,
}
