import type { ReportNodeSchemaLatest } from '~/adapter/indexedDB/types'
import type { MultipleChoiceQuestionNode } from '~/domain/report/model/report/node/questionNode/multipleChoiceQuestionNode/multipleChoiceQuestionNode'
import { convertReadonlyImage } from '../image/convertImage'
import { convertMemo } from './convertMemo'

type MultipleChoiceQuestionNodeSchema = Extract<
  ReportNodeSchemaLatest['data']['nodes'][0],
  { type: 'question'; questionType: 'multipleChoice' }
>
type ImageSchema = MultipleChoiceQuestionNodeSchema['question']['images'][0]

export const convertMultipleChoiceQuestionNode = async (
  node: MultipleChoiceQuestionNode,
): Promise<MultipleChoiceQuestionNodeSchema> => {
  const images: ImageSchema[] = []
  for (const image of node.question.images) {
    images.push(await convertReadonlyImage(image))
  }
  return {
    ...node,
    question: {
      ...node.question,
      images,
    },
    memo: await convertMemo(node.memo),
  }
}
