import { UnhandledInReportingError } from '~/domain/report/model/report/error'
import { EmployeeQuestionNode } from '../../../questionNode/employeeQuestionNode/employeeQuestionNode'
import { SectionNode } from '../../sectionNode'
import type { Node, NodeDict, NodeId } from '../../../node'
import type { SectionNodeBase } from '../../sectionNode'

/**
 * 繰り返しセクション&従業員チェックのマスター
 */
export type RepeatableMasterEmployeeSectionNode =
  SectionNodeBase<'repeatableEmployeeSectionMaster'>

/**
 * 繰り返しセクション&従業員チェックのインスタンス
 */
export type RepeatableInstanceEmployeeSectionNode = SectionNodeBase<
  'repeatableEmployeeSectionInstance',
  {
    masterNodeId: NodeId
  }
>

export type EmployeeCheckSectionNode =
  | RepeatableMasterEmployeeSectionNode
  | RepeatableInstanceEmployeeSectionNode

const isEmployeeCheckMasterSectionNode = (
  node: Node | undefined,
): node is RepeatableMasterEmployeeSectionNode => {
  if (!SectionNode.isSectionNode(node)) {
    return false
  }

  return node.section.sectionType === 'repeatableEmployeeSectionMaster'
}

const isEmployeeCheckInstanceSectionNode = (
  node: Node | undefined,
): node is RepeatableInstanceEmployeeSectionNode => {
  if (!SectionNode.isSectionNode(node)) {
    return false
  }

  return node.section.sectionType === 'repeatableEmployeeSectionInstance'
}

const isEmployeeCheckSectionNode = (
  node: Node | undefined,
): node is EmployeeCheckSectionNode => {
  return (
    isEmployeeCheckMasterSectionNode(node) ||
    isEmployeeCheckInstanceSectionNode(node)
  )
}

type RequireEmployeeCheckMasterSectionNode = (
  node: Node | undefined,
) => asserts node is RepeatableMasterEmployeeSectionNode
const requireEmployeeCheckMasterSectionNode: RequireEmployeeCheckMasterSectionNode =
  node => {
    if (node === undefined || !isEmployeeCheckMasterSectionNode(node)) {
      throw new UnhandledInReportingError(
        'node is not employee check master section node',
        {
          node,
        },
      )
    }
  }
type RequireEmployeeCheckInstanceSectionNode = (
  node: Node | undefined,
) => asserts node is RepeatableInstanceEmployeeSectionNode
const requireEmployeeCheckInstanceSectionNode: RequireEmployeeCheckInstanceSectionNode =
  node => {
    if (node === undefined || !isEmployeeCheckInstanceSectionNode(node)) {
      throw new UnhandledInReportingError(
        'node is not employee check instance section node',
        {
          node,
        },
      )
    }
  }

/**
 * 指定された従業員チェックセクションノード背後にある、従業員質問ノードを取得する
 *
 * @param node
 * @param nodeDict
 * @returns
 */
const getUnderlyingEmployeeQuestionNode = (
  node: EmployeeCheckSectionNode,
  nodeDict: NodeDict,
): EmployeeQuestionNode => {
  const employeeQuestionNodeId = node.nodes[0]
  const employeeQuestionNode = nodeDict[employeeQuestionNodeId]
  EmployeeQuestionNode.requireEmployeeQuestionNode(employeeQuestionNode)
  return employeeQuestionNode
}

const _EmployeeCheckSectionNode = {
  isEmployeeCheckSectionNode,
  isEmployeeCheckMasterSectionNode,
  isEmployeeCheckInstanceSectionNode,
  getUnderlyingEmployeeQuestionNode,
}

export const EmployeeCheckSectionNode: typeof _EmployeeCheckSectionNode & {
  requireEmployeeCheckMasterSectionNode: RequireEmployeeCheckMasterSectionNode
  requireEmployeeCheckInstanceSectionNode: RequireEmployeeCheckInstanceSectionNode
} = {
  ..._EmployeeCheckSectionNode,
  requireEmployeeCheckMasterSectionNode,
  requireEmployeeCheckInstanceSectionNode,
}
