import { useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import {
  FeatureFlagsApi,
  ScheduleListItemsApi,
  ToReportApi,
} from '@ulysses-inc/harami_api_client'
import { v4 as uuidv4 } from 'uuid'
import {
  getRequestError,
  isAppVersionInconsistentError,
} from '~/adapter/api/error'
import type { GetApiType } from '~/adapter/api/useAuthApi'
import { useAuthApi } from '~/adapter/api/useAuthApi'
import { type DBType } from '~/adapter/indexedDB/db'
import { useGetDB } from '~/hooks/db/useGetDB'
import { useAppVersionConsistency } from '~/stores/appVersionConsistency/appVersionConsistency'
import {
  useUserPlaceNodeStore,
  selectSelectedPlaceNodeUUID,
} from '~/stores/place/place'
import {
  selectUserCompanyId,
  selectUserUuid,
  useSessionStore,
} from '~/stores/session/session'
import { ErrorWithExtra } from '~/utils/error'
import { TemplatesDownloadTask } from './templateDownloadTask'

type ProgressListener = ({ progress }: { progress: number }) => void

type WarningCause = 'hasMoreEmployees' | 'unknown'

type DownloadOfflineResourcesResult =
  | {
      type: 'success'
    }
  | {
      type: 'warn'
      cause: WarningCause
    }
  | {
      type: 'error'
      error: Error
      shouldFeedback: boolean
    }

const wasAppVersionInConsistentErrorThrown = async (e: unknown) => {
  const error = await getRequestError(e)
  return isAppVersionInconsistentError(error)
}

export const useDownloadOfflineResources = () => {
  const { getApi } = useAuthApi()

  const { getDBWithThrow } = useGetDB()

  const userUuid = useSessionStore(selectUserUuid)
  const selectedPlaceNodeUuid = useUserPlaceNodeStore(
    selectSelectedPlaceNodeUUID,
  )

  const companyId = useSessionStore(selectUserCompanyId)

  const inconsistentVersionDetected = useAppVersionConsistency(
    state => state.inconsistentVersionDetected,
  )

  /**
   * [リトライについて]
   * 一度の API コール（副作用のある処理）のみで完結する処理は、処理の初めの方で実行し、かつ、リトライを行わない。
   * 早くエラーを通知し、ユーザーに場所などの環境自体を変えてもらった方が得策と考えられるため。
   * 一方で、複数回の API コール（副作用のある処理）が必要なものは、リトライを行う。
   * ひな形の内容はページネーションにより取得しており、１処理の失敗をすぐさま処理全体の失敗と判断してしまうと、
   * 処理の成功確率が著しく下がる可能性があるため。
   *
   * [エラーハンドリングについて]
   * この関数から直接呼び出す関数については、エラーは throw で伝播するようにしている。下手に Result や Promise<T | Error> にこだわると
   * かえって煩雑になってしまうと考えられたため。
   *
   */
  const download = useCallback(
    async (
      progressListener: ProgressListener,
    ): Promise<DownloadOfflineResourcesResult> => {
      // 現場選択されていない状態でレンダリングされうるコンポーネントからも使用されるため、
      // このタイミングでのチェックが必要
      if (!selectedPlaceNodeUuid) {
        throw new Error('selectedPlaceNodeUuid is not found')
      }
      let db: DBType | undefined = undefined
      try {
        db = await getDBWithThrow()

        await startDownloadOfflineResources(
          selectedPlaceNodeUuid,
          companyId,
          db,
        )

        await downloadFeatureFlags(
          userUuid,
          selectedPlaceNodeUuid,
          companyId,
          db,
          getApi,
        )

        await downloadScheduleItems(
          selectedPlaceNodeUuid,
          companyId,
          db,
          getApi,
        )
        const hasMoreEmployees = await downloadEmployees(
          selectedPlaceNodeUuid,
          companyId,
          db,
          getApi,
        )

        // このタイミングまで終了したら、10 % 終わっているとみなす
        progressListener({
          progress: 0.1,
        })

        await downloadTemplates(
          selectedPlaceNodeUuid,
          companyId,
          db,
          getApi,
          progressListener,
        )

        if (hasMoreEmployees) {
          return {
            type: 'warn',
            cause: 'hasMoreEmployees',
          }
        }

        return {
          type: 'success',
        }
      } catch (e) {
        let errorInDeletion: unknown = undefined
        try {
          if (db) {
            await deleteDownloadedOfflineResources(
              selectedPlaceNodeUuid,
              companyId,
              db,
            )
          }
        } catch (nestedError) {
          errorInDeletion = nestedError
        }
        Sentry.captureException(
          new ErrorWithExtra(
            'OfflineResourceDownloadError',
            'Failed to download offline resources',
            {
              placeNodeUuid: selectedPlaceNodeUuid,
              originalError: e,
              errorsInDeletion: errorInDeletion,
            },
          ),
        )
        let shouldFeedback = true
        if (await wasAppVersionInConsistentErrorThrown(e)) {
          shouldFeedback = false
          inconsistentVersionDetected()
        }
        return {
          type: 'error',
          error: e instanceof Error ? e : new Error(String(e)),
          shouldFeedback,
        }
      }
    },
    [
      companyId,
      getApi,
      getDBWithThrow,
      inconsistentVersionDetected,
      selectedPlaceNodeUuid,
      userUuid,
    ],
  )

  const endOfflineMode = useCallback(async () => {
    try {
      const db = await getDBWithThrow()
      await deleteDownloadedOfflineResources(
        selectedPlaceNodeUuid,
        companyId,
        db,
      )
    } catch (e) {
      Sentry.captureException(
        new ErrorWithExtra(
          'EndOfflineModeError',
          'Failed to delete offline resources',
          {
            placeNodeUuid: selectedPlaceNodeUuid,
            originalError: e,
          },
        ),
      )
    }
  }, [companyId, getDBWithThrow, selectedPlaceNodeUuid])

  return {
    download,
    endOfflineMode,
  } as const
}

/**
 * オフラインデータダウンロード処理の開始 💪
 * 選択現場に紐づく一連のダウンロード済データを削除した上で、ダウンロード日時などを記録する
 *
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 */
const startDownloadOfflineResources = async (
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
) => {
  await deleteDownloadedOfflineResources(selectedPlaceNodeUuid, companyId, db)
  await db.transaction('rw', [db.offlineDownloadInfos], async tx => {
    await tx.offlineDownloadInfos.put({
      placeNodeId: selectedPlaceNodeUuid,
      companyId,
      downloadedAt: new Date(),
    })
  })
}

/**
 * Feature Flags のダウンロード
 *
 * Feature Flags のダウンロードに失敗した場合でも、処理は続行する。
 * オフラインモード下では、すべての Feature Flags が OFF になっているのと同じ扱いとなる
 *
 * @param userUuid
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 * @param getApi
 */
const downloadFeatureFlags = async (
  userUuid: string,
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
  getApi: GetApiType,
) => {
  try {
    const featureFlagsResponse =
      await getApi(FeatureFlagsApi).getEnabledFeatures()
    await db.transaction('rw', [db.featureFlags], async tx => {
      await tx.featureFlags.put({
        userUuid,
        placeNodeId: selectedPlaceNodeUuid,
        companyId,
        enabledFeatures: featureFlagsResponse.features.map(feature => ({
          name: feature.name,
        })),
      })
    })
  } catch (e) {
    Sentry.captureException(
      new ErrorWithExtra(
        'FeatureFlagsDownloadError',
        'Failed to download feature flags',
        {
          userUuid,
          placeNodeUuid: selectedPlaceNodeUuid,
          companyId,
          originalError: e,
        },
      ),
    )
  }
}

/**
 * ホーム画面に表示するスケジュール情報のダウンロード
 *
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 * @param getApi
 */
const downloadScheduleItems = async (
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
  getApi: GetApiType,
) => {
  const scheduleItemsResponse = await getApi(
    ScheduleListItemsApi,
  ).getScheduleListItemsV2({
    acceptLanguage: 'Asia/Tokyo',
    scheduleDateRowFilter: {
      placeNodeId: { $in: [selectedPlaceNodeUuid] },
    },
  })

  if (scheduleItemsResponse.length === 0) {
    return
  }

  await db.transaction('rw', [db.scheduleItems], async tx => {
    await tx.scheduleItems.bulkPut(
      scheduleItemsResponse.map(item => ({
        uuid: uuidv4(),
        placeNodeId: selectedPlaceNodeUuid,
        companyId,
        dateRowSchedule: item,
      })),
    )
  })
}

/**
 * 従業員質問用、選択現場に紐付く従業員情報のダウンロード
 *
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 * @param getApi
 * @returns
 */
const downloadEmployees = async (
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
  getApi: GetApiType,
): Promise<boolean> => {
  const employeesResponse = await getApi(ToReportApi).getToReportEmployees({
    placeNodeUUID: selectedPlaceNodeUuid,
  })

  await db.transaction('rw', [db.employees], async tx => {
    await tx.employees.put({
      placeNodeUuid: selectedPlaceNodeUuid,
      companyId,
      hasMoreEmployees: employeesResponse.hasMoreEmployees,
      employees: employeesResponse.employees.map(employee => ({
        name: employee.name,
        code: employee.code,
        kana: employee.nameKana,
        placeNodes: employee.placeNodes,
      })),
    })
  })

  return employeesResponse.hasMoreEmployees
}

/**
 * ひな形の情報をダウンロード。ページの情報や画像のデータもこの関数内でダウンロードする
 *
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 * @param getApi
 * @param progressListener
 */
const downloadTemplates = async (
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
  getApi: GetApiType,
  progressListener: ProgressListener,
) => {
  let hasNextTemplates = true
  let currentPage = 1

  while (hasNextTemplates) {
    const task = new TemplatesDownloadTask(
      currentPage,
      selectedPlaceNodeUuid,
      companyId,
      db,
      getApi,
    )
    const taskResult = await task.run()
    if (taskResult instanceof Error) {
      throw taskResult
    }
    progressListener({ progress: taskResult.progress })
    hasNextTemplates = taskResult.hasNextPage
    currentPage = taskResult.next
  }
}

/**
 * 選択現場に紐づく一連のダウンロード済データを削除する
 *
 * @param selectedPlaceNodeUuid
 * @param companyId
 * @param db
 */
const deleteDownloadedOfflineResources = async (
  selectedPlaceNodeUuid: string,
  companyId: number,
  db: DBType,
) => {
  await db.transaction(
    'rw',
    [
      db.featureFlags,
      db.offlineDownloadInfos,
      db.scheduleItems,
      db.templates,
      db.templateNodes,
      db.multipleChoiceSets,
      db.templateMultipleChoiceSetRelations,
      db.templateMedia,
    ],
    async tx => {
      const cond = {
        placeNodeId: selectedPlaceNodeUuid,
        companyId,
      }
      // featureFlags のキーは userUuid であるが、
      // 現時点では、同一現場・複数タブなどで「片方でオフラインモード解除・もう片方はオフラインモード維持」のようなことはできない。
      // ひな形などのダウンロードデータは現場単位で削除するため
      //（もっとも、当機構実装時点では、localStorage のユーザー情報などはオリジン単位で持っているため、同一ブラウザで複数ユーザーを同時に使うことはできない）
      // 上記を踏まえ、この featureFlags の情報をあえて特別細かい粒度で削除することはしない
      await tx.featureFlags.where(cond).delete()
      await tx.offlineDownloadInfos.where(cond).delete()
      // employees は予期せぬ offline モードでも使われている可能性があるため、消さない
      await tx.scheduleItems.where(cond).delete()
      await tx.templates.where(cond).delete()
      await tx.templateNodes.where(cond).delete()
      await tx.multipleChoiceSets.where(cond).delete()
      await tx.templateMultipleChoiceSetRelations.where(cond).delete()
      await tx.templateMedia.where(cond).delete()
    },
  )
}
