import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { useRegisterSW } from 'virtual:pwa-register/react'

import {
  useAppVersionConsistency,
  selectIsAppVersionConsistent,
} from '~/stores/appVersionConsistency/appVersionConsistency'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { ErrorWithExtra } from '~/utils/error'
import { useCheckUpdateCompleted } from './useCheckUpdateCompleted'
import { useIsInReporting } from './useIsInReporting'

const intervalMS = 5 * 60 * 1000
const intervalMSInVersionInconsistency = 5 * 1000

export function useAppUpdate() {
  const isOffline = useOfflineModeStore(state => state.offlineMode)
  const isInReporting = useIsInReporting()
  const isAppVersionConsistent = useAppVersionConsistency(
    selectIsAppVersionConsistent,
  )
  const [registration, setRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >(undefined)

  const { checkUpdateCompleted } = useCheckUpdateCompleted()

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      setRegistration(r)
    },
    onRegisterError(error) {
      Sentry.captureException(
        new ErrorWithExtra(
          'ServiceWorkerRegistrationError',
          'SW registration error',
          {
            error,
          },
        ),
      )
    },
  })

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (registration) {
      interval = setInterval(
        async () => {
          // navigatorの存在しないブラウザ向けのチェック
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (registration.installing || !navigator) return
          if ('connection' in navigator && !navigator.onLine) return

          await registration.update()
        },
        isAppVersionConsistent ? intervalMS : intervalMSInVersionInconsistency,
      )

      // ログイン画面など、画面リロード時に API コールが発生しない画面を想定し、ServiceWorker アップデート後に再度バージョン整合性のチェックを行う
      // バージョンアップリリース中に古いアプリバージョンでアクセスし、デプロイ済みの最新のアプリに更新されたが、依然としてアクセスはできない、というケースを想定
      void checkUpdateCompleted()
    }

    return () => {
      clearInterval(interval)
    }
  }, [registration, isAppVersionConsistent, checkUpdateCompleted])

  useEffect(() => {
    const canUpdateAppVersion = !(isOffline || isInReporting)
    if (needRefresh && canUpdateAppVersion) {
      void updateServiceWorker(true)
      setNeedRefresh(false)
    }
  }, [
    isOffline,
    isInReporting,
    isAppVersionConsistent,
    needRefresh,
    updateServiceWorker,
    setNeedRefresh,
  ])
}
