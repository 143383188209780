import type {
  Memo,
  TextQuestionMemo,
} from '~/domain/report/model/report/node/questionNode/memo'
import { convertToUploadedImage } from './imageConverter'
import type { TextMemo, ReportAnswer } from '@ulysses-inc/harami_api_client'

type Props = Pick<ReportAnswer, 'textMemos' | 'manualMemos' | 'images'>

export const convertMemo = ({
  textMemos,
  manualMemos,
  images,
}: Props): Memo => ({
  textMemo: convertTextMemo(textMemos),
  handwrittenMemos: manualMemos?.map(convertToUploadedImage) ?? [],
  photoMemos: images?.map(convertToUploadedImage) ?? [],
})
const convertTextMemo = (
  textMemos?: TextMemo[],
): TextQuestionMemo | undefined => {
  if (!textMemos) return undefined
  const foundTextMemo = textMemos.find(({ name }) => name)
  return foundTextMemo ? { content: foundTextMemo.name! } : undefined
}
