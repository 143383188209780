import type { MultipleChoiceSetAnswer } from '~/domain/report/model/report/node/questionNode/multipleChoiceSetQuestionNode/multipleChoiceSetAnswer'
import type { MultipleChoiceSetQuestion } from '~/domain/report/model/report/node/questionNode/multipleChoiceSetQuestionNode/multipleChoiceSetQuestion'
import type { MultipleChoiceSetQuestionNode } from '~/domain/report/model/report/node/questionNode/multipleChoiceSetQuestionNode/multipleChoiceSetQuestionNode'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'
import type { ReportNodeSchema } from '@ulysses-inc/harami_api_client'

export const convertMultipleChoiceSetQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    base: { node, questionNodeBase, questionBase },
  } = context

  const responseMultipleChoiceSetUuid =
    extractResponseMultipleChoiceSetUuid(node)

  const choices = convertToChoices(context, responseMultipleChoiceSetUuid)

  const questionNode: MultipleChoiceSetQuestionNode = {
    ...questionNodeBase,
    questionType: 'responseSet',
    question: {
      ...questionBase,
      type: 'responseSet',
      multipleChoiceSetUuid: responseMultipleChoiceSetUuid,
      choices,
    },
    answer: getMultipleChoiceSetAnswer(context),
  }

  return questionNode
}

const getMultipleChoiceSetAnswer = (
  context: ConversionContext,
): MultipleChoiceSetAnswer | undefined => {
  const {
    question,
    base: { node, recordedAt },
  } = context

  const responseSets = question.responseAnswer?.responseSets ?? []
  if (responseSets.length === 0) {
    return undefined
  }

  const choiceUuids = responseSets.map(responseSet => {
    const uuid = responseSet.uuid ?? ''
    if (uuid === '') {
      throw new ApiToModelError(`Response set has no uuid.`, {
        node,
        responseSet,
      })
    }
    return uuid
  })

  return {
    type: 'responseSet',
    recordedAt,
    choiceUuids,
  }
}

const convertToChoices = (
  context: ConversionContext,
  responseMultipleChoiceSetUuid: string,
) => {
  const {
    base: { node },
    wholeContext: { multipleChoiceSetDict },
  } = context

  if (multipleChoiceSetDict.type === 'reference') {
    if (!multipleChoiceSetDict.uuids.has(responseMultipleChoiceSetUuid)) {
      throw new ApiToModelError(
        `Response multiple choice set uuid is not in multiple choice set dict.`,
        {
          node,
          responseMultipleChoiceSetUuid,
          multipleChoiceSetDict,
        },
      )
    }
    return []
  }

  const multipleChoiceSet = multipleChoiceSetDict.data.get(
    responseMultipleChoiceSetUuid,
  )
  if (multipleChoiceSet === undefined) {
    throw new ApiToModelError(`Multiple choice set not found.`, {
      node,
      responseMultipleChoiceSetUuid,
      multipleChoiceSetDict,
    })
  }

  const choices: MultipleChoiceSetQuestion['choices'] =
    multipleChoiceSet.responses?.map(responseSet => ({
      uuid: responseSet.uuid ?? '',
      name: responseSet.name ?? '',
      discriminationKey: responseSet.discriminationKey ?? '',
      categories:
        responseSet.categories?.map(category => ({
          name: category.name ?? '',
        })) ?? [],
    })) ?? []

  return choices
}

const extractResponseMultipleChoiceSet = (node: ReportNodeSchema) => {
  const responseMultipleChoiceSet =
    node.question?.responseMultipleChoiceSets?.[0]
  if (responseMultipleChoiceSet === undefined) {
    throw new ApiToModelError(
      'Multiple choice set question has no response multiple choice set.',
      { node },
    )
  }
  return responseMultipleChoiceSet
}

const extractResponseMultipleChoiceSetUuid = (node: ReportNodeSchema) => {
  const responseMultipleChoiceSet = extractResponseMultipleChoiceSet(node)

  // uuid が空文字列でないことを保証する
  const uuid = responseMultipleChoiceSet.uuid ?? ''
  if (uuid === '') {
    throw new ApiToModelError(`Response multiple choice set has no uuid.`, {
      node,
    })
  }

  return uuid
}
