export const orange = {
  11: '#7a2808',
  10: '#a9380c',
  9: '#d9480f',
  8: '#e8590c',
  7: '#f76707',
  6: '#fd7e14',
  5: '#ff922b',
  4: '#ffa94d',
  3: '#ffc078',
  2: '#ffd8a8',
  1: '#ffe8cc',
  0: '#fff4e6',
} as const

export const yellow = {
  11: '#804200',
  10: '#b35d00',
  9: '#e67700',
  8: '#f08c00',
  7: '#f59f00',
  6: '#fab005',
  5: '#fcc419',
  4: '#ffd43b',
  3: '#ffe066',
  2: '#ffec99',
  1: '#fff3bf',
  0: '#fff9db',
} as const

export const lime = {
  11: '#304e07',
  10: '#3f6509',
  9: '#5c940d',
  8: '#66a80f',
  7: '#74b816',
  6: '#82c91e',
  5: '#94d82d',
  4: '#a9e34b',
  3: '#c0eb75',
  2: '#d8f5a2',
  1: '#e9fac8',
  0: '#f4fce3',
} as const

export const green = {
  11: '#195024',
  10: '#1f632d',
  9: '#2b8a3e',
  8: '#2f9e44',
  7: '#37b24d',
  6: '#40c057',
  5: '#51cf66',
  4: '#69db7c',
  3: '#8ce99a',
  2: '#b2f2bb',
  1: '#d3f9d8',
  0: '#ebfbee',
} as const

export const teal = {
  11: '#054f39',
  10: '#06674a',
  9: '#087f5b',
  8: '#099268',
  7: '#0ca678',
  6: '#12b886',
  5: '#20c997',
  4: '#38d9a9',
  3: '#63e6be',
  2: '#96f2d7',
  1: '#c3fae8',
  0: '#e6fcf5',
} as const

export const cyan = {
  11: '#074a56',
  10: '#095e6d',
  9: '#0b7285',
  8: '#0c8599',
  7: '#1098ad',
  6: '#15aabf',
  5: '#22b8cf',
  4: '#3bc9db',
  3: '#66d9e8',
  2: '#99e9f2',
  1: '#c5f6fa',
  0: '#e3fafc',
} as const

export const indigo = {
  11: '#1b2763',
  10: '#2b3f9f',
  9: '#364fc7',
  8: '#3b5bdb',
  7: '#4263eb',
  6: '#4c6ef5',
  5: '#5c7cfa',
  4: '#748ffc',
  3: '#91a7ff',
  2: '#bac8ff',
  1: '#dbe4ff',
  0: '#edf2ff',
} as const

export const violet = {
  11: '#301e63',
  10: '#422a8a',
  9: '#5f3dc4',
  8: '#6741d9',
  7: '#7048e8',
  6: '#7950f2',
  5: '#845ef7',
  4: '#9775fa',
  3: '#b197fc',
  2: '#d0bfff',
  1: '#e5dbff',
  0: '#f3f0ff',
} as const

export const grape = {
  11: '#42174d',
  10: '#642275',
  9: '#862e9c',
  8: '#9c36b5',
  7: '#ae3ec9',
  6: '#be4bdb',
  5: '#cc5de8',
  4: '#da77f2',
  3: '#e599f7',
  2: '#eebefa',
  1: '#f3d9fa',
  0: '#f8f0fc',
} as const

export const pink = {
  11: '#500e25',
  10: '#7b1639',
  9: '#a61e4d',
  8: '#c2255c',
  7: '#d6336c',
  6: '#e64980',
  5: '#f06595',
  4: '#f783ac',
  3: '#faa2c1',
  2: '#fcc2d7',
  1: '#ffdeeb',
  0: '#fff0f6',
} as const

export const red = {
  11: '#601414',
  10: '#8a1d1d',
  9: '#c92a2a',
  8: '#e03131',
  7: '#f03e3e',
  6: '#fa5252',
  5: '#ff6b6b',
  4: '#ff8787',
  3: '#ffa8a8',
  2: '#ffc9c9',
  1: '#ffe3e3',
  0: '#fff5f5',
} as const

export const blue = {
  11: '#07415f',
  10: '#0b618e',
  9: '#0d71a6',
  8: '#1085c4',
  7: '#1499e0',
  6: '#10a8eb',
  5: '#27b6f2',
  4: '#48c6f7',
  3: '#6ed6fe',
  2: '#a3e7ff',
  1: '#c7f1ff',
  0: '#ebfaff',
} as const

export const gray = {
  9: '#1e242c',
  8: '#303844',
  7: '#434e5d',
  6: '#7f8c9d',
  5: '#a8b3c2',
  4: '#cbd2dd',
  3: '#dbe1e9',
  2: '#e6ebf2',
  1: '#eff2f7',
  0: '#f7f9fb',
} as const
