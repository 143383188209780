import type { ReactNode } from 'react'
import { EmptyPlaceListContent } from '~/pages/PlacesList/components/EmptyPlaceListContent/EmptyPlaceListContent'
import {
  selectBelongsToPlace,
  useUserPlaceNodeStore,
} from '~/stores/place/place'

type Props = {
  children: ReactNode
}

export function PlaceListPageRoute({ children }: Props) {
  const belongsToPlace = useUserPlaceNodeStore(selectBelongsToPlace)
  if (!belongsToPlace) {
    return <EmptyPlaceListContent />
  }
  return children
}
