import { UnhandledInReportingError } from '../../error'
import type { Node, NodeBase, NodeId } from '../node'
import type { NormalSectionNode } from './normalSectionNode/normalSectionNode'
import type {
  RepeatableMasterEmployeeSectionNode,
  RepeatableInstanceEmployeeSectionNode,
} from './repeatableSectionNode/employeeCheckSectionNode/employeeCheckSectionNode'
import type { RepeatableInstanceSectionNode } from './repeatableSectionNode/instanceSectionNode/instanceSectionNode'
import type { RepeatableMasterSectionNode } from './repeatableSectionNode/masterSectionNode/masterSectionNode'

export type SectionType =
  | 'normal'
  | 'repeatableSectionMaster'
  | 'repeatableSectionInstance'
  | 'repeatableEmployeeSectionMaster'
  | 'repeatableEmployeeSectionInstance'

export type SectionNodeBase<
  T extends SectionType = SectionType,
  V = object,
> = NodeBase<'section'> & {
  section: {
    name: string
    sectionType: T
  } & V
}

export type SectionNode =
  | NormalSectionNode
  | RepeatableMasterSectionNode
  | RepeatableInstanceSectionNode
  | RepeatableMasterEmployeeSectionNode
  | RepeatableInstanceEmployeeSectionNode

export type SectionNodeDict = Partial<Record<NodeId, SectionNode>>

const isSectionNode = (node: Node | undefined): node is SectionNode => {
  if (node === undefined) {
    return false
  }
  return node.type === 'section'
}
type RequireSectionNode = (
  node: Node | undefined,
) => asserts node is SectionNode
const requireSectionNode: RequireSectionNode = node => {
  if (node === undefined || !isSectionNode(node)) {
    throw new UnhandledInReportingError('node is not section node.', {
      node,
    })
  }
}

const _SectionNode = {
  isSectionNode,
}

export const SectionNode: typeof _SectionNode & {
  requireSectionNode: RequireSectionNode
} = {
  ..._SectionNode,
  requireSectionNode,
}
