import { useState, useCallback } from 'react'
import { useDownloadOfflineResources } from '~/domain/offlineReport/hooks/useDownloadOfflineResources'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'

export type DownloadResultType =
  | 'success'
  | 'error'
  | 'hasMoreEmployees'
  | 'noFeedback'

export function useOfflineToggleInteractions() {
  const [displayProgressCircle, setDisplayProgressCircle] = useState(false)
  const offlineModeOn = useOfflineModeStore(state => state.offlineModeOn)
  const offlineModeOff = useOfflineModeStore(state => state.offlineModeOff)
  const { download, endOfflineMode } = useDownloadOfflineResources()
  const [progress, setProgress] = useState(0)

  const [downloadResultType, setDownloadResultType] = useState<
    DownloadResultType | undefined
  >(undefined)

  const hideDownloadResultInfo = useCallback(() => {
    setDownloadResultType(undefined)
  }, [])

  const startDownloading = useCallback(async () => {
    setDisplayProgressCircle(true)
    const result = await download(({ progress }) => {
      setProgress(progress * 100)
    })
    setDisplayProgressCircle(false)
    setProgress(0)
    switch (result.type) {
      case 'success': {
        offlineModeOn()
        setDownloadResultType('success')
        break
      }
      case 'error': {
        offlineModeOff()
        setDownloadResultType(result.shouldFeedback ? 'error' : 'noFeedback')
        break
      }
      case 'warn': {
        switch (result.cause) {
          case 'hasMoreEmployees': {
            offlineModeOn()
            setDownloadResultType('hasMoreEmployees')
            break
          }
          case 'unknown': {
            // 現時点では、hasMoreEmployees 以外のケースは想定外のため、エラーとする
            offlineModeOff()
            setDownloadResultType('error')
            break
          }
        }
        break
      }
    }
  }, [download, offlineModeOff, offlineModeOn])

  const endOfflineReporting = useCallback(async () => {
    offlineModeOff()
    await endOfflineMode()
  }, [endOfflineMode, offlineModeOff])

  return {
    startDownloading,
    endOfflineReporting,
    displayProgressCircle,
    downloadProgress: progress,
    downloadResultType,
    hideDownloadResultInfo,
  } as const
}
