import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { captureException } from '@sentry/browser'
import {
  getRequestError,
  isAppVersionInconsistentError,
} from '~/adapter/api/error'
import { routes } from '~/app/routes/paths'
import gotonSrc from '~/assets/goton/goton-aseri.svg'
import logoSrc from '~/assets/logo/logo.svg'
import { useLogout } from '~/domain/logout/useLogout'
import { PageLayout } from '../PageLayout/PageLayout'
import { UpdateModal } from '../UpdateModal/UpdateModal'
import type { FallbackProps } from 'react-error-boundary'

export function GlobalError({ error }: FallbackProps) {
  const [isAppVersionConsistent, setIsAppVersionConsistent] = useState<
    boolean | undefined
  >(undefined)
  const { logout } = useLogout()
  useEffect(() => {
    if (error != null) {
      void getRequestError(error)
        .then(e => {
          if (isAppVersionInconsistentError(e)) {
            setIsAppVersionConsistent(false)
            // ここでエラーがキャッチされているということは、queryCache などの共通エラーハンドリングで、
            // Global な state 上の isAppVersionConsistent フラグは更新されているはず
            return
          }
          setIsAppVersionConsistent(true)
          // apiErrorはqueryClientでhandleするのでここではunexpectedErrorのみをキャプチャする
          if (e.type === 'unexpectedError') {
            captureException(error)
          }
        })
        .catch(() => {
          // getRequestError はエラーを吐かないように注意して実装しているはずだが、
          // 念の為 catch しておく
          setIsAppVersionConsistent(true)
        })
    } else {
      setIsAppVersionConsistent(true)
    }
  }, [error])

  if (isAppVersionConsistent === undefined) {
    return null
  }

  return (
    <PageLayout header={{ component: null }} mainLayoutType="center">
      <Box
        sx={{
          width: '100%',
          maxWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isAppVersionConsistent ? (
          <>
            <Box component="img" src={logoSrc} sx={{ width: '100%' }} />
            <Typography variant="h1" sx={{ mt: 1 }}>
              エラーが発生しました。
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              ホーム画面に戻り、もう一度操作してください。
              <br />
              問題が解決しない場合は、お手数ですが担当者にお問い合わせください。
            </Typography>
            <Box component="img" src={gotonSrc} sx={{ width: '50%', mt: 3 }} />
            <Button
              onClick={() => {
                location.replace(routes.home.path)
              }}
              variant="contained"
              sx={{ width: '100%', mt: 2 }}
              size="large"
            >
              ホームに戻る
            </Button>
            <Button
              onClick={async () => {
                await logout({ userInitiated: true })
              }}
              variant="contained"
              color="secondary"
              sx={{ width: '100%', mt: 2 }}
              size="large"
            >
              ログアウト
            </Button>
          </>
        ) : (
          <UpdateModal />
        )}
      </Box>
    </PageLayout>
  )
}
