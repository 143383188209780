import {
  ResponseDatetimeSubTypeEnum,
  ResponseDatetimeRuleSubTypeEnum,
  type ReportNodeSchema,
  type ResponseDatetimeRule,
} from '@ulysses-inc/harami_api_client'
import type { DateTimeAnswer } from '~/domain/report/model/report/node/questionNode/dateTimeQuestionNode/dateTimeAnswer'
import type { DateTimeQuestionFormat } from '~/domain/report/model/report/node/questionNode/dateTimeQuestionNode/dateTimeFormat'
import type { DateTimeQuestion } from '~/domain/report/model/report/node/questionNode/dateTimeQuestionNode/dateTimeQuestion'
import type { DateTimeQuestionNode } from '~/domain/report/model/report/node/questionNode/dateTimeQuestionNode/dateTimeQuestionNode'
import type { DateTimeRule } from '~/domain/report/model/report/node/questionNode/dateTimeQuestionNode/dateTimeRule'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import { isNullish } from '~/utils/isNullish'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'

export const convertDateTimeQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { node, questionNodeBase, questionBase, deviateProperty, recordedAt },
  } = context
  const responseAnswer = question.responseAnswer

  const dateTimeQuestion: DateTimeQuestion = {
    type: 'dateTime',
    ...questionBase,
    ...deviateProperty,
    ...convertToFormat(node),
  }

  let dateTimeAnswer: DateTimeAnswer | undefined = undefined
  if (responseAnswer?.datetimeValue?.datetimeValue !== undefined) {
    dateTimeAnswer = {
      type: 'dateTime',
      // API から与えられるデータのため、文字列の可能性がある
      value: new Date(responseAnswer.datetimeValue.datetimeValue),
      isInvalid: responseAnswer.datetimeValue.isInvalid === 1,
      recordedAt,
    }
  }

  const questionNode: DateTimeQuestionNode = {
    ...questionNodeBase,
    questionType: 'dateTime',
    question: dateTimeQuestion,
    answer: dateTimeAnswer,
  }

  return questionNode
}

const convertToFormat = (node: ReportNodeSchema): DateTimeQuestionFormat => {
  const responseDatetime = extractResponseDatetime(node)
  if (!responseDatetime) {
    throw new ApiToModelError('ResponseDatetime is required', { node })
  }

  switch (responseDatetime.subType) {
    case ResponseDatetimeSubTypeEnum.DATETIME: {
      return {
        format: 'dateTime',
        rule: convertToRule(node, responseDatetime.rule),
        isFixedToCurrentTime: responseDatetime.isFixedToCurrentTime ?? false,
      }
    }
    case ResponseDatetimeSubTypeEnum.DATE: {
      return {
        format: 'date',
        rule: convertToRule(node, responseDatetime.rule),
      }
    }
    case ResponseDatetimeSubTypeEnum.TIME: {
      return {
        format: 'time',
        isFixedToCurrentTime: responseDatetime.isFixedToCurrentTime ?? false,
      }
    }
    default:
      throw new ApiToModelError('Unsupported ResponseDatetime.subType', {
        node,
      })
  }
}

const convertToRule = (
  node: ReportNodeSchema,
  rule?: ResponseDatetimeRule,
): DateTimeRule | undefined => {
  if (!rule) {
    return undefined
  }

  const validateRuleValue: (
    value?: number,
  ) => asserts value is number = value => {
    if (isNullish(value)) {
      throw new ApiToModelError('ResponseDatetimeRule.value is required', {
        node,
      })
    }
  }

  switch (rule.subType) {
    case ResponseDatetimeRuleSubTypeEnum.GREATER_THAN_OR_EQUAL: {
      validateRuleValue(rule.value)
      return {
        type: 'ge',
        days: rule.value,
      }
    }
    case ResponseDatetimeRuleSubTypeEnum.LESS_THAN_OR_EQUAL: {
      validateRuleValue(rule.value)
      return {
        type: 'le',
        days: rule.value,
      }
    }
    case ResponseDatetimeRuleSubTypeEnum.EQUAL: {
      validateRuleValue(rule.value)
      return {
        type: 'eq',
        days: rule.value,
      }
    }
    default:
      throw new ApiToModelError('Unsupported ResponseDatetimeRule.subType', {
        node,
      })
  }
}

const extractResponseDatetime = (node: ReportNodeSchema) => {
  return node.question?.responseDatetimes?.[0]
}
