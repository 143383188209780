import { ResponseTextSubTypeEnum } from '@ulysses-inc/harami_api_client'
import type { QuestionNode } from '~/domain/report/model/report/node/questionNode/questionNode'
import type { TextAnswer } from '~/domain/report/model/report/node/questionNode/textQuestionNode/textAnswer'
import type { TextQuestion } from '~/domain/report/model/report/node/questionNode/textQuestionNode/textQuestion'
import type { TextQuestionNode } from '~/domain/report/model/report/node/questionNode/textQuestionNode/textQuestionNode'
import { ApiToModelError } from '../error'
import type { ConversionContext } from './types'
import type { ReportNodeSchema } from '@ulysses-inc/harami_api_client'

/**
 * テキスト質問の API Schema を Store のデータ型に変換する
 *
 * @param context
 * @returns
 */
export const convertTextQuestionNode = (
  context: ConversionContext,
): QuestionNode => {
  const {
    question,
    base: { node, questionNodeBase, questionBase, recordedAt },
  } = context
  const textQuestion: TextQuestion = {
    ...questionBase,
    type: 'text',
    rule: {
      format: convertToFormatType(
        node.question?.responseTexts?.[0]?.subType,
        node,
      ),
    },
  }

  const responseAnswer = question.responseAnswer
  let textAnswer: TextAnswer | undefined = undefined
  if (responseAnswer?.textValue?.textValue !== undefined) {
    textAnswer = {
      type: 'text',
      value: responseAnswer.textValue.textValue,
      recordedAt,
    }
  }

  const questionNode: TextQuestionNode = {
    ...questionNodeBase,
    questionType: 'text',
    question: textQuestion,
    answer: textAnswer,
  }

  return questionNode
}

const convertToFormatType = (
  subType: ResponseTextSubTypeEnum | undefined,
  node: ReportNodeSchema,
) => {
  if (subType === undefined) {
    throw new ApiToModelError('subType is undefined', {
      node,
    })
  }
  switch (subType) {
    case ResponseTextSubTypeEnum.SINGLE_LINE:
      return 'singleLine'
    case ResponseTextSubTypeEnum.MULTIPLE_LINE:
      return 'multipleLine'
  }
}
