import type { CSSProperties } from 'react'
import { alpha, createTheme } from '@mui/material/styles'
import { jaJP } from '@mui/x-date-pickers/locales'
import { blue, gray, red, yellow } from './kdsColors'

import { colorText } from './textColors'

type CSSColorProperty = CSSProperties['color']

declare module '@mui/material/styles' {
  interface Theme {
    customPalette: {
      bg: CSSColorProperty
      itemBg: CSSColorProperty
      itemInvalidBg: CSSColorProperty
      border: CSSColorProperty
      bgSpotlight: CSSColorProperty
      bgMask: CSSColorProperty
    }
  }
  interface ThemeOptions {
    customPalette?: {
      bg: CSSColorProperty
      itemBg: CSSColorProperty
      itemInvalidBg: CSSColorProperty
      border: CSSColorProperty
      bgSpotlight: CSSColorProperty
      bgMask: CSSColorProperty
    }
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false
    // MUIのコンポーネントのデフォルト値として参照されている
    sm: true
    md: false
    lg: false
    xl: false
    smartphone: true
    tablet: true
    pc: true
  }
}

export const theme = createTheme(
  {
    typography: {
      fontFamily: ['Noto Sans JP', 'Helvetica', 'Arial', 'sans-serif'].join(
        ',',
      ),
      // h1 Typography 22
      h1: {
        color: colorText,
        fontSize: 22,
        fontWeight: 700,
        lineHeight: '30px',
      },
      // h2 Typography 20
      h2: {
        color: colorText,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '28px',
      },
      // h2 Typography 20
      h3: {
        color: colorText,
        fontSize: 18,
        fontWeight: 700,
      },
      // body bold 16
      body1: {
        color: colorText,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '22px',
      },
      // body 16
      body2: {
        color: colorText,
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
      },
      button: {
        fontWeight: 700,
      },
      // caption  14
      caption: {
        color: colorText,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '17px',
      },
    },
    palette: {
      primary: {
        main: blue[6],
        contrastText: '#fff',
      },
      secondary: {
        main: '#fff',
        contrastText: gray[9],
      },
      error: {
        main: red[6],
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          sizeLarge: {
            fontSize: 18,
            fontWeight: 700,
            height: 44,
          },
          sizeMedium: {
            fontSize: 14,
            fontWeight: 700,
          },
          sizeSmall: {
            fontSize: 13,
            fontWeight: 500,
            height: 30,
            minWidth: 0,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: { borderRadius: '4px' },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
            boxShadow: 'none',
            borderBottom: `1px solid ${gray[3]}`,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              // TODO: constで定義して置き換える
              background: gray[9] + '0A',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            wordBreak: 'break-all',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: ({ ownerState: { severity, variant } }) => ({
            ...(severity === 'error' &&
              variant === 'standard' && {
                color: red[6],
                '.MuiAlert-icon .MuiSvgIcon-root': {
                  color: red[6],
                },
              }),
            ...(severity === 'error' &&
              variant === 'outlined' && {
                borderColor: red[6],
                backgroundColor: red[0],
                '.MuiAlert-icon .MuiSvgIcon-root': {
                  color: red[9],
                },
                '.MuiTypography-root': {
                  color: red[9],
                },
              }),
            ...(severity === 'warning' &&
              variant === 'outlined' && {
                borderColor: yellow[6],
                backgroundColor: yellow[0],
                '.MuiAlert-icon .MuiSvgIcon-root': {
                  color: yellow[9],
                },
                '.MuiTypography-root': {
                  color: yellow[10],
                },
              }),
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: 22,
            lineHeight: '30px',
            fontWeight: 700,
            color: colorText,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState: { color } }) => ({
            ...(color === 'error' && {
              color: red[7],
              backgroundColor: red[0],
            }),
            ...(color === 'warning' && {
              color: yellow[7],
              backgroundColor: yellow[0],
            }),
          }),
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: { overflowWrap: 'break-word' },
        },
      },
    },
    breakpoints: {
      values: {
        smartphone: 0,
        tablet: 600,
        pc: 1200,
        sm: 600,
      },
    },
    customPalette: {
      bg: gray[1],
      itemBg: '#fff',
      itemInvalidBg: red[0],
      border: gray[3],
      bgSpotlight: alpha(gray[9], 0.85),
      bgMask: alpha(gray[9], 0.45),
    },
  },
  jaJP,
)
