import { v4 as uuidv4 } from 'uuid'
import { MasterSectionNode } from '~/domain/report/model/report/node/sectionNode/repeatableSectionNode/masterSectionNode/masterSectionNode'
import { deepCopy } from '~/utils/deepCopy'
import { UnhandledInReportingError } from '../../../error'
import { Node } from '../../node'
import { FormulaQuestionNode } from '../../questionNode/formulaQuestionNode/formulaQuestionNode'
import { QuestionNode } from '../../questionNode/questionNode'
import { SectionNode } from '../sectionNode'
import { EmployeeCheckSectionNode } from './employeeCheckSectionNode/employeeCheckSectionNode'
import { InstanceSectionNode } from './instanceSectionNode/instanceSectionNode'
import type { Report } from '../../../report'
import type { NodeDict, NodeId } from '../../node'

export type RepeatableSectionNode = MasterSectionNode | InstanceSectionNode

const isRepeatableSectionNode = (
  node: Node | undefined,
): node is RepeatableSectionNode => {
  if (!SectionNode.isSectionNode(node)) {
    return false
  }

  return (
    node.section.sectionType === 'repeatableSectionMaster' ||
    node.section.sectionType === 'repeatableSectionInstance' ||
    node.section.sectionType === 'repeatableEmployeeSectionMaster' ||
    node.section.sectionType === 'repeatableEmployeeSectionInstance'
  )
}
type RequireRepeatableSectionNode = (
  node: Node | undefined,
) => asserts node is RepeatableSectionNode
const requireRepeatableSectionNode: RequireRepeatableSectionNode = node => {
  if (node === undefined || !isRepeatableSectionNode(node)) {
    throw new UnhandledInReportingError(
      'node is not repeatable section node.',
      {
        node,
      },
    )
  }
}

/**
 * 渡されたノードId配列で、対象のマスターノードからインスタンス化された最後のインスタンスノードのIndexを返す。
 * インスタンスが一つもない場合は、マスターノード自身のIndexを返す。
 *
 * @param masterNodeId - 対象のマスターノード
 * @param targetNodeIds - Indexを返す対象のnodeId配列
 * @param allNodes - すべてのノードが格納されている辞書
 * @returns
 */
const searchLastSectionInstanceNodeIndex = (
  masterNodeId: NodeId,
  targetNodeIds: NodeId[],
  allNodes: NodeDict,
) => {
  let lastInstanceNodeIndex = -1
  targetNodeIds.forEach((nodeId, index) => {
    // instance node がまだ作成されていない場合もあるので、master node自身の場合にそのindexを格納しておく
    if (nodeId === masterNodeId) {
      lastInstanceNodeIndex = index
      return
    }

    const node = allNodes[nodeId]
    if (
      InstanceSectionNode.isInstanceSectionNode(node) &&
      node.section.masterNodeId === masterNodeId
    ) {
      lastInstanceNodeIndex = index
    }
  })

  return lastInstanceNodeIndex
}

/**
 * セクションをインスタンス化した結果の型
 */
type InstantiateResult = {
  /** インスタンスセクションのノード */
  instantiatedSectionNode: InstanceSectionNode
  /** 子孫ノードすべてのクローンが格納されている辞書（インスタンスセクションノードは含まない） */
  clonedDescendantNodes: NodeDict
}

/**
 * 渡された "マスターノード" を "インスタンス化" した結果を返す.
 * インスタンス化とは、マスターノードを元に新たにインスタンスノードを生成すること.
 * この時、マスターノードの子孫ノードもすべてクローンする.
 *
 * ノードIDの割当:
 * 各ノードのIDは、引数で渡された generateId 関数によって払い出されたものが使われる
 *
 * ノードUUIDの割当:
 * 生成したノードの中で、UUIDが新たに作成されるノードは、マスターノードを元に生成したインスタンスノードのみ.
 * 子孫ノードは元のUUIDを維持する.
 * 現状のHaramiではこれを前提に処理をしている箇所が存在するためやむを得ずこうなっている.
 *
 * @param masterSectionNode - インスタンス化するマスターセクションノード
 * @param allNodeDict - すべてのノードが格納されている辞書
 * @param generateId - ノードIDを払い出す関数
 * @returns
 */
const instantiateSectionNode = (
  masterSectionNode: MasterSectionNode,
  allNodeDict: NodeDict,
  generateId: () => number,
): InstantiateResult => {
  // 繰り返しセクション（インスタンス）のルートノードのみ、uuid を振り直す
  const availableNodeUuids = [uuidv4()]

  const deepClonedNodeResult = deepCloneNodeWithDescendants(
    masterSectionNode,
    allNodeDict,
    generateId,
    () => availableNodeUuids.shift(),
  )

  // クローンされたノードが master section node なので、 instance section node に変換する
  MasterSectionNode.requireMasterSectionNode(deepClonedNodeResult.clonedNode)
  const instantiatedSectionNode = convertToInstanceSectionNode(
    deepClonedNodeResult.clonedNode,
    masterSectionNode.id,
  )

  return {
    instantiatedSectionNode: instantiatedSectionNode,
    clonedDescendantNodes: deepClonedNodeResult.clonedDescendantNodes,
  }
}

type deepCloneNodeRecursiveResult = {
  clonedNode: Node
  clonedDescendantNodes: NodeDict
}

/**
 * targetNode とその子孫ノードをクローンする
 *
 * @param targetNode
 * @param allNodes
 * @param generateId
 * @param generateUuid - この関数が空文字列またはundefinedを返す場合、元のnode.uuidを維持してnodeがクローンされる
 * @returns
 */
const deepCloneNodeWithDescendants = (
  targetNode: Node,
  allNodes: NodeDict,
  generateId: () => number,
  generateUuid: () => string | undefined,
) => {
  const idMapping = new Map<NodeId, NodeId>()
  const clonedNode = deepCloneNodeRecursive(
    targetNode,
    allNodes,
    generateId,
    generateUuid,
    idMapping,
  )
  // idMapping 内にマッピングが存在する場合、
  // その key に対応する node は、今回コピー元となったノードである。
  // 計算式の node がコピーされている場合、
  // a) idMapping の key に、operand の node.id が存在する -> 今回コピーされたコピー先のノードを参照するように修正する必要がある
  // b) idMapping の key に、operand の node.id が存在しない -> セクション跨ぎが発生しない場合、発生し得ない。セクション跨ぎがある場合、参照先は変える必要がない
  const swapReferencedNodeIds = (node: Node) => {
    if (FormulaQuestionNode.isFormulaQuestionNode(node)) {
      node.question.formulaDefTokens.forEach(token => {
        if (token.type === 'question' && token.questionNodeId) {
          const newNodeId = idMapping.get(token.questionNodeId)
          if (newNodeId !== undefined) {
            token.questionNodeId = newNodeId
          }
        }
      })
    }
    for (const nodeId of node.nodes) {
      const childNode = clonedNode.clonedDescendantNodes[nodeId]
      Node.requireNode(childNode)
      swapReferencedNodeIds(childNode)
    }
  }
  swapReferencedNodeIds(clonedNode.clonedNode)

  return clonedNode
}

/**
 * For 繰り返しセクションのインスタンス化用
 * 渡されたノードと、その子孫ノードすべてを再帰的にクローンする.
 *
 * @param targetNode
 * @param allNodes
 * @param generateId
 * @param generateUuid - この関数が空文字列またはundefinedを返す場合、元のnode.uuidを維持してnodeがクローンされる
 * @param idMapping key: マスターセクション内のコピー元の node.id、value: インスタンスセクション内の node.id (コピー後に採番されたID)
 * @returns
 */
const deepCloneNodeRecursive = (
  targetNode: Node,
  allNodes: NodeDict,
  generateId: () => number,
  generateUuid: () => string | undefined,
  idMapping: Map<NodeId, NodeId>,
): deepCloneNodeRecursiveResult => {
  // マスターノード内にインスタンスノードがある場合はエラーを投げる
  // 処理上は起き得ない不正な状態であるが、念のためチェックしている
  if (InstanceSectionNode.isInstanceSectionNode(targetNode)) {
    throw new UnhandledInReportingError(
      'During instantiation, an instance node was found within the master node.',
      { node: targetNode, allNodes },
    )
  }
  // マスターノード内に回答が存在する場合はエラーを投げる（ただし、選択肢のデフォルト回答は除く）
  // 処理上は起き得ない不正な状態であるが、念のためチェックしている
  if (
    QuestionNode.isQuestionNode(targetNode) &&
    QuestionNode.hasNonDefaultAnswer(targetNode)
  ) {
    throw new UnhandledInReportingError(
      'During instantiation, an answer was found within the master node.',
      { node: targetNode, allNodes },
    )
  }

  const resultClonedNode = deepCopy(targetNode)

  // クローンしたノードにID等を振る.
  // 親ノードからの参照を保持する必要があるため、本関数の呼び出し側でnodeIdを払い出すようにしている.
  const newNodId = generateId()
  idMapping.set(resultClonedNode.id, newNodId)
  resultClonedNode.id = newNodId
  const newNodeUuid = generateUuid()
  if (newNodeUuid) {
    resultClonedNode.uuid = newNodeUuid
  }

  // 子ノードの紐づきを初期化し、新たにpushしていく
  resultClonedNode.nodes = []
  let resultClonedDescendantNodes: NodeDict = {}

  // 子ノードを再帰的にクローンする処理
  targetNode.nodes.forEach(childNodeId => {
    const childNode = allNodes[childNodeId]
    Node.requireNode(childNode)

    // 子ノードのクローンを取得
    const clonedChildNodeResult = deepCloneNodeRecursive(
      childNode,
      allNodes,
      generateId,
      generateUuid,
      idMapping,
    )

    // クローンした子ノードへの参照を親ノードへpush
    resultClonedNode.nodes.push(clonedChildNodeResult.clonedNode.id)

    resultClonedDescendantNodes = {
      ...resultClonedDescendantNodes,
      [clonedChildNodeResult.clonedNode.id]: clonedChildNodeResult.clonedNode,
      ...clonedChildNodeResult.clonedDescendantNodes,
    }
  })

  return {
    clonedNode: resultClonedNode,
    clonedDescendantNodes: resultClonedDescendantNodes,
  }
}

/**
 * マスターノードからインスタンスノードへ変換する.
 * セクション名はマスターノードのものがそのまま使われる.
 *
 * @param masterSectionNode
 * @param masterNodeId
 * @returns
 */
const convertToInstanceSectionNode = (
  masterSectionNode: MasterSectionNode,
  masterNodeId: number,
): InstanceSectionNode => {
  switch (masterSectionNode.section.sectionType) {
    case 'repeatableSectionMaster':
      return {
        ...masterSectionNode,
        section: {
          name: masterSectionNode.section.name,
          sectionType: 'repeatableSectionInstance',
          masterNodeId,
        },
      }
    case 'repeatableEmployeeSectionMaster':
      return {
        ...masterSectionNode,
        section: {
          name: masterSectionNode.section.name,
          sectionType: 'repeatableEmployeeSectionInstance',
          masterNodeId,
        },
      }
  }
}

/**
 * masterSectionNodeIdから生成されたインスタンスノードをnodeIdsから取得する
 *
 * @param masterSectionNode
 * @param parentNode
 * @param allNodeDict
 * @returns
 */
const getInstanceNodes = (
  masterSectionNode: MasterSectionNode,
  parentNode: Node,
  allNodeDict: NodeDict,
): InstanceSectionNode[] => {
  return parentNode.nodes
    .filter(nodeId => {
      const node = allNodeDict[nodeId]
      if (!InstanceSectionNode.isInstanceSectionNode(node)) return false
      return isInstanceNodeFromMaster(masterSectionNode, node)
    })
    .map(nodeId => allNodeDict[nodeId])
    .filter(InstanceSectionNode.isInstanceSectionNode)
}

/**
 * baseSectionNameを元に、instanceNodesのセクション名を更新して新しいnode一覧を返す。
 * 従業員チェックセクションは、名称変更の対象外とする
 *
 * @param baseSectionName
 * @param instanceNodes
 * @returns
 */
const renameInstanceSectionNodes = (
  baseSectionName: string,
  instanceNodes: InstanceSectionNode[],
): InstanceSectionNode[] => {
  let instanceCount = 0
  return instanceNodes.map(instanceNode => {
    const node: InstanceSectionNode = deepCopy(instanceNode)
    if (EmployeeCheckSectionNode.isEmployeeCheckSectionNode(instanceNode)) {
      return node
    }
    node.section.name = `${baseSectionName} (${++instanceCount})`
    return node
  })
}

/**
 * nodeIds 中の、マスターノードから生成されたインスタンスノードの数をカウントする
 * @param allNodeDict
 * @param nodeIds
 * @param masterSectionNode
 */
const countInstanceNodes = (
  allNodeDict: NodeDict,
  nodeIds: NodeId[],
  masterSectionNode: MasterSectionNode,
): number => {
  return nodeIds.filter(nodeId => {
    const node = allNodeDict[nodeId]
    if (!InstanceSectionNode.isInstanceSectionNode(node)) {
      return false
    }
    return isInstanceNodeFromMaster(masterSectionNode, node)
  }).length
}

const isInstanceNodeFromMaster = (
  masterSectionNode: MasterSectionNode,
  targetSectionNode: InstanceSectionNode,
): boolean => {
  if (!InstanceSectionNode.isInstanceSectionNode(targetSectionNode)) {
    return false
  }

  return masterSectionNode.id === targetSectionNode.section.masterNodeId
}

/**
 * インスタンスノードと子孫ノードを挿入した新たなノード辞書を返す
 *
 * 挿入位置は、同一マスターノードから作成されたインスタンスノードの最後の位置
 *
 * @param instanceNode
 * @param descendantNodes
 * @param report
 * @returns
 */
const insertInstanceNode = (
  instanceNode: InstanceSectionNode,
  descendantNodes: NodeDict,
  report: Report,
): NodeDict => {
  const masterNode = report.nodes[instanceNode.section.masterNodeId]
  MasterSectionNode.requireMasterSectionNode(masterNode)

  const parentNode = Node.getParentNode(
    masterNode.id,
    report.nodePathInfos.idPaths,
    report.nodes,
  )

  // 挿入する位置を探し生成したinstanceを挿入する
  // 同一 master section から作成された最後の instance section の次に挿入する必要がある
  const lastSectionInstanceNodeIndex = searchLastSectionInstanceNodeIndex(
    masterNode.id,
    parentNode.nodes,
    report.nodes,
  )
  if (lastSectionInstanceNodeIndex === -1) {
    throw new UnhandledInReportingError(
      'An unexpected error has occurred in insertInstanceNode.',
    )
  }

  const newParentNode = deepCopy(parentNode)
  newParentNode.nodes.splice(
    lastSectionInstanceNodeIndex + 1,
    0,
    instanceNode.id,
  )

  return {
    ...report.nodes,
    // 変更を加えた親ノードを挿入(インスタンスノードへの参照をnodesに追加)
    [newParentNode.id]: newParentNode,
    // インスタンスノードを挿入
    [instanceNode.id]: instanceNode,
    // 子孫ノードを辞書へ挿入
    ...descendantNodes,
  }
}

const _RepeatableSectionNode = {
  isRepeatableSectionNode,
  instantiateSectionNode,
  getInstanceNodes,
  renameInstanceSectionNodes,
  countInstanceNodes,
  insertInstanceNode,
}
export const RepeatableSectionNode: typeof _RepeatableSectionNode & {
  requireRepeatableSectionNode: RequireRepeatableSectionNode
} = {
  ..._RepeatableSectionNode,
  requireRepeatableSectionNode,
}
