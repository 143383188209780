import type { ReadonlyImage, Image } from '~/domain/report/model/image/image'
import type { ReadonlyImageSchema, ReportImageSchema } from '../../types'

export const convertReadonlyImage = async (
  image: ReadonlyImage,
): Promise<ReadonlyImageSchema> => {
  switch (image.type) {
    case 'uploaded': {
      return {
        type: 'uploaded',
        recordedAt: image.recordedAt,
        file: image.file,
      }
    }
    case 'localRo': {
      return {
        type: 'localRo',
        recordedAt: image.recordedAt,
        underlyingRemoteFile: image.underlyingRemoteFile,
        content: {
          mediaType: image.content.type,
          buffer: await image.content.arrayBuffer(),
        },
      }
    }
  }
}

export const convertReportImage = async (
  image: Image,
): Promise<ReportImageSchema> => {
  switch (image.type) {
    case 'local': {
      return {
        type: 'local',
        recordedAt: image.recordedAt,
        file: {
          mediaType: image.file.type,
          buffer: await image.file.arrayBuffer(),
        },
      }
    }
    case 'uploaded': {
      return {
        type: 'uploaded',
        recordedAt: image.recordedAt,
        file: image.file,
      }
    }
  }
}
