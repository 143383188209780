import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { colorPrimary, colorTextTertiary } from '~/styles/textColors'

export const usePageSelection = () => {
  const currentPathName = useLocation().pathname
  const isSelected = useCallback(
    (pathName: string) => currentPathName === pathName,
    [currentPathName],
  )
  const getColorForPath = useCallback(
    (pathName: string) =>
      isSelected(pathName) ? colorPrimary : colorTextTertiary,
    [isSelected],
  )

  return {
    currentPathName,
    isSelected,
    getColorForPath,
  } as const
}
