import type { PropsWithChildren } from 'react'
import { Box, Container } from '@mui/material'
import type { SxProps, Theme } from '@mui/material'

export type DefaultProps = {
  bgWhiteContent?: React.ReactNode
}

const defaultStyles = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  bgGrayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme => theme.customPalette.bg,
    padding: {
      smartphone: 2,
      tablet: 3,
    },
  },
  bgWhiteWrapper: {
    backgroundColor: 'white',
    padding: {
      smartphone: 2,
      tablet: 3,
    },
  },
  main: {
    p: {
      smartphone: 0,
      tablet: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
} as const satisfies Record<string, SxProps<Theme>>
export function PageMainDefault({
  children,
  bgWhiteContent,
}: PropsWithChildren<DefaultProps>) {
  return (
    <Box component="main" sx={defaultStyles.mainWrapper}>
      <Box
        sx={[
          defaultStyles.bgGrayWrapper,
          { flexGrow: bgWhiteContent != null ? 0 : 1 },
        ]}
      >
        <Container maxWidth="pc" sx={defaultStyles.main}>
          {children}
        </Container>
      </Box>
      {bgWhiteContent && (
        <Box sx={defaultStyles.bgWhiteWrapper}>
          <Container maxWidth="pc" sx={defaultStyles.main}>
            {bgWhiteContent}
          </Container>
        </Box>
      )}
    </Box>
  )
}

const centerStyles = {
  mainWrapper: {
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme => theme.customPalette.bg,
  },
} as const satisfies Record<string, SxProps<Theme>>
export function PageMainCenter({ children }: PropsWithChildren) {
  return <Box sx={centerStyles.mainWrapper}>{children}</Box>
}

const fullStyles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme => theme.customPalette.bg,
  },
} as const satisfies Record<string, SxProps<Theme>>
export function PageMainFull({ children }: PropsWithChildren) {
  return <Box sx={fullStyles.main}>{children}</Box>
}
