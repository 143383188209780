import type { PropsWithChildren } from 'react'
import { DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { colorText } from '~/styles/textColors'
import type { SxProps, Theme } from '@mui/material'
import type { ButtonProps } from '@mui/material/Button'

const styles = {
  dialogAction: {
    padding: '8px 16px 16px',
  },
} satisfies Record<string, SxProps<Theme>>

export interface ConfirmationDialogProps {
  id: string
  open: boolean
  onClose: () => void
  okCallback: () => void
  title: string
  okText: string
  // TODO: 細かく調整できるようにし過ぎているきらいがある。機能全体のデザインを統一しもう少し共通化できないか考える
  // labels: ph.3
  cancelButtonProps?: Omit<ButtonProps, 'autoFocus' | 'onClick'>
  okButtonProps?: Omit<ButtonProps, 'onClick'>
}

export function ConfirmationDialog(
  props: PropsWithChildren<ConfirmationDialogProps>,
) {
  const {
    onClose,
    open,
    okCallback,
    title,
    okText,
    cancelButtonProps,
    okButtonProps,
    children,
    ...other
  } = props

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    onClose()
    okCallback()
  }
  const _cancelButtonProps: ButtonProps = {
    size: 'large',
    sx: { color: colorText },
    ...cancelButtonProps,
  }
  const _okButtonProps: ButtonProps = {
    size: 'large',
    ...okButtonProps,
  }

  return (
    <Dialog fullWidth open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions sx={styles.dialogAction}>
        <Button autoFocus onClick={handleCancel} {..._cancelButtonProps}>
          キャンセル
        </Button>
        <Button onClick={handleOk} {..._okButtonProps}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
