import { UnhandledInReportingError } from '../../../error'
import type { Answer, AnswerBase } from '../answer'

export type MultipleChoiceSetAnswer = AnswerBase<'responseSet'> & {
  /**
   * 回答(選択)した選択肢のuuidの配列
   */
  choiceUuids: string[]
}

const isMultipleChoiceSetAnswer = (
  answer: Answer | undefined,
): answer is MultipleChoiceSetAnswer => {
  return answer?.type === 'responseSet'
}

type RequireMultipleChoiceSetAnswer = (
  answer: Answer | undefined,
) => asserts answer is MultipleChoiceSetAnswer
const requireMultipleChoiceSetAnswer: RequireMultipleChoiceSetAnswer =
  answer => {
    if (!isMultipleChoiceSetAnswer(answer)) {
      throw new UnhandledInReportingError(
        `The answer is not a multiple choice set answer.`,
        {
          answer,
        },
      )
    }
  }

const createMultipleChoiceSetAnswer = (
  choiceUuids: string[],
): MultipleChoiceSetAnswer => {
  if (choiceUuids.length === 0) {
    throw new UnhandledInReportingError(`choiceUuids must not be empty.`, {
      choiceUuids,
    })
  }
  return {
    type: 'responseSet',
    recordedAt: new Date(),
    choiceUuids,
  }
}

const _MultipleChoiceSetAnswer = {
  createMultipleChoiceSetAnswer,
  isMultipleChoiceSetAnswer,
}

export const MultipleChoiceSetAnswer: typeof _MultipleChoiceSetAnswer & {
  requireMultipleChoiceSetAnswer: RequireMultipleChoiceSetAnswer
} = {
  ..._MultipleChoiceSetAnswer,
  requireMultipleChoiceSetAnswer,
}
