import { useCallback } from 'react'
import type { NavigateOptions } from 'react-router-dom'
import {
  useSearchParams,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import type {
  Route,
  Params,
  QueryParamsEncodable,
  PathParamsEncodable,
} from './routeConfig'

export const useTypedSearchParams = <
  T extends string,
  PP extends PathParamsEncodable<T>,
  QP extends QueryParamsEncodable | undefined,
  SP,
>(
  route: Route<T, PP, QP, SP>,
) => {
  const [params, setParams] = useSearchParams()
  const typedParams = route.decodeQueryParams(params)
  const setTypedParams = (params: QP) => {
    const encodedParams = route.encodeQueryParams(params)
    // keyがstring型で値が空文字の場合はキーごと削除する"
    if (encodedParams) {
      for (const [key, value] of encodedParams.entries()) {
        if (typeof value === 'string' && value === '') {
          encodedParams.delete(key)
        }
      }
    }

    setParams(encodedParams)
  }
  return [typedParams, setTypedParams] as const
}

export const useTypedParams = <
  T extends string,
  PP extends PathParamsEncodable<T>,
  QP extends QueryParamsEncodable | undefined,
  SP,
>(
  route: Route<T, PP, QP, SP>,
) => {
  const params = useParams()
  const typedParams = route.decodePathParams(params)
  return typedParams
}

export const useTypedLocationState = <
  T extends string,
  PP extends PathParamsEncodable<T>,
  QP extends QueryParamsEncodable | undefined,
  SP,
>(
  route: Route<T, PP, QP, SP>,
) => {
  const locationState = useLocation().state
  const typedLocationState = route.decodeStateParams(locationState)
  return typedLocationState
}

export const useTypedNavigate = () => {
  const navigate = useNavigate()
  const typedNavigate = useCallback(
    <
      T extends string,
      PP extends PathParamsEncodable<T>,
      QP extends QueryParamsEncodable | undefined,
      SP,
    >(
      route: Route<T, PP, QP, SP>,
      params: Params<PP, QP, SP>,
      options?: Omit<NavigateOptions, 'state'>,
    ) => {
      const path = route.buildPath(params)
      navigate(path, {
        ...options,
        state: params.stateParams,
      })
    },
    [navigate],
  )
  return typedNavigate
}

export const useGoBack = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = useCallback(
    (fallbackPath: string) => {
      const hasHistory = location.key !== 'default'
      if (hasHistory) {
        navigate(-1)
      } else {
        navigate(fallbackPath)
      }
    },
    [location.key, navigate],
  )

  return goBack
}
