import { useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import { OfflineApi } from '@ulysses-inc/harami_api_client'
import {
  getRequestError,
  isAppVersionInconsistentError,
} from '~/adapter/api/error'
import { useUnAuthApi } from '~/adapter/api/useUnAuthApi'
import { useAppVersionConsistency } from '~/stores/appVersionConsistency/appVersionConsistency'
import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { ErrorWithExtra } from '~/utils/error'

export const useCheckUpdateCompleted = () => {
  const { getApi } = useUnAuthApi()
  const isVersionConsistent = useAppVersionConsistency(
    state => state.isVersionConsistent,
  )
  const inconsistentVersionDetected = useAppVersionConsistency(
    state => state.inconsistentVersionDetected,
  )
  const isOffline = useOfflineModeStore(state => state.offlineMode)

  const checkUpdateCompleted = useCallback(async () => {
    if (isOffline) {
      return
    }
    // この関数は画面リロード時などに、API にバージョン整合性を問い合わせ、不整合があったらフラグを立てることが目的のため、
    // 不整合が検知済みの場合は、コールする必要がない
    if (!isVersionConsistent) {
      return
    }
    try {
      await getApi(OfflineApi).offlinePing()
    } catch (e: unknown) {
      const error = await getRequestError(e)
      if (isAppVersionInconsistentError(error)) {
        inconsistentVersionDetected()
        return
      }
      Sentry.captureException(
        new ErrorWithExtra(
          'CheckUpdateCompletedError',
          'Check app version update completed error',
          {
            error,
          },
        ),
      )
    }
  }, [getApi, inconsistentVersionDetected, isOffline, isVersionConsistent])

  return {
    checkUpdateCompleted,
  }
}
