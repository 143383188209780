import type { Updaters } from '~/adapter/indexedDB/types'
import type { OfflineDownloadInfoSchemaV1 } from '../../1/downloadInfo/downloadInfo'
import type { EmployeeSchemaV1 } from '../../1/employee/employee'
import type {
  MultipleChoiceSetSchemaV1,
  TemplateMultipleChoiceRelationSchemaV1,
} from '../../1/multipleChoiceSet/multipleChoiceSet'
import type { ReportSchemaV1 } from '../../1/report/report'
import type { ScheduleItemSchemaV1 } from '../../1/schedule/scheduleItem'
import type { TemplateSchemaV1 } from '../../1/template/template'
import type { TemplateMediaSchemaV1 } from '../../1/template/templateMedia'
import type { VersionSchemaV1 } from '../../1/version/version'
import type { OfflineDownloadInfoSchemaV2 } from '../downloadInfo/downloadInfo'
import type { EmployeeSchemaV2 } from '../employee/employee'
import type {
  MultipleChoiceSetSchemaV2,
  TemplateMultipleChoiceSetRelationSchemaV2,
} from '../multipleChoiceSet/multipleChoiceSet'
import type { ReportNodeSchemaV2, ReportSchemaV2 } from '../report/report'
import type { ScheduleItemSchemaV2 } from '../schedule/scheduleItem'
import type {
  TemplateNodeSchemaV2,
  TemplateSchemaV2,
} from '../template/template'
import type { TemplateMediaSchemaV2 } from '../template/templateMedia'
import type { VersionSchemaV2 } from '../version/version'

const version = 2
type versionType = typeof version

export const migration: Pick<
  Updaters[versionType],
  'upgradeBefore' | 'migrations'
> = {
  upgradeBefore: async tx => {
    const reports: ReportSchemaV1[] = await tx.table('reports').toArray()
    for (const report of reports) {
      const newReportNode: ReportNodeSchemaV2 = {
        uuid: report.uuid,
        placeNodeId: report.placeNodeId,
        companyId: report.companyId,
        data: {
          pageNodeIds: report.data.pageNodeIds,
          nodes: report.data.nodes,
        },
      }
      await tx.table('reportNodes').put(newReportNode)
    }
    const templates: TemplateSchemaV1[] = await tx.table('templates').toArray()
    for (const template of templates) {
      const newTemplateNode: TemplateNodeSchemaV2 = {
        templateId: template.templateId,
        placeNodeId: template.placeNodeId,
        companyId: template.companyId,
        data: {
          pageNodeIds: template.data.pageNodeIds,
          nodes: template.data.nodes,
        },
      }
      await tx.table('templateNodes').put(newTemplateNode)
    }
  },
  migrations: {
    versions: (prev: VersionSchemaV1): VersionSchemaV2 => {
      return {
        ...prev,
        // NOTICE: version テーブルのインクリメントを忘れないこと
        version,
      }
    },
    reports: (prev: ReportSchemaV1): ReportSchemaV2 => {
      const prevReportMode = prev.data.reportMode
      const current = {
        uuid: prev.uuid,
        placeNodeId: prev.placeNodeId,
        companyId: prev.companyId,
        data: {
          saveOperationType: prev.saveOperationType,
          reportMode: (() => {
            switch (prevReportMode.modeType) {
              case 'newReportFromTemplate': {
                return {
                  modeType: prevReportMode.modeType,
                  template: prevReportMode.template,
                  reportUuid: prevReportMode.reportUuid,
                  reportDate: prevReportMode.reportDate,
                  reportAssignee: {
                    uuid: prevReportMode.reportAssigneeUuid,
                    name: '',
                  },
                  placeNode: {
                    uuid: prev.placeNodeId ?? '',
                    name: '',
                  },
                }
              }
              case 'newReportFromSchedule': {
                return {
                  modeType: prevReportMode.modeType,
                  template: prevReportMode.template,
                  reportUuid: prevReportMode.reportUuid,
                  reportDate: prevReportMode.reportDate,
                  reportAssignee: {
                    uuid: prevReportMode.reportAssigneeUuid,
                    name: '',
                  },
                  placeNode: {
                    uuid: prev.placeNodeId ?? '',
                    name: '',
                  },
                  scheduleId: prevReportMode.scheduleId,
                  scheduleName: prevReportMode.scheduleName,
                }
              }
              case 'editReport': {
                return prevReportMode
              }
            }
          })(),
        },
      }
      return current
    },
    employees: (prev: EmployeeSchemaV1): EmployeeSchemaV2 => {
      return prev
    },
    offlineDownloadInfos: (
      prev: OfflineDownloadInfoSchemaV1,
    ): OfflineDownloadInfoSchemaV2 => {
      return prev
    },
    scheduleItems: (prev: ScheduleItemSchemaV1): ScheduleItemSchemaV2 => {
      return prev
    },
    templates: (prev: TemplateSchemaV1): TemplateSchemaV2 => {
      const current = {
        templateId: prev.templateId,
        placeNodeId: prev.placeNodeId,
        companyId: prev.companyId,
        data: {
          template: prev.data.template,
          displayProps: {
            isUsedJustForSchedule: prev.data.displayProps.isUsedJustForSchedule,
          },
        },
      }
      return current
    },
    templateMedia: (prev: TemplateMediaSchemaV1): TemplateMediaSchemaV2 => {
      return prev
    },
    multipleChoiceSets: (
      prev: MultipleChoiceSetSchemaV1,
    ): MultipleChoiceSetSchemaV2 => {
      return prev
    },
    templateMultipleChoiceSetRelations: (
      prev: TemplateMultipleChoiceRelationSchemaV1,
    ): TemplateMultipleChoiceSetRelationSchemaV2 => {
      return prev
    },
  },
}
