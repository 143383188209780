import { useCallback, useMemo, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Typography, IconButton } from '@mui/material'
import { useGoBack } from '~/app/routes/hooks'
import { routes } from '~/app/routes/paths'

import { useOfflineModeStore } from '~/stores/offlineMode/offlineMode'
import { colorText } from '~/styles/textColors'
import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu'
import { OfflineModeBanner } from './components/OfflineModeBanner/OfflineModeBanner'
import { useHeaderHeight } from './hooks/useHeaderHeight'
import type { SxProps, Theme } from '@mui/material'

const styles = {
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    color: colorText,
    px: 3,
    py: 1,
    gap: '32px',
  },
  section: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    columnGap: 2,
    overflow: 'hidden',
  },
  title: {
    lineHeight: '48px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  backIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 44,
    height: 48,
    flexShrink: 0,
  },
} as const satisfies Record<string, SxProps<Theme>>

const useHamburgerMenu = (hasMenu: boolean) => {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false)

  const handleOpenHamburgerMenu = useCallback(() => {
    setIsHamburgerMenuOpen(true)
  }, [])

  const handleCloseHamburgerMenu = useCallback(() => {
    setIsHamburgerMenuOpen(false)
  }, [])

  if (!hasMenu) {
    return {
      renderMenuTrigger: null,
      renderMenu: null,
    }
  }

  return {
    renderMenuTrigger: (
      <IconButton
        aria-label="サイドメニューを開く"
        onClick={handleOpenHamburgerMenu}
      >
        <MenuIcon sx={{ fontSize: '32px' }} />
      </IconButton>
    ),
    renderMenu: (
      <HamburgerMenu
        isOpen={isHamburgerMenuOpen}
        onClose={handleCloseHamburgerMenu}
      />
    ),
  }
}

function BackButton() {
  const goBack = useGoBack()

  const handleClickBack = useCallback(() => {
    goBack(routes.home.buildPath({}))
  }, [goBack])

  return (
    <IconButton
      sx={styles.backIcon}
      aria-label="戻る"
      onClick={handleClickBack}
    >
      <ChevronLeftIcon fontSize="large" sx={{ color: colorText }} />
    </IconButton>
  )
}

export type Props = {
  hasBackButton?: boolean
  hasHamburgerMenu?: boolean
  title: string
  rightContent?: React.ReactNode
  // UI修正してなるべく削除したい
  backButtonComponent?: React.ReactNode
}
export function Header({
  hasBackButton = false,
  hasHamburgerMenu = true,
  title,
  rightContent,
  backButtonComponent,
  sx: sxProps,
}: Props & { sx?: SxProps<Theme> }) {
  const isOffline = useOfflineModeStore(state => state.offlineMode)
  const { headerHeight } = useHeaderHeight()

  // 戻るボタンを表示する場合はハンバーガーメニューを表示しない
  const { renderMenuTrigger, renderMenu } = useHamburgerMenu(
    !hasBackButton && hasHamburgerMenu,
  )

  const renderBackButton = useMemo(() => {
    if (hasBackButton) {
      return backButtonComponent ?? <BackButton />
    }
    return null
  }, [hasBackButton, backButtonComponent])

  return (
    <Box
      component="header"
      sx={{
        minHeight: headerHeight,
        ...sxProps,
      }}
    >
      {isOffline && <OfflineModeBanner />}
      <Box sx={styles.headerContent}>
        <Box sx={styles.section}>
          {renderBackButton}
          {renderMenuTrigger}
          <Typography variant="h1" sx={styles.title}>
            {title}
          </Typography>
        </Box>
        {rightContent && rightContent}
        {renderMenu}
      </Box>
    </Box>
  )
}
