import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { localStorageKeys } from '~/adapter/localstorage/keys'
import { isNullish } from '~/utils/isNullish'

type User = {
  uuid: string
  name: string
  company: Company
  // NOTE:
  // 必要な情報があれば都度追加する。
  // ログイン時に様々な情報がAPIから返されるが、多くの情報をローカルストレージに保持すると変更が難しくなるので
  // 必要なもののみを入れたい。
}

type Company = {
  id: number
  name: string
}

type SessionStoreState = {
  /**
   * 認証方法：Rumpログイン移行由来で認証方法を保持する形になっているが、今は Rump ログインに対応する rump_oidc 以外に存在しない。
   */
  authMethod: 'rump_oidc' | null
  user: User | null
}

type SessionStoreAction = {
  rumpLogin: (user: User) => void
  logout: () => void
}

export const useSessionStore = create<SessionStoreState & SessionStoreAction>()(
  devtools(
    immer(
      persist(
        set => ({
          authMethod: null,
          user: null,
          rumpLogin: user =>
            set(state => {
              state.authMethod = 'rump_oidc'
              state.user = user
            }),
          logout: () =>
            set(state => {
              state.authMethod = null
              state.user = null
            }),
        }),
        {
          name: localStorageKeys.sessionStore,
          version: 0,
          onRehydrateStorage: () => {
            // beta キーを削除する。リリースして時間経過したらこの行を消すこと。
            localStorage.removeItem(localStorageKeys.sessionStore + '-beta')
          },
        },
      ),
    ),
  ),
)

/**
 * ログインユーザーの uuid を取得する。
 * store 内にユーザー情報が格納されていない場合、エラーを throw する。
 *
 * @param state
 * @returns
 */
export const selectUserUuid = (state: SessionStoreState): string => {
  const userUuid = state.user?.uuid
  if (!userUuid) {
    throw new Error('User not found')
  }
  return userUuid
}

/**
 * ログインユーザーの name を取得する。
 * store 内にユーザー情報が格納されていない場合、エラーを throw する。
 *
 * @param state
 * @returns
 */
export const selectUserName = (state: SessionStoreState): string => {
  const userName = state.user?.name
  // 初期ユーザーなどだと空文字のケースもありうるため、空文字は許可
  if (isNullish(userName)) {
    throw new Error('User not found')
  }
  return userName
}

/**
 * ログインユーザーの company_id を取得する。
 * store 内にユーザーの企業情報が格納されていない場合、エラーを throw する。
 *
 * @param state
 * @returns
 */
export const selectUserCompanyId = (state: SessionStoreState): number => {
  const companyId = state.user?.company.id
  // company_id = 0 のレコードは存在しないためエラーとして良い
  if (!companyId) {
    throw new Error('User not found')
  }
  return companyId
}

/**
 * 認証状態を取得する。
 *
 * @param state
 * @returns
 */
export const selectIsAuthed = (state: SessionStoreState): boolean => {
  return state.authMethod === 'rump_oidc'
}
