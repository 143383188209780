import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

type State = {
  /**
   * バックエンドが期待するバージョンと、アプリのバージョンが一致している場合 true
   *
   */
  isVersionConsistent: boolean
}
type Actions = {
  /**
   * アプリバージョンの不整合が検知された場合にコールされる
   * @returns
   */
  inconsistentVersionDetected: () => void
}

export const useAppVersionConsistency = create<State & Actions>()(
  devtools(
    immer(set => ({
      isVersionConsistent: true,
      inconsistentVersionDetected: () =>
        set(state => {
          state.isVersionConsistent = false
        }),
    })),
  ),
)

export const selectIsAppVersionConsistent = (state: State) =>
  state.isVersionConsistent
