import type { Hint } from '~/domain/report/model/report/node/questionNode/hint'
import { safeParseBlock } from '~/lib/editorjs/parse'
import type { Block } from '~/lib/editorjs/type'
import { ApiToModelError } from '../error'
import type { ReportQuestionHint } from '@ulysses-inc/harami_api_client'
const convertHintBlocks = (blocks: object[]): Block[] =>
  blocks.map(block => {
    const result = safeParseBlock(block)
    if (result.success) {
      return result.data
    }
    throw new ApiToModelError('Failed to convert hint block.', {
      block,
    })
  })

export const convertHints = (hints?: ReportQuestionHint[]): Hint[] =>
  hints?.map(hint => {
    if (
      hint.id === undefined ||
      hint.name === undefined ||
      hint.hintId === undefined ||
      hint.blocks === undefined
    ) {
      throw new ApiToModelError('Hint has no id or name or hintId or blocks.', {
        hint,
      })
    }
    return {
      id: hint.id,
      hintId: hint.hintId,
      name: hint.name,
      blocks: convertHintBlocks(hint.blocks),
    }
  }) ?? []
