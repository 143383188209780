import { Box, Button, Typography } from '@mui/material'
import gotonSrc from '~/assets/goton/goton-aseri.svg'
import logoSrc from '~/assets/logo/logo.svg'
import { PageLayout } from '~/components/layout/PageLayout/PageLayout'
import { useLogout } from '~/domain/logout/useLogout'

export function EmptyPlaceListContent() {
  const { logout } = useLogout()

  return (
    <PageLayout header={{ component: null }} mainLayoutType="center">
      <Box
        sx={{
          width: '100%',
          maxWidth: '360px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="img" src={logoSrc} sx={{ width: '100%' }} />
        <Typography
          variant="h3"
          sx={{ mt: 2, lineHeight: 1.6, textAlign: 'center' }}
        >
          所属現場が設定されておりません。
          <br />
          管理者にお問い合わせください。
        </Typography>
        <Box component="img" src={gotonSrc} sx={{ width: '50%', mt: 5 }} />
        <Button
          onClick={async () => {
            await logout({ userInitiated: true })
          }}
          variant="contained"
          sx={{ width: '100%', mt: 5 }}
          size="large"
        >
          ログアウトする
        </Button>
      </Box>
    </PageLayout>
  )
}
