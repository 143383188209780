import type { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import {
  selectBelongsToPlace,
  selectIsPlaceNodeSelected,
  useUserPlaceNodeStore,
} from '~/stores/place/place'
import { routes } from './paths'

type Props = {
  children: ReactNode
}

export function TemplateListPageRoute({ children }: Props) {
  const isPlaceNodeSelected = useUserPlaceNodeStore(selectIsPlaceNodeSelected)
  const belongsToPlace = useUserPlaceNodeStore(selectBelongsToPlace)
  if (!isPlaceNodeSelected) {
    if (belongsToPlace) {
      return <Navigate to={routes.places.buildPath({})} replace />
    }
  }
  return children
}
