import {
  PlaceNodeTypeEnum,
  type PlaceNode as PlaceNodeType,
} from '@ulysses-inc/harami_api_client'

type PlaceNode = PlaceNodeType

const getPlaceNodeName = (placeNode: PlaceNode): string => {
  switch (placeNode.type) {
    case PlaceNodeTypeEnum.Place: {
      return placeNode.place?.name ?? ''
    }
    case PlaceNodeTypeEnum.PlaceGroup: {
      return placeNode.placeGroup?.name ?? ''
    }
  }
}

export const PlaceNode = {
  getPlaceNodeName,
}
