import { UnhandledInReportingError } from '../../../error'
import type { FormulaAnswer } from './formulaAnswer'
import type { FormulaQuestion } from './formulaQuestion'
import type { Node } from '../../node'
import type { QuestionNodeBase, QuestionNodeInterface } from '../questionNode'

export type FormulaQuestionNode = QuestionNodeBase<'formula'> & {
  question: FormulaQuestion
  answer: FormulaAnswer | undefined
}

const isFormulaQuestionNode = (
  node: Node | undefined,
): node is FormulaQuestionNode => {
  return node?.type === 'question' && node.questionType === 'formula'
}
type RequireFormulaQuestionNode = (
  node: Node | undefined,
) => asserts node is FormulaQuestionNode
const requireFormulaQuestionNode: RequireFormulaQuestionNode = node => {
  if (!isFormulaQuestionNode(node)) {
    throw new UnhandledInReportingError(`node is not formula question node.`, {
      node,
    })
  }
}

const isInvalidAnswer = (node: FormulaQuestionNode): boolean => {
  return node.answer?.isInvalid ?? false
}

const toUserFriendlyAnswerString = (node: FormulaQuestionNode): string => {
  const answer = node.answer
  if (answer === undefined) {
    return ''
  }
  const valueString = Number.isNaN(answer.value) ? '' : String(answer.value)
  return `${valueString}${node.question.scale}`
}

const _FormulaQuestionNode = {
  isInvalidAnswer,
  isFormulaQuestionNode,
  toUserFriendlyAnswerString,
} satisfies QuestionNodeInterface<FormulaQuestionNode>

export const FormulaQuestionNode: typeof _FormulaQuestionNode & {
  requireFormulaQuestionNode: RequireFormulaQuestionNode
} = {
  ..._FormulaQuestionNode,
  requireFormulaQuestionNode,
}
