import {
  TemplateNodeTypeEnum,
  ResponseTypeEnum,
  NumberLogicTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { computeIfAbsent } from '~/utils/map'

import { ApiToModelError } from './error'
import {
  buildConversionContext,
  converterMapping,
} from './question/questionNodeConverter'

import type { MultipleChoiceSetDict } from './question/types'
import type {
  LogicNode,
  LogicNodeBase,
} from '../../model/report/node/logicNode/logicNode'
import type { MultipleChoiceLogicNode } from '../../model/report/node/logicNode/multipleChoiceLogicNode/multipleChoiceLogicNode'
import type {
  Node,
  NodeDict,
  NodeBase,
  NodeType,
} from '../../model/report/node/node'

import type { ConditionalQuestionType } from '../../model/report/node/questionNode/question'
import type { QuestionNode } from '../../model/report/node/questionNode/questionNode'
import type {
  RepeatableMasterEmployeeSectionNode,
  RepeatableInstanceEmployeeSectionNode,
} from '../../model/report/node/sectionNode/repeatableSectionNode/employeeCheckSectionNode/employeeCheckSectionNode'
import type { RepeatableInstanceSectionNode } from '../../model/report/node/sectionNode/repeatableSectionNode/instanceSectionNode/instanceSectionNode'
import type { RepeatableMasterSectionNode } from '../../model/report/node/sectionNode/repeatableSectionNode/masterSectionNode/masterSectionNode'
import type {
  SectionNode,
  SectionNodeBase,
} from '../../model/report/node/sectionNode/sectionNode'
import type {
  ReportNodeSchema,
  TemplatePage,
  ReportPage,
  ReportSection,
} from '@ulysses-inc/harami_api_client'

/**
 * NodePathDict と同じような構造で、ある node までのパス情報を格納する。
 * 末尾の要素は、自 node の node.id となる。
 * ページのID は含まないので、先頭の要素はページ直下の node.id となる。
 */
type NodeIdPath = number[]

export type ArgPage = {
  id?: number
  nodes?: {
    id: number
  }[]
}

/**
 * API から取得したスキーマで構築された node のディクショナリを model の形式に変換する機能を提供するクラス
 */
export class NodeSchemaConverter {
  /**
   * 繰り返しセクションのインスタンスノードに対して、マスターノードのIDを補完するために使用するデータ
   * 繰り返しセクションがネストされている場合、子繰り返しセクションのマスターノードの uuid は、重複する可能性があるため、
   * 当 Map の value は、配列としている。
   *
   * 具体例は以下のリンク参照：
   * https://www.notion.so/originalNodeUuid-5d5ada1d5b484ff3a15667d406028b04
   */
  private readonly nodeIdPathsByOriginalNodeUuid: Map<string, NodeIdPath[]>
  private readonly parentPageIdByNodeId: Map<number, number>
  private readonly parentNodeIdByNodeId: Map<number, number>
  private readonly pageDict: Partial<Record<number, { nodes: number[] }>>
  /**
   * nodeIdをキーにしたnodeの辞書
   */
  private readonly nodeDict: Partial<Record<number, ReportNodeSchema>>
  /**
   * uuidをキーにした選択肢セットの辞書（レポートで使用されるもののみ）
   */
  private readonly multipleChoiceSetDict: MultipleChoiceSetDict

  constructor(
    pages: ArgPage[],
    normalizedReportNodes: Partial<Record<number, ReportNodeSchema>>,
    multipleChoiceSetDict: MultipleChoiceSetDict,
  ) {
    const {
      nodeIdPathsByOriginalNodeUuid,
      parentPageIdByNodeId,
      parentNodeIdByNodeId,
      pageDict,
    } = createInitialNodeInfoMaps(pages, normalizedReportNodes)

    this.nodeIdPathsByOriginalNodeUuid = nodeIdPathsByOriginalNodeUuid
    this.parentPageIdByNodeId = parentPageIdByNodeId
    this.parentNodeIdByNodeId = parentNodeIdByNodeId
    this.pageDict = pageDict
    this.nodeDict = normalizedReportNodes
    this.multipleChoiceSetDict = multipleChoiceSetDict
  }

  convert(
    pages: TemplatePage[] | ReportPage[],
    nodes: Record<number, ReportNodeSchema>,
  ) {
    const nodeDict: NodeDict = {}

    Object.entries(nodes).forEach(([, node]) => {
      const convertedNode = this.convertNode(node)
      nodeDict[node.id] = convertedNode
    })

    // pageもnodeとして扱えるようにする。
    // そのために、pageに新たにnodeIdを付与する必要がある。
    const pageNodeIds: number[] = []
    pages.forEach(page => {
      if (!page.id) {
        throw new ApiToModelError(`page has no id.`, { page })
      }
      pageNodeIds.push(page.id)
      nodeDict[page.id] = {
        id: page.id,
        type: 'page',
        nodes: page.nodes?.map(node => node.id) ?? [],
        page: {
          name: page.name,
        },
      }
    })

    return { nodeDict, pageNodeIds }
  }

  private convertNode(node: ReportNodeSchema): Node {
    const nodeBase: Omit<NodeBase<NodeType>, 'type'> = {
      id: node.id,
      uuid: node.uuid,
      nodes: node.nodes,
      hide: node.hide,
    }
    switch (node.type) {
      case TemplateNodeTypeEnum.Section: {
        if (node.section === undefined) {
          throw new ApiToModelError('Section node has no section.', { node })
        }
        const sectionNode = this.convertSection(nodeBase, node.section)
        return sectionNode
      }
      case TemplateNodeTypeEnum.Logic: {
        return this.convertLogicNode(node)
      }
      case TemplateNodeTypeEnum.Question: {
        return this.convertQuestionNode(node)
      }
    }
  }

  private convertSection = (
    nodeBase: Omit<NodeBase<NodeType>, 'type'>,
    reportSection: ReportSection,
  ): SectionNode => {
    const sectionBase: SectionNodeBase = {
      ...nodeBase,
      type: 'section',
      section: {
        name: reportSection.name ?? '',
        sectionType: 'normal',
      },
    }

    if (!reportSection.isRepeat) {
      return {
        ...sectionBase,
        section: {
          ...sectionBase.section,
          sectionType: 'normal',
        },
      }
    }

    // 従業員チェックの繰り返しセクションの場合
    if (isEmployeeCheckSection(reportSection)) {
      if (isMasterSection(reportSection)) {
        const sectionNode: RepeatableMasterEmployeeSectionNode = {
          ...sectionBase,
          section: {
            ...sectionBase.section,
            sectionType: 'repeatableEmployeeSectionMaster',
          },
        }
        return sectionNode
      } else {
        const sectionNode: RepeatableInstanceEmployeeSectionNode = {
          ...sectionBase,
          section: {
            ...sectionBase.section,
            sectionType: 'repeatableEmployeeSectionInstance',
            masterNodeId: this.getMasterNodeId(nodeBase.id, reportSection),
          },
        }
        return sectionNode
      }
    }

    // 通常の繰り返しセクションの場合
    if (isMasterSection(reportSection)) {
      const sectionNode: RepeatableMasterSectionNode = {
        ...sectionBase,
        section: {
          ...sectionBase.section,
          sectionType: 'repeatableSectionMaster',
        },
      }
      return sectionNode
    } else {
      const sectionNode: RepeatableInstanceSectionNode = {
        ...sectionBase,
        section: {
          ...sectionBase.section,
          sectionType: 'repeatableSectionInstance',
          masterNodeId: this.getMasterNodeId(nodeBase.id, reportSection),
        },
      }
      return sectionNode
    }
  }

  private convertLogicNode(node: ReportNodeSchema): LogicNode {
    if (node.logic === undefined) {
      throw new ApiToModelError('Logic node has no logic.', { node })
    }

    const parentNodeId = this.parentNodeIdByNodeId.get(node.id)
    const parentNode = this.nodeDict[parentNodeId ?? 0]
    if (parentNode === undefined) {
      throw new ApiToModelError('Logic node has no parent node.', { node })
    }

    const logicNodeBase: Omit<
      LogicNodeBase<ConditionalQuestionType>,
      'triggerQuestionType'
    > = {
      id: node.id,
      uuid: node.uuid,
      nodes: node.nodes,
      hide: node.hide,
      type: 'logic',
      originalReportLogic: node.logic,
    }

    switch (parentNode.question?.responseType) {
      case ResponseTypeEnum.NUMBER: {
        return {
          ...logicNodeBase,
          triggerQuestionType: 'number',
          conditions:
            node.logic.numberConditions?.map(cond =>
              cond.logicType === NumberLogicTypeEnum.NORMAL
                ? 'normal'
                : 'invalid',
            ) ?? [],
        }
      }
      case ResponseTypeEnum.MULTIPLE_CHOICE: {
        const triggerChoices: MultipleChoiceLogicNode['triggerChoices'] =
          node.logic.responseMultipleChoices?.map(choice => ({
            label: choice.response,
          })) ?? []

        return {
          ...logicNodeBase,
          triggerQuestionType: 'multipleChoice',
          triggerChoices,
        }
      }
      default: {
        throw new ApiToModelError(
          `Logic node is not supported for response type.`,
          {
            node,
            parentNode,
          },
        )
      }
    }
  }

  /**
   * 指定された繰り返しセクションのインスタンスノードに対する、マスターノードの node.id を取得する。
   *
   * 繰り返しセクションがネストされている場合、子繰り返しセクションのマスターノードの uuid は、重複する可能性があるため、
   * 単純に、originalNodeUuid でルックアップするだけでは、候補が絞り込めない。
   *
   * このケースにおいては、当該インスタンスノードの「親」ノードが、
   * ルートノードからの経路上に含まれているマスターノードを採用することで絞り込むことができる
   *
   * 具体例は以下のリンク参照：
   * https://www.notion.so/originalNodeUuid-5d5ada1d5b484ff3a15667d406028b04
   *
   * @param nodeId
   * @param reportSection
   * @returns
   */
  private getMasterNodeId(nodeId: number, reportSection: ReportSection) {
    const originalNodeUuid = reportSection.originalNodeUuid ?? ''
    const parentNodeId = this.parentNodeIdByNodeId.get(nodeId)
    if (!parentNodeId) {
      // ページが親の場合、候補は１つしかない
      const nodeIdPaths =
        this.nodeIdPathsByOriginalNodeUuid.get(originalNodeUuid)
      if (!nodeIdPaths || nodeIdPaths.length !== 1) {
        throw new ApiToModelError(
          `nodeIdPaths mapped to originalNodeUuid must contain only one element.`,
          {
            nodeId,
            reportSection,
            originalNodeUuid,
          },
        )
      }
      const masterNodeId = nodeIdPaths[0].at(-1)
      if (!masterNodeId) {
        throw new ApiToModelError(
          `masterNodeId for originalNodeUuid is a falsy value.`,
          {
            nodeId,
            reportSection,
            originalNodeUuid,
            masterNodeId,
          },
        )
      }
      return masterNodeId
    }
    // 親がページ以外の場合
    const nodeIdPathCandidates =
      this.nodeIdPathsByOriginalNodeUuid.get(originalNodeUuid)
    if (!nodeIdPathCandidates) {
      throw new ApiToModelError(
        `nodeIdPaths mapped to originalNodeUuid doesn't exist.`,
        {
          nodeId,
          reportSection,
          originalNodeUuid,
        },
      )
    }
    const exactOneNodeIdPath = nodeIdPathCandidates.filter(c =>
      c.includes(parentNodeId),
    )
    if (exactOneNodeIdPath.length !== 1) {
      throw new ApiToModelError(
        `nodeIdPaths mapped to originalNodeUuid with nodeId - parentNodeId must contain only one element.`,
        {
          nodeId,
          reportSection,
          originalNodeUuid,
          parentNodeId,
        },
      )
    }
    const masterNodeId = exactOneNodeIdPath[0].at(-1)
    if (!masterNodeId) {
      throw new ApiToModelError(
        `masterNodeId for originalNodeUuid with nodeId - parentNodeId is a falsy value.`,
        {
          nodeId,
          reportSection,
          originalNodeUuid,
          parentNodeId,
          masterNodeId,
        },
      )
    }
    return masterNodeId
  }

  private convertQuestionNode(node: ReportNodeSchema): QuestionNode {
    if (node.question?.responseType === undefined) {
      throw new ApiToModelError('Question node has no response type.', {
        node,
      })
    }
    const conversionContext = buildConversionContext(node, {
      pageDict: this.pageDict,
      nodeDict: this.nodeDict,
      parentPageIdByNodeId: this.parentPageIdByNodeId,
      parentNodeIdByNodeId: this.parentNodeIdByNodeId,
      multipleChoiceSetDict: this.multipleChoiceSetDict,
    })
    return converterMapping[node.question.responseType](conversionContext)
  }
}

const isMasterSection = (reportSection: ReportSection): boolean => {
  return !reportSection.originalNodeUuid
}

const isEmployeeCheckSection = (reportSection: ReportSection): boolean => {
  return reportSection.isEmployeeCheck === 1
}

// 繰り返しセクションのマスターであるかの判定をやや厳密にやる関数
//
// ここでは、処理系で使用する情報は最低限検証しておく意図で、
// isMasterSection より厳密な判定としている
const isMasterSectionNodeStrict = (node: ReportNodeSchema) => {
  return (
    node.uuid &&
    node.type === TemplateNodeTypeEnum.Section &&
    node.section?.isRepeat === 1 &&
    !node.section.originalNodeUuid
  )
}

const createInitialNodeInfoMaps = (
  pages: ArgPage[],
  normalizedReportNodes: Partial<Record<number, ReportNodeSchema>>,
) => {
  const nodeIdPathsByOriginalNodeUuid = new Map<string, NodeIdPath[]>()
  const parentPageIdByNodeId = new Map<number, number>()
  const parentNodeIdByNodeId = new Map<number, number>()
  const pageDict: Partial<Record<number, { nodes: number[] }>> = {}

  const traverseNodes = (currentNodeId: number, pathToParent: number[]) => {
    const node = normalizedReportNodes[currentNodeId]
    if (!node) {
      return
    }
    const pathToCurrent = [...pathToParent, currentNodeId]
    if (isMasterSectionNodeStrict(node)) {
      computeIfAbsent(
        nodeIdPathsByOriginalNodeUuid,
        node.uuid ?? '',
        () => [],
      ).push(pathToCurrent)
    }
    for (const childNodeId of node.nodes) {
      parentNodeIdByNodeId.set(childNodeId, currentNodeId)
      traverseNodes(childNodeId, pathToCurrent)
    }
  }
  for (const page of pages) {
    if (page.id) {
      pageDict[page.id] = { nodes: page.nodes?.map(node => node.id) ?? [] }
    }
    for (const childNode of page.nodes ?? []) {
      if (page.id) {
        parentPageIdByNodeId.set(childNode.id, page.id)
      }
      traverseNodes(childNode.id, [])
    }
  }

  return {
    nodeIdPathsByOriginalNodeUuid,
    parentPageIdByNodeId,
    parentNodeIdByNodeId,
    pageDict,
  }
}
