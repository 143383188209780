import * as Sentry from '@sentry/browser'
import { AuthApi, ErrorCodeEnum } from '@ulysses-inc/harami_api_client'
import {
  getRequestError,
  isAppVersionInconsistentError,
} from '~/adapter/api/error'
import { useAuthApi } from '~/adapter/api/useAuthApi'
import { useClearAllPersistedData } from '~/app/hooks/useClearAllPersistedData'
import { routes } from '~/app/routes/paths'
import type { AdditionalMessagePattern } from '~/pages/RumpLogin/RumpLogin'
import { useAppVersionConsistency } from '~/stores/appVersionConsistency/appVersionConsistency'
import { ErrorWithExtra } from '~/utils/error'

export const useLogout = () => {
  const { getApi } = useAuthApi()
  const inconsistentVersionDetected = useAppVersionConsistency(
    state => state.inconsistentVersionDetected,
  )
  const { clearAllPersistedData } = useClearAllPersistedData()

  const logout = async ({
    userInitiated,
    additionalMessage,
  }: {
    userInitiated: boolean
    additionalMessage?: AdditionalMessagePattern
  }) => {
    try {
      await getApi(AuthApi).authLogout()
    } catch (e) {
      if (isAppVersionInconsistentError(await getRequestError(e))) {
        inconsistentVersionDetected()
        // 本エラーが発生している場合には、バックエンドでのログアウト処理自体が実行されていないので、
        // localStorage のクリアや redirect などの処理は行わない
        return
      }

      // NOTE: サーバーのログアウト処理に失敗した場合でも、ネットワークエラーなどを除いて Cookie は削除されるはずのため、ログアウト処理は継続させる
      Sentry.captureException(
        new ErrorWithExtra(
          'AuthLogoutFailure',
          'ログアウトのAPIリクエストに失敗しました',
          {
            originalError: e,
          },
        ),
      )
    }

    clearAllPersistedData()

    // logout時にbrowser back利用して欲しくないためlocation.replaceを利用
    const defaultParams = additionalMessage ? { additionalMessage } : {}
    const route = userInitiated
      ? routes.login.buildPath({ queryParams: defaultParams })
      : routes.login.buildPath({
          queryParams: {
            errorCode: ErrorCodeEnum.AuthenticationExpired,
            ...defaultParams,
          },
        })
    window.location.replace(route)
  }

  return { logout }
}
