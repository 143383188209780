import { v4 as uuidv4 } from 'uuid'
import type { UploadedImage } from '~/domain/report/model/image/image'
import { ApiToModelError } from '../error'
import type { Image } from '@ulysses-inc/harami_api_client'
export const convertToUploadedImage = (origImage: Image): UploadedImage => {
  if (
    origImage.uuid === undefined ||
    origImage.recordedAt === undefined ||
    origImage.url === undefined
  ) {
    throw new ApiToModelError('Image has no uuid, recordedAt, or url.', {
      image: origImage,
    })
  }
  return {
    type: 'uploaded' as const,
    key: uuidv4(),
    recordedAt: origImage.recordedAt,
    file: {
      url: origImage.url,
      uuid: origImage.uuid,
    },
  }
}
