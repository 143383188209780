import { Dexie } from 'dexie'

import { migration as migrationToV2 } from './schema/versions/2/migration/migration'
import type {
  Updaters,
  HaramiDBInterface,
  ReportSchemaLatest,
  VersionSchemaLatest,
  MultipleChoiceSetSchemaLatest,
  ScheduleItemSchemaLatest,
  TemplateMultipleChoiceSetRelationSchemaLatest,
  TemplateSchemaLatest,
  TemplateMediaSchemaLatest,
  OfflineDownloadInfoSchemaLatest,
  EmployeeSchemaLatest,
  ReportNodeSchemaLatest,
  TemplateNodeSchemaLatest,
  FeatureFlagSchemaLatest,
} from './types'

const updaters: Updaters = {
  1: {
    store: {
      versions: 'primaryKey, &version',
      reports: 'uuid, placeNodeId, companyId',
      employees: 'placeNodeUuid, companyId',
      offlineDownloadInfos: 'placeNodeId, companyId',
      scheduleItems: 'uuid, placeNodeId, companyId',
      templates: 'templateId, placeNodeId, companyId',
      templateMedia: 'key, placeNodeId, companyId',
      multipleChoiceSets: 'uuid, placeNodeId, companyId',
      templateMultipleChoiceSetRelations:
        '[templateId+multipleChoiceSetUuid], placeNodeId, companyId',
    },
  },
  2: {
    store: {
      versions: 'primaryKey, &version',
      featureFlags: 'userUuid, placeNodeId, companyId',
      reports: 'uuid, placeNodeId, companyId',
      reportNodes: 'uuid, placeNodeId, companyId',
      employees: 'placeNodeUuid, companyId',
      offlineDownloadInfos: 'placeNodeId, companyId',
      scheduleItems: 'uuid, placeNodeId, companyId',
      templates: 'templateId, placeNodeId, companyId',
      templateNodes: 'templateId, placeNodeId, companyId',
      templateMedia: 'key, placeNodeId, companyId',
      multipleChoiceSets: 'uuid, placeNodeId, companyId',
      templateMultipleChoiceSetRelations:
        '[templateId+multipleChoiceSetUuid], placeNodeId, companyId',
    },
    ...migrationToV2,
  },
}

/**
 * ソースコードの最新のバージョンを表す定数
 * マイグレーションが発生する時に、この定数自体は自動的にインクリメントされる。
 * アプリ自体のバージョンとは同期しない。
 */
const latestVersion = Object.keys(updaters).map(Number).sort().reverse()[0]

class HaramiDB extends Dexie implements HaramiDBInterface {
  versions: Dexie.Table<VersionSchemaLatest, string>
  featureFlags: Dexie.Table<FeatureFlagSchemaLatest, string>
  reports: Dexie.Table<ReportSchemaLatest, string>
  reportNodes: Dexie.Table<ReportNodeSchemaLatest, string>
  employees: Dexie.Table<EmployeeSchemaLatest, string>
  offlineDownloadInfos: Dexie.Table<OfflineDownloadInfoSchemaLatest, string>
  scheduleItems: Dexie.Table<ScheduleItemSchemaLatest, string>
  templates: Dexie.Table<TemplateSchemaLatest, string>
  templateNodes: Dexie.Table<TemplateNodeSchemaLatest, string>
  templateMedia: Dexie.Table<TemplateMediaSchemaLatest, string>
  multipleChoiceSets: Dexie.Table<MultipleChoiceSetSchemaLatest, string>
  // TODO: templates が multipleChoiceSets.uuid(s) を持っているため、いらないかもしれない
  // labels: ph.3
  templateMultipleChoiceSetRelations: Dexie.Table<
    TemplateMultipleChoiceSetRelationSchemaLatest,
    string
  >

  constructor() {
    super('harami-db')

    Object.entries(updaters).forEach(([v, updater]) => {
      this.version(parseInt(v)).stores(updater.store)

      const version = this.version(parseInt(v)).stores(updater.store)
      if ('migrations' in updater) {
        version.upgrade(async tx => {
          await updater.upgradeBefore(tx)
          for (const [key, migrate] of Object.entries(updater.migrations)) {
            await tx
              .table(key)
              .toCollection()
              .modify((prev, ref) => {
                ref.value = migrate(prev)
              })
          }
        })
      }
    })

    this.reports = this.table('reports')
    this.reportNodes = this.table('reportNodes')
    this.versions = this.table('versions')
    this.featureFlags = this.table('featureFlags')
    this.employees = this.table('employees')
    this.offlineDownloadInfos = this.table('offlineDownloadInfos')
    this.scheduleItems = this.table('scheduleItems')
    this.templates = this.table('templates')
    this.templateNodes = this.table('templateNodes')
    this.templateMedia = this.table('templateMedia')
    this.multipleChoiceSets = this.table('multipleChoiceSets')
    this.templateMultipleChoiceSetRelations = this.table(
      'templateMultipleChoiceSetRelations',
    )
  }
}

/**
 * オフラインデータのスキーマバージョン !== ソースコードのスキーマバージョンである場合に発生するエラー
 *
 */
export class VersionInconsistencyError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'VersionInconsistencyError'
  }
}

/**
 * オフラインモードを利用しない場合に、DB が作成されないようにするために、初期化を遅延させている
 */
let haramiDB: HaramiDB | undefined = undefined

const createDB = () => {
  if (!haramiDB) {
    const d = new HaramiDB()
    haramiDB = d

    haramiDB.on('ready', async () => {
      return await d.transaction('rw', d.versions, async () => {
        const version = await d.versions.get('versionKey')
        if (!version) {
          // 動作確認してみたところ、すでにレコードがある状態で呼び出しても
          // エラーとならず、かつ、新しいレコードもできない
          await d.versions.add({
            primaryKey: 'versionKey',
            version: latestVersion,
          })
        }
      })
    })
  }
}

const checkVersion = async () => {
  if (!haramiDB) {
    return
  }
  const version = await haramiDB.versions.get('versionKey')

  if (!version) {
    throw new VersionInconsistencyError('version not found')
  }

  if (version.version !== latestVersion) {
    throw new VersionInconsistencyError(
      `version inconsistency: ${version.version} !== ${latestVersion}`,
    )
  }
}

export type DBType = HaramiDB

/**
 * 端末のストレージに保存されたデータを取り扱うための DB インスタンスを取得する。
 * 通常は、src/hooks/db/useGetDB.ts を利用することを推奨。
 *
 *
 * @returns
 */
export const getDB = async (): Promise<DBType> => {
  /*
   * NOTE:
   * 初期化の処理順に関する懸念。概ね大丈夫そうだがスッキリしない。
   *
   * 動作確認レベルでは以下の順で実行された
   * 1. コンストラクタで仕込まれているマイグレーション処理
   * 2. ready イベントのコールバック
   * 3 db.reports.get などのデータ取得などの処理
   *
   * 内部的には非同期的な処理であることも想定され、上記の処理順であることが保証されているか疑わしい
   *
   * 公式 doc には以下のような記述がある
   *
   * ready イベント (https://dexie.org/docs/Dexie/Dexie.on.ready)
   * 「In case database is already open, the event will trigger immediately.
   *   If not open, it will trigger as soon as database becomes successfully opened.」(a)
   * 「If your subscriber returns a Promise, the open procedure will “block” the database until your promise becomes resolved or rejected.
   *   With “block” it means that any queued db operations will stay queued and new operations will be enqueued.」(b)
   *
   * Dexie.open (https://dexie.org/docs/Dexie/Dexie.open())
   * 「By default, db.open() will be called automatically on first query to the db.」(c)
   * 「When open is called, your Dexie instance start interacting with the backend indexedDB code.
   *  In case upgrade is needed, your registered stores will be created or modified accordingly and any registered upgrader function will run.
   *  When all is finished the return Promise instance will resolve and any pending db operation you have initiated after the call to open() will resume.」(d)
   *
   * Dexie.transaction (https://dexie.org/docs/Dexie/Dexie.transaction())
   * 「NOTE: As of v1.4.0+, the scope function will always be executed asynchronously. 」（e)
   *
   * この記述を信用するならば、
   * - ready イベントのコールバックは、少なくとも open されるより前には呼ばれない(aによる)
   * - デフォルトでは、open は、最初のクエリ時に自動的に呼ばれる(cによる)
   * - open が行われると（呼ばれると）、マイグレーションまで完了する(dによる)
   * - open が終わるより前に、DBへの操作は行われない(dによる)
   * - ready イベントのコールバックが Promise を返す場合、DBへの操作はブロックされる(bによる)
   *
   * 💡少なくとも、open=マイグレーションなどよりも、ready や、そのほかDBへの操作が「後」になることは保証されているように読み取れる。
   *
   * ready イベントのコールバック内のトランザクション処理が、同コールバックの Promise よりも先に resolve されることが保証される場合、
   * 期待通りの順番になる。
   * この保証があるかどうか、ドキュメント上怪しかった（eによる)
   *
   * もし、ready イベントの中身が、実際のデータ取得処理などより後に実行されてしまった場合でも、
   * 単にそのタイミングで、バージョン不一致により一瞬オフラインモードが使用できないだけで、
   * DB にアクセスする処理が何かしらの形で再度実行された時には、正常に戻るので、大きな問題はないと判断。
   *
   */
  createDB()
  if (!haramiDB) {
    // TODO: 呼び出し元で Feature Flag をみる前提
    // labels: ph.3
    throw new Error('DB is not created')
  }
  await checkVersion()
  return haramiDB
}
