/**
 * サロゲートペアを考慮した文字数が、指定した文字数を超えているかどうかを判定する
 *
 * @param str
 * @param length
 * @returns
 */
export const isGreaterThanLength = (str: string, length: number): boolean => {
  return getCharacterLength(str) > length
}

/**
 * サロゲートペアを考慮した文字数を取得する
 *
 * @param str
 * @returns
 */
export const getCharacterLength = (str: string): number => {
  return [...str].length
}
